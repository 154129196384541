@charset "UTF-8";
/*--------------------------------------------------------------------
RESET STYLES

Version:    3.0 - 2015
author:     Alexandre R. Gomes, from a idea of Eric Meyer & others
email:      alex@burocratik.com
website:    http://www.burocratik.com
-----------------------------------------------------------------------*/
/* =RESET ORGINAL v2.0 | 20110126
http://meyerweb.com/eric/tools/css/reset/

/* =NORMALIZE.css v8.0.0  | 20180208
github.com/necolas/normalize.css

/* =ALSO MY STUFF
------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------
=NORMALIZE.css v8.0.0
github.com/necolas/normalize.css
-------------------------------------------------------------------------*/
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none; }

/*-------------------------------------------------------------------------
=RESET ORGINAL v2.0 | 20110126
http://meyerweb.com/eric/tools/css/reset/
-------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  overflow: auto; }

/*-------------------------------------------------------------------------
=RESET ALEXANDRE GOMES  (alex@burocratik.com)
http://meyerweb.com/eric/tools/css/reset/
------------------------------------------------------------------------*/
abbr,
span.abbr {
  border-bottom: none; }

.clear {
  clear: both;
  height: 0; }

.clearfix:after,
.clearfix::after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.hide {
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: hidden; }

.hidetext {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

/*-----------------------------------------------------------------------
TYPOGRAPHY

* @Version:    1.0 - 2015
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
@font-face {
  font-family: 'p-Light';
  src: url("/public/fonts/p-l.eot");
  src: url("/public/fonts/p-l.eot?#iefix") format("embedded-opentype"), url("/public/fonts/p-l.woff2") format("woff2"), url("/public/fonts/p-l.woff") format("woff"), url("/public/fonts/p-l.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'p-Regular';
  src: url("/public/fonts/p-r.eot");
  src: url("/public/fonts/p-r.eot?#iefix") format("embedded-opentype"), url("/public/fonts/p-r.woff") format("woff"), url("/public/fonts/p-r.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'p-Medium';
  src: url("/public/fonts/p-m.eot");
  src: url("/public/fonts/p-m.eot?#iefix") format("embedded-opentype"), url("/public/fonts/p-m.woff") format("woff"), url("/public/fonts/p-m.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Stencil */
@font-face {
  font-family: 's-Medium';
  src: url("/public/fonts/ps-m.eot");
  src: url("/public/fonts/ps-m.eot?#iefix") format("embedded-opentype"), url("/public/fonts/ps-m.woff2") format("woff2"), url("/public/fonts/ps-m.woff") format("woff"), url("/public/fonts/ps-m.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 's-Light';
  src: url("/public/fonts/ps-l.eot");
  src: url("/public/fonts/ps-l.eot?#iefix") format("embedded-opentype"), url("/public/fonts/ps-l.woff2") format("woff2"), url("/public/fonts/ps-l.woff") format("woff"), url("/public/fonts/ps-l.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 's-Thin';
  src: url("/public/fonts/ps-thin.eot");
  src: url("/public/fonts/ps-thin.eot?#iefix") format("embedded-opentype"), url("/public/fonts/ps-thin.woff2") format("woff2"), url("/public/fonts/ps-thin.woff") format("woff"), url("/public/fonts/ps-thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* SUBSTITUI
font-family: 'Knockout 27 A', 'Knockout 27 B'; font-weight: 400; font-style: normal;
font-family: 'Knockout 49 A', 'Knockout 49 B'; font-weight: 400; font-style: normal;
*/
/******* =RENDERING *******/
html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures, contextual;
  -moz-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -moz-font-feature-settings: "smcp=1, onum=1, frac=1, kern=1, liga=1, dlig=1, swsh=1";
  -ms-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -o-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1; }

/* Chrome doesn't assume text rendering for form elements */
input,
textarea,
select,
button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures, contextual;
  -moz-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -moz-font-feature-settings: "smcp=1, onum=1, frac=1, kern=1, liga=1, dlig=1, swsh=1";
  -ms-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -o-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1;
  font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1, "dlig" 1, "swsh" 1; }

.text-subs,
.sub, sub {
  -moz-font-feature-settings: "subs" 1;
  -moz-font-feature-settings: "subs=1";
  -ms-font-feature-settings: "subs" 1;
  -o-font-feature-settings: "subs" 1;
  -webkit-font-feature-settings: "subs" 1;
  font-feature-settings: "subs" 1; }

.text-sups,
.sup, sup {
  -moz-font-feature-settings: "sups" 1;
  -moz-font-feature-settings: "sups=1";
  -ms-font-feature-settings: "sups" 1;
  -o-font-feature-settings: "sups" 1;
  -webkit-font-feature-settings: "sups" 1;
  font-feature-settings: "sups" 1; }

.sub, sub,
.sup, sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0; }

.sup, sup {
  top: -0.5em; }

.sub, sub {
  bottom: -0.25em; }

/*******  *******/
h3 {
  font: normal 50px/58px 'pEL', Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  /*max-width: 262px;*/ }

h3.medium {
  font: normal 30px/36px 'pR', Helvetica, Arial, sans-serif;
  text-align: left;
  letter-spacing: 1px; }

h3.light {
  font: normal 30px/36px 'pL', Helvetica, Arial, sans-serif;
  text-align: left;
  letter-spacing: 1px; }

strong, .strong, b {
  font-family: 'p-Medium', Helvetica, Arial, sans-serif; }

/******* =Sizes *******/
.txt-22 {
  font-size: 22px;
  line-height: 26px; }

/******* =Alignment *******/
.txt-center {
  text-align: center; }

.txt-left {
  text-align: left; }

.txt-right {
  text-align: right; }

/******* =Titles SVGS *******/
.gym-name {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  font: normal 400 74px/74px 'Knockout 27 A', 'Knockout 27 B', Helvetica, Arial, sans-serif;
  letter-spacing: 0; }
  .gym-name .city {
    position: absolute;
    top: -39px;
    left: 50%;
    font-size: 25px;
    line-height: 17px;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
    @media screen and (max-width: 767px) {
      .gym-name .city {
        font-size: 20px;
        top: -22px; } }
    @media screen and (max-width: 413px) {
      .gym-name .city {
        top: -11px; } }

.gym-name::before,
.gym-name::after {
  content: "";
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 0;
  width: 130px;
  height: 78px;
  opacity: .7;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }

.gym-name.yellow::before {
  background: transparent url(/public/imgs/title-top-arc-yellow.svg) no-repeat center center;
  top: -100px; }

.gym-name.orange::before {
  background: transparent url(/public/imgs/title-top-arc-orange.svg) no-repeat center center;
  top: -100px; }

.gym-name.white::before {
  background: transparent url(/public/imgs/title-top-arc-white.svg) no-repeat center center;
  top: -100px;
  opacity: 1; }

.gym-name.yellow::after {
  background: transparent url(/public/imgs/title-bottom-arc-yellow.svg) no-repeat center center;
  top: 100px; }

.gym-name.orange::after {
  background: transparent url(/public/imgs/title-bottom-arc-orange.svg) no-repeat center center;
  top: 100px; }

.gym-name.white::after {
  background: transparent url(/public/imgs/title-bottom-arc-white.svg) no-repeat center center;
  top: 100px;
  opacity: 1; }

.gym-name.size38 {
  font: normal 400 38px/38px 'Knockout 27 A', 'Knockout 27 B', Helvetica, Arial, sans-serif; }

.gym-name.size38::before,
.gym-name.size38::after {
  background-size: 60% 60%;
  opacity: .5; }

.gym-name.size38.yellow::before {
  background: transparent url(/public/imgs/title-top-arc-yellow-w70.svg) no-repeat center center;
  top: -67px; }

.gym-name.size38.orange::before {
  background: transparent url(/public/imgs/title-top-arc-orange-w70.svg) no-repeat center center;
  top: -67px; }

.gym-name.size38.gyellow::before {
  background: transparent url(/public/imgs/title-top-arc-gyellow-w70.svg) no-repeat center center;
  top: -67px; }

.gym-name.size38.white::before {
  background: transparent url(/public/imgs/title-top-arc-white-w70.svg) no-repeat center center;
  top: -67px;
  opacity: 1; }

.gym-name.size38.yellow::after {
  background: transparent url(/public/imgs/title-bottom-arc-yellow-w70.svg) no-repeat center center;
  top: 34px; }

.gym-name.size38.orange::after {
  background: transparent url(/public/imgs/title-bottom-arc-orange-w70.svg) no-repeat center center;
  top: 34px; }

.gym-name.size38.gyellow::after {
  background: transparent url(/public/imgs/title-bottom-arc-gyellow-w70.svg) no-repeat center center;
  top: 34px; }

.gym-name.size38.white::after {
  background: transparent url(/public/imgs/title-bottom-arc-white-w70.svg) no-repeat center center;
  top: 34px;
  opacity: 1; }

/*-----------------------------------------------------------------------
LAYOUT

* @Version:    1.0 - 2015
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  min-height: 100%;
  height: auto;
  font-family: "p-Light", Helvetica, Arial, sans-serif;
  background-color: #252322;
  color: #ffffff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overscroll-behavior: none; }

html.js-fixed-all,
body.js-fixed-all,
html.open-modal,
body.open-modal,
html.modal-open,
body.modal-open,
html.open-nav,
body.open-nav {
  overflow: hidden; }

body.js-byrefresh {
  opacity: 0;
  background-color: #252322; }

.no-js body.js-byrefresh {
  opacity: 1; }

body.js-loading-page,
body.js-loading-page a,
body.js-loading-page button {
  cursor: progress; }

.rgpd-link {
  position: absolute;
  bottom: 35px;
  right: 120px;
  z-index: 22;
  display: flex; }
  .rgpd-link a {
    display: block; }
    .rgpd-link a:first-child {
      margin-right: 20px; }

/*-------------------------------------------------------------------------
=HEADER
-------------------------------------------------------------------------*/
#header-main {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, -258px, 0);
  -moz-transform: translate3d(0, -258px, 0);
  transform: translate3d(0, -258px, 0); }

#header-bg {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  height: 200vh;
  width: 100%;
  background: #151a1f;
  display: none;
  opacity: 0;
  /*pointer-events: none; WITH this i can click below!!!!*/ }

/******* =MENU MAIN =MAIN NAV *******/
#nav-main {
  position: relative;
  z-index: 20;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  /*Safari only*/
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px); }

.nav-sec-mobile {
  z-index: 20;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.no-csstransforms3d .nav-sec-mobile {
  display: none; }

.no-csstransforms3d .open-nav .nav-sec-mobile {
  display: block; }

/*.open-nav.mobile .nav-sec-mobile{
    position: relative; z-index: 20;
    -webkit-transform: translate3d(0, 258px, 0);
    -moz-transform: translate3d(0, 258px, 0);
    transform: translate3d(0, 258px, 0);
}*/
/******* =MENU =NAV CONTROL *******/
#nav-main-control {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 72px;
  /*height: 79px;*/ }

#nav-main-control .bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /*background: rgba(37,35,34,.1);*/ }

#nav-main-control .bg::after,
#nav-main-control .nav-modality li::after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(37, 35, 34, 0.9);
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

#nav-main-control.goBg .bg::after,
#nav-main-control.goBg .nav-modality li::after {
  opacity: 1;
  background-color: rgba(37, 35, 34, 0.9); }

.corporate #nav-main-control .bg::after {
  background: rgba(26, 27, 34, 0.95); }

.ginasios #nav-main-control .bg::after {
  background: rgba(254, 186, 36, 0.9); }

.ginasios.celas #nav-main-control .bg::after {
  background: rgba(255, 209, 0, 0.9); }

.ginasios.lagrimas #nav-main-control .bg::after {
  background: rgba(255, 162, 0, 0.9); }

.ginasios.leiria #nav-main-control .bg::after {
  background: rgba(240, 219, 23, 0.9); }

.page-404 #nav-main-control .bg::after {
  background: rgba(0, 162, 255, 0.9); }

.nutricao-all #nav-main-control .bg::after,
.nutricao-single #nav-main-control .bg::after,
.nutricao-all #nav-main-control .nav-modality li::after,
.nutricao-single #nav-main-control .nav-modality li::after {
  background: rgba(191, 210, 124, 0.9); }

.health-all #nav-main-control .bg::after,
.health-single #nav-main-control .bg::after,
.health-all #nav-main-control .nav-modality li::after,
.health-single #nav-main-control .nav-modality li::after {
  background: rgba(254, 113, 77, 0.9); }

.safari #nav-main-control .bg::after,
.ios #nav-main-control .bg::after {
  display: none; }

/*Removing header-bg in pages that do not need it*/
.no-header-bg #nav-main-control .bg {
  display: none; }

header h1 {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 21px;
  width: 131px;
  height: 29px;
  text-indent: -5555px;
  background: transparent url(/public/imgs/id/phive-id.svg) no-repeat 0 0;
  background-size: auto 29px;
  clip: rect(0px, 28px, 36px, 0px);
  -webkit-transform: translate3d(-9%, 0, 0);
  -ms-transform: translate3d(-9%, 0, 0);
  transform: translate3d(-9%, 0, 0);
  /*  -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition: all .5s cubic-bezier(0.175, 0.885, 0.320, 1);
  transition: all .3s ease-in-out;*/ }

header h1 a {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%; }

.home header h1 {
  clip: rect(0px, 135px, 36px, 0px);
  -webkit-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0); }

.home header h1 a {
  /*pointer-events: none;*/ }

/******* BURGER MENU *******/
.btn-burger {
  position: absolute;
  z-index: 20;
  left: 34px;
  top: 17px;
  width: 54px;
  height: 40px;
  text-indent: -5555px;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.btn-burger a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -5555px; }

.btn-burger span {
  display: block;
  position: absolute;
  left: 0;
  top: 18px;
  width: 54px;
  height: 2px;
  background: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /*  -webkit-transition: margin 0.13s ease-out 0.23s, opacity 0.13s ease-out 0.23s, -webkit-transform 0.13s ease-out, background .14s linear;
    -moz-transition: margin 0.13s ease-out 0.23s, opacity 0.13s ease-out 0.23s, -moz-transform 0.13s ease-out, background .14s linear;
    -ms-transition: margin 0.13s ease-out 0.23s, opacity 0.13s ease-out 0.23s, -ms-transform 0.13s ease-out, background .14s linear;
    transition: margin 0.13s ease-out 0.23s, opacity 0.13s ease-out 0.23s, transform 0.13s ease-out, background .14s linear;*/ }

.btn-burger .l1 {
  margin-top: -8px; }

.btn-burger .l3 {
  margin-top: 8px; }

.btn-burger:hover .l1 {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px); }

.btn-burger:hover .l3 {
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px); }

.open-nav .btn-burger .l1 {
  -webkit-animation: animBurgerL1 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
  -ms-animation: animBurgerL1 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
  animation: animBurgerL1 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards; }

.open-nav .btn-burger .l3 {
  -webkit-animation: animBurgerL3 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
  -ms-animation: animBurgerL3 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
  animation: animBurgerL3 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards; }

@-webkit-keyframes animBurgerL1 {
  0% {
    -webkit-transform: translateY(-3px); }
  50% {
    -webkit-transform: translateY(8px); }
  100% {
    -webkit-transform: translateY(0); } }

@-webkit-keyframes animBurgerL3 {
  0% {
    -webkit-transform: translateY(3px); }
  50% {
    -webkit-transform: translateY(-8px); }
  100% {
    -webkit-transform: translateY(0); } }

@-ms-keyframes animBurgerL1 {
  0% {
    -ms-transform: translateY(-3px); }
  50% {
    -ms-transform: translateY(8px); }
  100% {
    -ms-transform: translateY(0); } }

@-ms-keyframes animBurgerL3 {
  0% {
    -ms-transform: translateY(3px); }
  50% {
    -ms-transform: translateY(-8px); }
  100% {
    -ms-transform: translateY(0); } }

@keyframes animBurgerL1 {
  0% {
    transform: translateY(-3px); }
  50% {
    transform: translateY(8px); }
  100% {
    transform: translateY(0); } }

@keyframes animBurgerL3 {
  0% {
    transform: translateY(3px); }
  50% {
    transform: translateY(-8px); }
  100% {
    transform: translateY(0); } }

/******* MODALITIES LINKS E SETAS *******/
#nav-main-control .nav-modality {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 460px;
  height: 100%;
  font: normal 14px/14px "p-Medium", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.ie #nav-main-control .nav-modality {
  z-index: 10; }

#nav-main-control .nav-modality ul {
  position: relative;
  width: 100%;
  height: 72px;
  border-spacing: 0;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.ie #nav-main-control .nav-modality ul {
  flex: none;
  display: inline-block;
  overflow: hidden; }

#nav-main-control .nav-modality li {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-left: 1px;
  /*background: rgba(37,35,34,0.1);*/
  height: 100%; }

#nav-main-control .nav-modality li:first-child {
  margin: 0;
  -webkit-flex: 2 1 0;
  -ms-flex: 2 1 0;
  flex: 2 1 0; }

.ie #nav-main-control .nav-modality li,
.ie #nav-main-control .nav-modality li:first-child {
  flex: none;
  display: inline-block;
  float: left;
  background: none;
  width: 100px; }

.ie #nav-main-control .nav-modality li:first-child {
  padding: 33px 0 0 20px;
  width: 156px; }

.no-csstransforms3d #nav-main-control .nav-modality li a {
  padding: 27px 0 0 30px; }

#nav-main-control .nav-modality li a {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

#nav-main-control .nav-modality li.go-main p {
  text-indent: -5555px; }

.js-fixed-all #nav-main-control .nav-modality a {
  /* when transition */
  pointer-events: none; }

.js-fixed-all #nav-main-control li.text {
  /* when transition */
  color: transparent; }

#nav-main-control .nav-modality li.text .anim-txt-ud {
  z-index: 10;
  padding-right: 17px; }

/* =quadrados modalidades*/
.open-modality {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.open-modality span {
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 6px;
  background: #fff;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.open-modality .b1 {
  left: 0;
  top: 0; }

.open-modality .b2 {
  left: 8px;
  top: 0; }

.open-modality .b3 {
  left: 16px;
  top: 0; }

.open-modality .b4 {
  left: 0;
  top: 8px; }

.open-modality .b5 {
  left: 8px;
  top: 8px; }

.open-modality .b6 {
  left: 16px;
  top: 8px; }

.open-modality .b7 {
  left: 0;
  top: 16px; }

.open-modality .b8 {
  left: 8px;
  top: 16px; }

.open-modality .b9 {
  left: 16px;
  top: 16px; }

.open-modality:hover .b1,
a:hover .open-modality .b1 {
  -webkit-transform: translate3d(-2px, -2px, 0);
  transform: translate3d(-2px, -2px, 0);
  transform: translate3d(-2px, -2px, 0); }

.open-modality:hover .b2,
a:hover .open-modality .b2 {
  -webkit-transform: translate3d(0px, -2px, 0);
  -ms-transform: translate3d(0px, -2px, 0);
  transform: translate3d(0px, -2px, 0); }

.open-modality:hover .b3,
a:hover .open-modality .b3 {
  -webkit-transform: translate3d(2px, -2px, 0);
  -ms-transform: translate3d(2px, -2px, 0);
  transform: translate3d(2px, -2px, 0); }

.open-modality:hover .b4,
a:hover .open-modality .b4 {
  -webkit-transform: translate3d(-2px, 0, 0);
  -ms-transform: translate3d(-2px, 0, 0);
  transform: translate3d(-2px, 0, 0); }

.open-modality:hover .b5,
a:hover .open-modality .b5 {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.open-modality:hover .b6,
a:hover .open-modality .b6 {
  -webkit-transform: translate3d(2px, 0, 0);
  -ms-transform: translate3d(2px, 0, 0);
  transform: translate3d(2px, 0, 0); }

.open-modality:hover .b7,
a:hover .open-modality .b7 {
  -webkit-transform: translate3d(-2px, 2px, 0);
  -ms-transform: translate3d(-2px, 2px, 0);
  transform: translate3d(-2px, 2px, 0); }

.open-modality:hover .b8,
a:hover .open-modality .b8 {
  -webkit-transform: translate3d(0, 2px, 0);
  -ms-transform: translate3d(0, 2px, 0);
  transform: translate3d(0, 2px, 0); }

.open-modality:hover .b9,
a:hover .open-modality .b9 {
  -webkit-transform: translate3d(2px, 2px, 0);
  -ms-transform: translate3d(2px, 2px, 0);
  transform: translate3d(2px, 2px, 0); }

/* = setas menu modalidades */
#header-main .nav-modality a .prev,
#header-main .nav-modality a .next {
  display: block;
  width: 33px;
  height: 26px;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

#header-main .nav-modality a:hover .next {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px); }

#header-main .nav-modality a:hover .prev {
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px); }

#header-main .nav-modality svg {
  fill: #fff; }

#header-main .nav-modality svg text {
  visibility: hidden; }

/******* =MENU =MAIN NAV *******/
#nav-main-list {
  position: relative;
  z-index: 20;
  width: 100%;
  min-height: 200px;
  border-top: 1px solid #2a292a;
  font: normal 400 36px/36px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #5c5857;
  background: #252322; }

.no-csstransforms3d #nav-main-list {
  position: absolute;
  left: 0;
  top: 55px; }

#nav-main-list li {
  position: relative;
  border-left: 1px solid #2a292a; }

.no-flexboxlegacy #nav-main-list li {
  /* =IE */
  width: 24%; }

#nav-main-list li:first-child {
  border: none; }

#nav-main-list a {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  color: #5c5857;
  -webkit-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out; }

#nav-main-list a:hover {
  color: #fff; }

#nav-main-list span {
  display: block; }

#nav-main-list svg,
#nav-main-list img {
  display: inline-block;
  width: 139px;
  height: 81px;
  margin: 25px 0 20px 0; }

/* =svg anim */
#nav-main-list a .svg-anim-modality {
  fill: none;
  stroke: white;
  stroke-width: 1;
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGicoModalityOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 308; } }

@-ms-keyframes animSVGicoModalityOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 308; } }

@keyframes animSVGicoModalityOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 308; } }

#nav-main-list a.over .svg-anim-modality {
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a.over .svg-anim-modality {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoModalitySafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-modality {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

@-webkit-keyframes animSVGicoModalitySafari {
  0% {
    stroke-dashoffset: 308; }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes animSVGicoModality {
  0% {
    stroke-dashoffset: -308; }
  100% {
    stroke-dashoffset: 0; } }

@-ms-keyframes animSVGicoModality {
  0% {
    stroke-dashoffset: -308; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes animSVGicoModality {
  0% {
    stroke-dashoffset: -308; }
  100% {
    stroke-dashoffset: 0; } }

/* =main nav svg instrutores */
#nav-main-list a .svg-anim-instr {
  fill: none;
  stroke: white;
  stroke-width: 1;
  stroke-dasharray: 527;
  stroke-dashoffset: -527;
  -webkit-animation: animSVGicoInstrucOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoInstrucOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoInstrucOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGicoInstrucOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 527; } }

@-ms-keyframes animSVGicoInstrucOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 527; } }

@keyframes animSVGicoInstrucOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 527; } }

#nav-main-list a.over .svg-anim-instr {
  stroke-dasharray: 527;
  stroke-dashoffset: 0;
  -webkit-animation: animSVGicoInstruc 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoInstruc 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoInstruc 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a.over .svg-anim-instr {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoInstrucSafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-instr {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

@-webkit-keyframes animSVGicoInstrucSafari {
  0% {
    stroke-dashoffset: 527; }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes animSVGicoInstruc {
  0% {
    stroke-dashoffset: -527; }
  100% {
    stroke-dashoffset: 0; } }

@-ms-keyframes animSVGicoInstruc {
  0% {
    stroke-dashoffset: -527; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes animSVGicoInstruc {
  0% {
    stroke-dashoffset: -527; }
  100% {
    stroke-dashoffset: 0; } }

#nav-main-list a .svg-anim-instr-rgt {
  fill: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-dasharray: 527;
  stroke-dashoffset: -527;
  -webkit-animation: animSVGicoInstrucRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoInstrucRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoInstrucRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a .svg-anim-instr-rgt {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 527;
  -webkit-animation: animSVGicoInstrucRgtSafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGicoInstrucRgtSafari {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 527; } }

@-webkit-keyframes animSVGicoInstrucRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -527; } }

@-ms-keyframes animSVGicoInstrucRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -527; } }

@keyframes animSVGicoInstrucRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -527; } }

#nav-main-list a.over .svg-anim-instr-rgt {
  stroke-dasharray: 527;
  stroke-dashoffset: 527;
  -webkit-animation: animSVGicoModalityInstrRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModalityInstrRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModalityInstrRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-instr-rgt {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

@-webkit-keyframes animSVGicoModalityInstrRgtOut {
  0% {
    stroke-dashoffset: 527; }
  100% {
    stroke-dashoffset: 0; } }

@-ms-keyframes animSVGicoModalityInstrRgtOut {
  0% {
    stroke-dashoffset: 527; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes animSVGicoModalityInstrRgtOut {
  0% {
    stroke-dashoffset: 527; }
  100% {
    stroke-dashoffset: 0; } }

#nav-main-list a .svg-anim-instr-p {
  fill: none; }

#nav-main-list a.over .svg-anim-instr-p {
  fill: #fff; }

/*** =main nav svg Ginasios */
#nav-main-list a .svg-anim-nut-plate-rgt {
  fill: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoNutPlatetRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoNutPlatetRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoNutPlatetRgt 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a .svg-anim-nut-plate-rgt {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoNutPlatetRgtSafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGicoNutPlatetRgtSafari {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 308; } }

@-webkit-keyframes animSVGicoNutPlatetRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -308; } }

@-ms-keyframes animSVGicoNutPlatetRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -308; } }

@keyframes animSVGicoNutPlatetRgt {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -308; } }

#nav-main-list a.over .svg-anim-nut-plate-rgt {
  stroke-dasharray: 308;
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoNutPlatetRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoNutPlatetRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoNutPlatetRgtOut 0.7s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-nut-plate-rgt {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

@-webkit-keyframes animSVGicoNutPlatetRgtOut {
  0% {
    stroke-dashoffset: 308; }
  100% {
    stroke-dashoffset: 0; } }

@-ms-keyframes animSVGicoNutPlatetRgtOut {
  0% {
    stroke-dashoffset: 308; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes animSVGicoNutPlatetRgtOut {
  0% {
    stroke-dashoffset: 308; }
  100% {
    stroke-dashoffset: 0; } }

#nav-main-list a .svg-anim-nut-plate-lft {
  fill: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

#nav-main-list a.over .svg-anim-nut-plate-lft {
  stroke-dasharray: 308;
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a.over .svg-anim-nut-plate-lft {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoModalitySafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-nut-plate-lft {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

#nav-main-list a .svg-anim-nut-rgt,
#nav-main-list a .svg-anim-nut-lft {
  /* =faca e garfo*/
  fill: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModalityOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

#nav-main-list a.over .svg-anim-nut-rgt,
#nav-main-list a.over .svg-anim-nut-lft {
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoModality 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a.over .svg-anim-nut-rgt,
.safari #nav-main-list a.over .svg-anim-nut-lft {
  /* =bug =safari not accept negative dashoffset*/
  stroke-dashoffset: 308;
  -webkit-animation: animSVGicoModalitySafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-nut-rgt,
.ie #nav-main-list a.over .svg-anim-nut-lft {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

/*#nav-main-list a .svg-anim-gym-down{
    opacity: 1;
    fill: none;
    stroke: #fff;
    stroke-width: .1;
    stroke-dasharray: 308;
    stroke-dashoffset: -308;
}*/
/*** =main nav svg horario */
#nav-main-list a .svg-anim-horar {
  fill: none;
  stroke: white;
  stroke-width: 1;
  stroke-dasharray: 308;
  stroke-dashoffset: -308;
  -webkit-animation: animSVGicoCorporateOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoCorporateOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoCorporateOut 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGicoCorporateOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 308; } }

@-ms-keyframes animSVGicoCorporateOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -308; } }

@keyframes animSVGicoCorporateOut {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -308; } }

#nav-main-list a.over .svg-anim-horar {
  stroke-dasharray: 270;
  stroke-dashoffset: 270;
  -webkit-animation: animSVGicoCorporate 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGicoCorporate 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGicoCorporate 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.safari #nav-main-list a.over .svg-anim-horar {
  /* =bug =safari not accept negative dashoffset */
  stroke-dashoffset: 270;
  -webkit-animation: animSVGicoCorporateSafari 0.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.ie #nav-main-list a.over .svg-anim-horar {
  stroke-dashoffset: 0;
  stroke-dashoffset: 0;
  -ms-animation-duration: 0; }

@-webkit-keyframes animSVGicoCorporateSafari {
  0% {
    stroke-dashoffset: 270; }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes animSVGicoCorporate {
  0% {
    stroke-dashoffset: 270; }
  100% {
    stroke-dashoffset: 0; } }

@-ms-keyframes animSVGicoCorporate {
  0% {
    stroke-dashoffset: 270; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes animSVGicoCorporate {
  0% {
    stroke-dashoffset: 270; }
  100% {
    stroke-dashoffset: 0; } }

/******* =MENU =NAV SEC *******/
.language-picker-mobile {
  position: absolute;
  left: 160px;
  display: none; }

.open-nav .language-picker-mobile {
  display: block;
  top: 26px;
  right: 20px;
  left: initial; }

.language-picker-mobile li {
  margin: 0 2px;
  display: inline-block; }

.language-picker-mobile li a {
  color: #5c5857;
  font-family: "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  font-size: 25px;
  text-transform: uppercase; }

.language-picker-mobile li a:hover,
.language-picker-mobile li a.on {
  color: #ffffff; }

.language-picker {
  position: absolute;
  left: 150px; }

#nav-main-sec .language-picker li {
  margin: 0 2px; }

#nav-main-sec .language-picker li a:hover,
#nav-main-sec .language-picker li a.on {
  color: #ffffff; }

#nav-main-sec {
  position: relative;
  z-index: 20;
  width: 100%;
  height: 58px;
  padding: 0 40px;
  font: normal 400 25px/25px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #5c5857;
  background: #252322; }

.no-csstransforms3d #nav-main-sec {
  position: absolute;
  left: 0;
  top: 0; }

#nav-main-sec ul {
  float: right;
  line-height: 58px; }

#nav-main-sec li {
  display: inline-block;
  margin: 0 6px; }

#nav-main-sec a {
  color: #5c5857; }

#nav-main-sec a:hover {
  color: #fff; }

#nav-main-sec .btn {
  position: absolute;
  left: 43px;
  top: 12px;
  height: 31px;
  font: normal 400 25px/35px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-indent: 45px;
  color: #5c5857; }

#nav-main-sec .btn:hover,
#nav-main-sec .btn.over {
  color: #fff; }

#nav-main-sec .btn-x {
  position: absolute;
  left: 0;
  top: 0; }

.no-csstransforms3d #nav-main-sec .btn-x {
  display: none; }

#nav-main-sec .btn-x:before,
#nav-main-sec .btn-x:after {
  background: #5c5857; }

#nav-main-sec .btn:hover .btn-x:before,
#nav-main-sec .btn.over .btn-x:before {
  background: #fff;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg); }

#nav-main-sec .btn:hover .btn-x:after,
#nav-main-sec .btn.over .btn-x:after {
  background: #fff;
  -webkit-transform: rotate3d(0, 0, 1, 135deg);
  -ms-transform: rotate3d(0, 0, 1, 135deg);
  -moz-transform: rotate3d(0, 0, 1, 135deg);
  transform: rotate3d(0, 0, 1, 135deg); }

#nav-main-sec a.facebook,
#nav-main-sec a.whatsapp,
#nav-main-sec a.twitter {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 6px; }

#nav-main-sec a.facebook {
  padding-left: 12px; }

#nav-main-sec a.twitter {
  padding-right: 0; }

#nav-main-sec a.facebook svg {
  width: 9px;
  height: 17px; }

#nav-main-sec a.whatsapp svg {
  width: 17px;
  height: 16px; }

#nav-main-sec a.twitter svg {
  width: 17px;
  height: 15px; }

#nav-main-sec a.facebook svg path,
#nav-main-sec a.whatsapp svg path,
#nav-main-sec a.twitter svg path {
  fill: #5c5857; }

#nav-main-sec a.facebook:hover svg path,
#nav-main-sec a.whatsapp:hover svg path,
#nav-main-sec a.twitter:hover svg path {
  fill: #ffffff; }

/** MUTE BUTTON , AUDIO**/
.mute-button {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 14px;
  display: block;
  width: 29px;
  height: 29px;
  margin-left: -11px;
  cursor: pointer; }

.mute-button .svg-anim-mute.bg {
  stroke: #242322;
  stroke-width: 2; }

.mute-button path {
  fill: none;
  stroke: #5c5857;
  stroke-width: 1;
  /*  -webkit-transition: stroke 0.2s linear;
          transition: stroke 0.2s linear;*/ }

.mute-button:hover .svg-anim-mute.bg {
  stroke: #242322; }

.mute-button:hover path {
  stroke: #ffffff; }

.mute-button.sound-on .svg-anim-mute {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  -webkit-transition: stroke-dashoffset 0.45s cubic-bezier(0.76, 0, 0.18, 1);
  transition: stroke-dashoffset 0.45s cubic-bezier(0.76, 0, 0.18, 1); }

.mute-button .svg-anim-mute {
  stroke-dasharray: 100;
  stroke-dashoffset: 200;
  -webkit-transition: stroke-dashoffset 0.45s cubic-bezier(0.76, 0, 0.18, 1);
  transition: stroke-dashoffset 0.45s cubic-bezier(0.76, 0, 0.18, 1); }

.ismobile .mute-button {
  display: none; }

.mobile .mute-button {
  display: none; }

.mute-button.hide {
  visibility: hidden; }

/*-------------------------------------------------------------------------
=CONTENT WRAPPER and =PAGE TRANSITIONS =PRELOADING
NOTE: .page-main - if i use translatez menu not fixes on chrome!
-------------------------------------------------------------------------*/
.page-main {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  /* needed when animate elements outside the document*/ }

.page-next,
.page-prev {
  /* prevent paint with values = zero */
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  min-height: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.js-fixed-all .page-next,
.js-fixed-all .page-prev {
  width: 100%;
  height: auto;
  min-height: 100%; }

.preload,
.holderLoad {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 5px;
  height: 5px;
  overflow: hidden; }

/*-------------------------------------------------------------------------
=TRANSITIONS =AJAX
-------------------------------------------------------------------------*/
#transition {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

#transition.js-byrefresh {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

#transition .transition-svg {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

#transition .transition-svg path {
  fill: #252322; }

.grayTransition #transition .transition-svg path {
  fill: #bab0aa; }

/*-------------------------------------------------------------------------
=TRANSITIONS =AJAX =FADE (like modalities all)
-------------------------------------------------------------------------*/
#transition-fade {
  display: none;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(0, 0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

#transition-circle {
  display: none;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  margin: -100px 0 0 -100px;
  /* no translate -50% because of the animation scale*/
  border-radius: 50% 50%;
  -webkit-transform: translate3d(0, 0, 0) scale(0.1);
  transform: translate3d(0, 0, 0) scale(0.1); }

.mobile #transition-fade,
.mobile #transition-circle {
  display: none; }

/*-------------------------------------------------------------------------
=TRANSITIONS =MODALITIES look a like =AJAX (flip)
-------------------------------------------------------------------------*/
body .transition-modalities {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.transition-modalities .transition-modalities-bg {
  display: block;
  position: absolute;
  z-index: 10;
  width: 120%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg); }

/*-------------------------------------------------------------------------
=LOADING ANIM PAGES
NOTE. because i use srcset for retina i dont need special css for it!
-------------------------------------------------------------------------*/
.loadigPage-anim {
  display: none;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 225px;
  height: 209px;
  margin: -112px 0 0 -104px;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.loadigPage-anim img.animate-sprite {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  overflow: hidden;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.loadigPage-anim.transition-circle-loading {
  display: none;
  position: fixed;
  z-index: 3000; }

.loadigPage-anim.transition-circle-loading.fadeout {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

/*.loadigPage-mobile-anim{
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  width: 141px;
  height: 174px;
  margin: -90px 0 0 -87px;
  overflow: hidden;
  background: transparent url(/public/imgs/phive-anim_0-mobile.png) no-repeat 0 0;
  background-size: auto 174px;
}

.loadigPage-mobile-anim.anim-go {
  -webkit-animation: mobile-anim 0.7s steps(24, end) infinite;
      -ms-animation: mobile-anim 0.7s steps(24, end) infinite;
          animation: mobile-anim 0.7s steps(24, end) infinite;
}

@-webkit-keyframes mobile-anim {
    from { background-position: 0px; }
    to { background-position: -3384px; }
}

@-moz-keyframes mobile-anim {
    from { background-position: 0px; }
    to { background-position: -3384px; }
}

@keyframes mobile-anim {
    from { background-position: 0px; }
    to { background-position: -3384px; }
}*/
/*-------------------------------------------------------------------------
    =HORARIOS
-------------------------------------------------------------------------*/
.content-horarios {
  position: relative;
  display: block;
  width: 100vw;
  min-height: 700px;
  z-index: 100;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .content-horarios {
      min-height: 60vh; } }
  .content-horarios .scroll-wrapper-horizontal {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 700px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    @media screen and (max-width: 767px) {
      .content-horarios .scroll-wrapper-horizontal {
        min-height: 60vh; } }
    .content-horarios .scroll-wrapper-horizontal .gym-column {
      position: relative;
      width: 45vw;
      height: 100%;
      min-height: 700px;
      overflow: hidden;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-transition-property: -webkit-transform;
      transition-property: transform;
      -webkit-transition: width 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125), -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125);
      transition: width 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125), transform 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125); }
      @media screen and (max-width: 1080px) {
        .content-horarios .scroll-wrapper-horizontal .gym-column {
          width: 85vw; } }
      @media screen and (max-width: 767px) {
        .content-horarios .scroll-wrapper-horizontal .gym-column {
          min-height: 60vh; } }
      .content-horarios .scroll-wrapper-horizontal .gym-column.open {
        width: 100vw; }
      .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(odd) {
        background-color: #9d8677; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(odd) .btn-rect {
          border-color: #52433a;
          color: #52433a; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(odd) .btn-rect.on,
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(odd) .btn-rect:hover {
          background: #52433a;
          color: #fff; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(odd) .ico-s {
          background-color: #52433a !important; }
      .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) {
        background-color: #5f4d42; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .btn-rect {
          border-color: #fff;
          color: #fff; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .btn-rect.on,
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .btn-rect:hover {
          background: #fff;
          color: #52433a; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .ico-s {
          background-color: #9d8679 !important; }
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .btn-x.btn-dbrown::before,
        .content-horarios .scroll-wrapper-horizontal .gym-column:nth-child(even) .btn-x.btn-dbrown::after {
          background: #fff; }
      .content-horarios .scroll-wrapper-horizontal .gym-column h3 {
        pointer-events: none;
        transition: transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
        -webkit-transition: -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
        .content-horarios .scroll-wrapper-horizontal .gym-column h3:before, .content-horarios .scroll-wrapper-horizontal .gym-column h3:after,
        .content-horarios .scroll-wrapper-horizontal .gym-column h3 .city {
          transition: transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
      .content-horarios .scroll-wrapper-horizontal .gym-column .click-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer; }
      .content-horarios .scroll-wrapper-horizontal .gym-column .grid-content {
        position: relative;
        z-index: 1; }
      .content-horarios .scroll-wrapper-horizontal .gym-column.off .click-area {
        cursor: default; }
      @media (hover: hover) {
        .content-horarios .scroll-wrapper-horizontal .gym-column:not(.off):not(.open):hover h3 {
          transform: scale(1.05);
          -webkit-transform: scale(1.05); }
          .content-horarios .scroll-wrapper-horizontal .gym-column:not(.off):not(.open):hover h3:before {
            -webkit-transform: translateX(-50%) translateY(8px);
            transform: translateX(-50%) translateY(8px); }
          .content-horarios .scroll-wrapper-horizontal .gym-column:not(.off):not(.open):hover h3:after {
            -webkit-transform: translateX(-50%) translateY(-8px);
            transform: translateX(-50%) translateY(-8px); }
          .content-horarios .scroll-wrapper-horizontal .gym-column:not(.off):not(.open):hover h3 .city {
            -webkit-transform: translate3d(-50%, 8px, 0);
            transform: translate3d(-50%, 8px, 0); } }
  .content-horarios .gym-btn {
    display: none; }

.mobile .content-horarios .gym-btn {
  display: block; }
  @media screen and (max-width: 767px) {
    .mobile .content-horarios .gym-btn.prev {
      left: 20px; }
    .mobile .content-horarios .gym-btn.next {
      right: 20px; } }

.horarios .content-horarios {
  background-color: #252322; }

.no-borderimage .content-horarios {
  min-height: 400px; }

.ie11 .content-horarios {
  min-height: 100% !important; }

.content-horarios .gym-column.open .arrow-gyms {
  display: none; }

.content-horarios .gym-column.open {
  cursor: auto; }

@media screen and (max-width: 1023px) {
  .arrow-gyms {
    display: none; } }

.content-horarios .gym-column.close:before {
  content: "";
  width: 20px;
  height: 100%;
  background: inherit;
  position: absolute;
  display: block;
  top: 0;
  z-index: -1; }

/*.content-horarios .gym-column.close:first-of-type:before{
  left: 100%;
}*/
.content-horarios .grid-content {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%; }

.content-horarios h3,
.content-horarios p .btn {
  margin: 0 auto; }

.content-horarios .icon-horarios {
  position: absolute;
  left: 0;
  top: 50%;
  margin: -73px 0 0 -80px;
  z-index: 50; }

.content-horarios .gym-column.close:before {
  position: absolute;
  top: 0;
  content: " ";
  width: 100%;
  height: 100%;
  cursor: pointer; }

.content-horarios .gym-column.close.off:before {
  cursor: default; }

.content-horarios .gym-column.close:hover {
  /*    width: 18%;
-webkit-transition: width .26s ease-in-out;
   -moz-transition: width .26s ease-in-out;
     -o-transition: width .26s ease-in-out;
        transition: width .26s ease-in-out;*/
  cursor: pointer; }

.content-horarios .gym-column.move-hover {
  width: 55%; }

.mobile .content-horarios .gym-column.move-hover {
  width: 50%; }

.gym-column .hover-closed,
.gym-column .hover-closed-lagrimas {
  display: none; }

.gym-column.close .hover-closed,
.gym-column.close .hover-closed-lagrimas {
  display: block; }

.hover-closed {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: #151a1f;
  cursor: pointer;
  -webkit-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1);
  /* older webkit */
  -webkit-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  /* custom */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  /* custom */ }

.hover-closed-lagrimas {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background: #b9afa9;
  cursor: pointer;
  -webkit-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1);
  /* older webkit */
  -webkit-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition: left 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  /* custom */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  /* custom */ }

.content-horarios .gym-column.move {
  /*transform: translateX(20px); =daniel - cant be translate because of position fixed*/ }

.content-horarios .gym-column:first-of-type.move {
  /*transform: translateX(-20px);*/ }

.modalidades .content-horarios .gym-column:before {
  position: absolute;
  left: 0;
  top: 0;
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 50;
  pointer-events: none; }

.modalidades .content-horarios .gym-column.open:before {
  z-index: 0; }

.modalidades .content-horarios .gym-column:nth-child(odd) {
  background-color: transparent; }
  .modalidades .content-horarios .gym-column:nth-child(odd):before {
    background-color: rgba(0, 0, 0, 0.05); }

.modalidades .content-horarios .gym-column:nth-child(even) {
  background-color: transparent; }
  .modalidades .content-horarios .gym-column:nth-child(even):before {
    background-color: rgba(255, 255, 255, 0.1); }

.modalidades .content-horarios .off .grid-content {
  position: relative;
  opacity: 0.3; }

.content-horarios .off .gym-name.white::before {
  background: transparent url(/public/imgs/title-top-arc-white-off.svg) no-repeat center center;
  top: -100px;
  opacity: 1; }

.content-horarios .off .gym-name.white::after {
  background: transparent url(/public/imgs/title-bottom-arc-white-off.svg) no-repeat center center;
  top: 100px;
  opacity: 1; }

.modalidades .content-horarios .scroll-wrapper-horizontal .gym-column .btn-rect {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.25); }

.modalidades .content-horarios .scroll-wrapper-horizontal .gym-column .btn-rect.on,
.modalidades .content-horarios .scroll-wrapper-horizontal .gym-column .btn-rect:hover {
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: transparent; }

.modalidades .content-horarios .scroll-wrapper-horizontal .gym-column .ico-s {
  background-color: rgba(0, 0, 0, 0.2) !important; }

.modalidades .content-horarios .gym-btn {
  background-color: rgba(0, 0, 0, 0.2); }

/*.content-horarios .off .grid-content::before {
  position: absolute;
  bottom: -105px;
  left: 42.5%;
  content: " ";
  height: 4px;
  width: 302px;
  background-color: #fff;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate3d(0, 0, 1, -65.5deg);
  -ms-transform: rotate3d(0, 0, 1, -65.5deg);
  transform: rotate3d(0, 0, 1, -65.5deg);
}*/
.content-horarios .off.close .grid-content {
  position: initial; }

/******* =HORARIOS TABELAS *******/
.table-horarios-wrapper {
  position: relative;
  width: 100%;
  height: 100%; }

.nav-table {
  position: relative;
  top: -62px;
  text-align: right;
  max-width: 50%;
  margin-left: auto; }
  @media screen and (max-width: 1280px) {
    .nav-table {
      top: auto;
      max-width: 100%;
      text-align: left;
      margin: 50px 0; } }
  @media screen and (max-width: 1200px) {
    .nav-table {
      margin: 0; } }

.nav-table li {
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px; }
  @media screen and (max-width: 1280px) {
    .nav-table li {
      margin-bottom: 0; } }
  @media screen and (max-width: 1200px) {
    .nav-table li {
      margin-bottom: 20px; } }

.nav-table li:first-child {
  margin: 0; }

.content-horarios .dowl-pdf {
  position: relative;
  top: 0;
  right: auto;
  width: 100%;
  margin: 80px 0 0 0;
  text-align: center; }

.content-horarios .btn-close {
  position: absolute;
  top: -172px;
  right: -98px;
  width: 35px;
  height: 35px; }

.content-horarios .btn-close .btn-x {
  top: 21px;
  /*For position Fix after container get to top of window*/
  width: 35px;
  height: 35px; }

.content-horarios .btn-close.fix .btn-x {
  top: 106px;
  /*For position Fix after container get to top of window*/
  position: fixed; }

.lagrimas .content-horarios .btn-close .btn-x:before,
.btn-x:after {
  background: #151a1f; }

.nav-table .btn-rect {
  padding: 13px 27px 13px 27px; }

.lagrimas .btn-rect {
  border-color: #151a1f;
  color: #151a1f; }

.celas .btn-rect:hover,
.celas .btn-rect.on {
  background: #fff;
  color: #151a20; }

.lagrimas .btn-rect:hover,
.lagrimas .btn-rect.on {
  background: #151a1f;
  color: #b9afa9; }

.nav-table .btn-rect.on {
  cursor: default;
  pointer-events: none; }

/* =table */
.table-horarios-scroll {
  position: relative;
  width: 100%; }

.table-horarios {
  position: relative;
  width: 100%;
  font: normal 14px/14px "p-Medium", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  border-spacing: 1px 0;
  border-collapse: separate;
  /*background: transparent url(/public/imgs/horario-tabela-celas-bg.gif) repeat-y;*/ }

.table-horarios colgroup {
  display: none; }

.table-horarios thead {
  height: 52px; }

.lagrimas .table-horarios-scroll .table-horarios thead tr,
.celas .table-horarios-scroll .table-horarios thead tr {
  background: transparent; }

.table-horarios td,
.table-horarios th {
  position: relative;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  line-height: 42px;
  height: 42px;
  min-width: 122px;
  width: 14%;
  padding: 5px 0;
  overflow: hidden; }

.table-horarios td.double .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  background: transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0); }

.table-horarios td.double a {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 15px; }
  .table-horarios td.double a .txt {
    pointer-events: none;
    padding: 0;
    width: 200%;
    left: 50%;
    transform: scale(1.3) translateX(-50%);
    -webkit-transform: scale(1.3) translateX(-50%); }
  .table-horarios td.double a:hover .txt, .table-horarios td.double a.hover .txt {
    -webkit-transform: scale(1) translateX(-50%);
    -ms-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%); }

.mobile .table-horarios td.double .wrapper {
  flex-direction: column;
  -webkit-flex-direction: column; }

.mobile .table-horarios td.double a {
  margin: 2px 0; }
  .mobile .table-horarios td.double a .txt {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    left: auto;
    line-height: 1; }

.table-horarios th {
  border: 0; }

.table-horarios-scroll .table-horarios td:first-child,
.table-horarios-scroll .table-horarios th:first-child {
  border: 0;
  width: 122px;
  min-width: 122px;
  max-width: 122px; }

.celas .table-horariosX td {
  border-left: 1px solid #151a1f; }

.lagrimas .table-horariosX td {
  border-left: 1px solid #b9afa9; }

.table-horarios tr {
  display: block;
  width: 100%;
  height: 52px;
  overflow: hidden; }

.lagrimas .table-horarios tr:nth-child(odd) td {
  background: rgba(0, 0, 0, 0.09); }

.celas .table-horarios tr:nth-child(odd) td {
  background: rgba(255, 255, 255, 0.1);
  /* =changed from 0.05*/ }

.lagrimas .table-horarios tr:nth-child(even) td,
.celas .table-horarios tr:nth-child(even) td {
  background: transparent; }

.table-horarios td a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  background: transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0); }

.modalidades .table-horarios td a,
.table-horarios td a.mod-no-click,
a.mod-no-click {
  cursor: default; }

.table-horarios td .txt {
  position: absolute;
  top: 0;
  left: 0;
  font: normal 12px/42px "p-Medium", Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
  height: 42px;
  padding-top: 4px;
  opacity: 0;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.table-horarios td a .ico-s {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.table-horarios td a:hover .ico-s,
.table-horarios td a.hover .ico-s {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0); }

.table-horarios td a:hover .txt,
.table-horarios td a.hover .txt {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

/* =estudios*/
.table-horarios td a {
  /*display: none;*/
  z-index: 1;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  transition: all 0.2s ease-out; }

.table-horarios.estudio1 td a.estudio1,
.table-horarios.estudio2 td a.estudio2,
.table-horarios.estudio3 td a.estudio3,
.table-horarios.estudio4 td a.estudio4,
.table-horarios.estudio5 td a.estudio5,
.table-horarios.piscina td a.piscina,
.table-horarios.estudio-exterior td a.estudio-exterior {
  /*display: block;*/
  z-index: 2;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  /* transition: transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275)*/ }

/* Exception for mobile horarios */
.mobile .horarios .table-horarios td .txt {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.mobile .horarios .table-horarios td a .ico-s {
  display: none; }

/* A melhor opção é tirar o href do link
.modalidades .table-horarios td a { pointer-events: none; }*/
@media screen and (max-width: 1460px) {
  .table-horarios thead th span {
    display: none; }
  .table-horarios td,
  .table-horarios th {
    min-width: 0; } }

/*** =OPEN / CLOSE horarios **/
.content-horarios .open {
  min-height: 1030px; }

@media screen and (max-width: 767px) {
  .content-horarios .open {
    min-height: 0; } }

.content-horarios.open .gym-column.open {
  padding: 125px 125px;
  z-index: 10; }

.no-csstransforms3d .content-horarios.open .gym-column.open {
  width: 100%; }

.no-csstransforms3d .content-horarios.open .gym-column.close {
  display: none; }

.content-horarios .close p .btn {
  margin: 0 auto;
  z-index: 10; }

.content-horarios .table-horarios-wrapper {
  display: none; }

.content-horarios .open .table-horarios-wrapper {
  display: block;
  -webkit-animation: animOpenHorario 0.5s ease-in-out forwards;
  -ms-animation: animOpenHorario 0.5s ease-in-out forwards;
  animation: animOpenHorario 0.5s ease-in-out forwards; }

.content-horarios.open .icon-horarios,
.content-horarios.open .icon-horarios-bw {
  display: none; }

.content-horarios .open h3 {
  padding: 0;
  text-align: left;
  margin: 0 auto;
  max-width: 100%;
  -webkit-animation: animOpenHorario 0.5s ease-in-out forwards;
  -ms-animation: animOpenHorario 0.5s ease-in-out forwards;
  animation: animOpenHorario 0.5s ease-in-out forwards; }
  .content-horarios .open h3 .city {
    top: -20px;
    left: 0;
    -webkit-transform: none;
    transform: none; }
    @media screen and (max-width: 767px) {
      .content-horarios .open h3 .city {
        top: -10px; } }

@-webkit-keyframes animOpenHorario {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes animOpenHorario {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animOpenHorario {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes animCloseHorario {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes animCloseHorario {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animCloseHorario {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes animCloseHorarioOff {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

@-ms-keyframes animCloseHorarioOff {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

@keyframes animCloseHorarioOff {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

/*-------------------------------------------------------------------------
    =FOOTER
    NOTE: =webkit =chrome bug: width display none, when video is fixed full screen i have a movement of the page when scrolling!!!
-------------------------------------------------------------------------*/
.push-footer {
  position: relative;
  z-index: 100;
  margin-bottom: 550px; }

.footer {
  position: fixed;
  z-index: 90;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 550px;
  /*padding: 67px 86px;*/
  padding: 67px 40px;
  background-color: #252322; }

.safari .footer {
  bottom: 0;
  top: calc(100vh - 550px); }

.safari .mobile .footer {
  top: 0; }

.mobile .push-footer {
  margin-bottom: 0; }

.mobile .footer {
  position: relative; }

.footer p {
  color: #fff;
  font: normal 32px/36px "p-Light", Helvetica, Arial, sans-serif; }

.footer p.designBy {
  position: absolute;
  z-index: 22;
  /* for instrutor footer */
  /*left: 86px;*/
  left: 42px;
  bottom: 35px;
  font: normal 15px/15px "p-regular", Helvetica, Arial, sans-serif;
  color: #ffffff; }

.mobile .home .footer p.designBy {
  left: 120px; }
  @media screen and (max-width: 767px) {
    .mobile .home .footer p.designBy {
      left: auto;
      bottom: 40px; } }

.mobile .home .rgpd-link {
  right: 120px; }

.footer p.designBy a {
  color: #ffffff; }

.footer .social {
  position: absolute;
  overflow: visible;
  z-index: 25;
  top: 74px;
  /*right: 74px;*/
  right: 26px; }

.footer .social li {
  margin-left: 28px; }

.footer li.facebook .icon-svg {
  height: 22px;
  width: 12px; }

.footer li.twitter .icon-svg {
  height: 20px;
  width: 24px; }

.footer li.instag .icon-svg {
  height: 20px;
  width: 24px; }

.footer li.utube .icon-svg {
  height: 19px;
  width: 27px; }

.footer .social a {
  position: relative;
  display: inline-block;
  padding: 0 10px; }

.footer .social li a svg {
  position: relative;
  z-index: 5;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.15s ease-in-out;
  -ms-transition: -ms-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out; }

.footer .social li a:hover svg {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2); }

.id-sec {
  position: relative;
  width: 36px;
  height: 35px;
  margin-bottom: 80px;
  background: transparent url(/public/imgs/id/phive-id-p.svg) no-repeat;
  background-size: 150px auto; }

.footer-team .id-sec {
  margin-bottom: 0; }

.id-sec-full {
  position: relative;
  width: 167px;
  height: 38px;
  margin-bottom: 80px;
  background: transparent url(/public/imgs/id/phive-id.svg) no-repeat;
  background-size: auto 38px; }

.id-sec a,
.id-sec-full a {
  position: absolute;
  width: 100%;
  height: 100%;
  text-indent: -5555px; }

/* =newsletter*/
#subForm {
  position: relative;
  margin: 40px 0 60px 0;
  overflow: hidden; }

#subForm .email {
  display: block;
  position: relative;
  float: left;
  width: 474px;
  height: 53px;
  padding: 10px 24px;
  font: 18px/18px "p-Light", Helvetica, Arial, sans-serif;
  color: #3b3939;
  background: transparent;
  border: 2px solid #3b3939;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }
  #subForm .email::placeholder {
    color: #3b3939; }

#subForm .email:focus,
#subForm.focus-on .email {
  color: #ffffff;
  border: 2px solid #ffffff; }

#subForm .btn-submit {
  display: block;
  position: relative;
  float: left;
  width: 170px;
  height: 53px;
  font: 17px/53px "p50w", Helvetica, Arial, sans-serif;
  font: normal 400 18px/52px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #252322;
  background: #3b3939;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none; }
  #subForm .btn-submit.enable {
    pointer-events: visible;
    background: #ffffff; }

/*#subForm.focus-on .btn-submit {background: #ffffff;}*/
#subForm .btn-submit.sent,
#subForm .btn-submit.error,
#subForm .btn-submit.sending {
  pointer-events: none;
  cursor: default; }

#subForm .btn-submit .bg {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  /*background-color: #505167;*/
  background-color: #f3cd53;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: background-color 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: background-color 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: background-color 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

#subForm .btn-submit.sending .bg {
  -webkit-animation: animSending 2s ease forwards;
  -ms-animation: animSending 2s ease forwards;
  animation: animSending 2s ease forwards; }

@-webkit-keyframes animSending {
  0% {
    -webkit-transform: scaleX(0); }
  30% {
    -webkit-transform: scaleX(0.5); }
  60% {
    -webkit-transform: scaleX(0.8); }
  100% {
    -webkit-transform: scaleX(0.95); } }

@-ms-keyframes animSending {
  0% {
    -ms-transform: scaleX(0); }
  30% {
    -ms-transform: scaleX(0.5); }
  60% {
    -ms-transform: scaleX(0.8); }
  100% {
    -ms-transform: scaleX(0.95); } }

@keyframes animSending {
  0% {
    transform: scaleX(0); }
  30% {
    transform: scaleX(0.5); }
  60% {
    transform: scaleX(0.8); }
  100% {
    transform: scaleX(0.95); } }

#subForm .btn-submit.done .bg {
  -webkt-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1); }

#subForm .btn-submit .txt,
#subForm .btn-submit .ico {
  position: absolute;
  z-index: 5;
  top: 2px;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

#subForm .btn-submit.sent .txt,
#subForm .btn-submit.error .txt {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0); }

#subForm .btn-submit .ico {
  z-index: 4;
  opacity: 0;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.btn-submit .ico-x {
  background-size: 25px 25px; }

.btn-submit .ico-ok {
  background-size: 35px 35px; }

#subForm .btn-submit.sent .ico-ok,
#subForm .btn-submit.error .ico-x {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

#subForm .btn-submit.sent .bg {
  background-color: #1ecd97; }

#subForm .btn-submit.error .bg {
  background-color: #fb797e; }

#subForm .rgpd-wrapper {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  opacity: 0;
  margin-top: 80px;
  transition: opacity 0.3s ease-out; }

#subForm .rgpd-wrapper.show {
  opacity: 1; }

#subForm .rgpd-wrapper input {
  opacity: 0;
  visibility: hidden; }

/* =footer2-c */
.footer-2c {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0; }

.mobile .footer-2c {
  display: block;
  padding: 67px 40px; }

.footer-2c .cell-lft {
  position: relative;
  width: 70%;
  padding: 67px 0 67px 40px; }

.mobile .footer-2c .cell-lft {
  position: static;
  width: 100%;
  padding: 0; }

.footer-2c .cell-rgt {
  position: relative;
  height: 100%;
  width: 30%;
  font-size: 40px;
  cursor: pointer; }

.footer-2c .cell-rgt .btn-js {
  display: block;
  width: 100%;
  height: 100%; }

.footer-2c .block-bg-cover::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background: #252322;
  opacity: 0.3;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease; }

.footer-2c .cell-rgt:hover .block-bg-cover::after {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease; }

.footer-2c .visual {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  -webkit-transform: scale(1) translate3d(0, 0, 0);
  transform: scale(1) translate3d(0, 0, 0);
  /*-webkit-transition: -webkit-transform .7s cubic-bezier(.17,.86,.28,.98); original*/
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.86, 0.28, 0.98);
  transition: transform 0.6s cubic-bezier(0.17, 0.86, 0.28, 0.98); }

.footer-2c .cell-rgt:hover .visual {
  /*-webkit-transform: scale(1.05) translate3d(0,0,0); original*/
  -webkit-transform: scale(1.2) translate3d(0, 0, 0);
  transform: scale(1.2) translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 8s cubic-bezier(0.04, 0.73, 0, 1.01);
  transition: transform 8s cubic-bezier(0.04, 0.73, 0, 1.01); }

.footer-2c .cell-rgt .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 67px 67px; }

.footer-2c .cell-rgt .text p {
  font: normal 46px/50px "p-Light", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px; }

.footer.footer-2c .btn-arrow-svg {
  position: absolute;
  left: 67px;
  bottom: 67px;
  pointer-events: none; }

.footer.footer-2c .btn-arrow-svg path {
  fill: #252322; }

/*.footer.footer-2c .text::after {
  position: absolute;
  right: 67px;
  bottom: 88px;
  width: 29px;
  height: 40px;
  content: "";
  background: transparent url(/public/imgs/icon-arrow-footer-next.svg) no-repeat;
}*/
@media screen and (max-width: 1400px) {
  .footer-2c .cell-lft {
    width: 65%; }
  .footer-2c .cell-rgt {
    width: 35%; } }

@media screen and (max-width: 1024px) {
  .push-footer {
    margin-bottom: 0; }
  .footer {
    position: relative;
    height: auto;
    padding: 60px 40px; }
  .footer .social {
    display: none; }
  .footer-2c .cell-lft {
    padding: 0;
    width: 100%; }
  .footer-2c .cell-rgt {
    display: none; }
  .mobile .footer-2c .cell-rgt {
    display: block; }
  .mobile .footer-2c {
    position: relative;
    width: 100%;
    height: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0; }
  .mobile .footer-2c .cell-lft {
    position: relative;
    width: 100%;
    min-height: 100%;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    padding: 60px 40px;
    height: auto; }
  .mobile .footer-2c .cell-rgt {
    position: relative;
    width: 100%;
    min-height: 100%;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    height: auto; }
  .mobile .footer-2c .cell-rgt .text {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 60px 40px; }
  .mobile .footer-2c .cell-rgt .text p {
    font-size: 36px;
    line-height: 40px; }
  .mobile .footer.footer-2c .btn-arrow-svg {
    position: absolute;
    top: 50%;
    left: auto;
    margin-top: -47px;
    right: 40px;
    bottom: 60px;
    pointer-events: none; }
  .mobile .footer.footer-2c .text::after {
    display: none; } }

@media screen and (max-width: 822px) {
  #subForm .email {
    width: 380px; } }

@media screen and (max-width: 767px) {
  .footer p {
    font: normal 23px/29px "p-light", Helvetica, Arial, sans-serif; }
  .footer p.designBy {
    position: relative;
    width: 100%;
    left: 0;
    text-align: center; }
  .rgpd-link {
    position: relative;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: auto;
    display: block; }
    .rgpd-link a:first-child {
      margin: 0;
      margin-bottom: 10px; }
  #subForm .email {
    width: 60%;
    min-width: 156px;
    padding: 10px 5px;
    font-size: 15px; }
  #subForm .btn-submit {
    width: 30%;
    min-width: 100px;
    font-size: 15px; } }

@media screen and (max-width: 566px) {
  .footer {
    padding: 60px 20px; }
  .mobile .footer-2c .cell-lft {
    padding: 60px 20px; } }

@media screen and (max-width: 414px) {
  .footer p br {
    display: none; }
  .mobile .footer-2c .cell-rgt .text {
    height: 350px;
    padding: 40px; }
  .mobile .footer.footer-2c .btn-arrow-svg {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
    right: auto;
    bottom: 100px;
    pointer-events: none; }
  .mobile .footer-2c .cell-rgt .text p {
    text-align: center; } }

/* tirei do html o span pois nao gostaram
.footer .social li a:hover svg{
    -webkit-animation: animSocialHover .35s ease-in-out forwards;
    -ms-animation: animSocialHover .35s ease-in-out forwards;
    animation: animSocialHover .35s ease-in-out forwards;
}
@-webkit-keyframes animSocialHover{
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.2); }
    100% { -webkit-transform: scale(1); }
}
@-ms-keyframes animSocialHover{
    0% { -ms-transform: scale(1); }
    50% { -ms-transform: scale(1.2); }
    100% { -ms-transform: scale(1); }
}
@keyframes animSocialHover{
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.footer .social a span{
    display: block;
    position: absolute; z-index: 1;
    left: -5555px; top: -10px;
    background: #fff;
    width: 38px; height: 38px;
    border-radius: 50% 50%;
    opacity: .5;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5);
    -webkit-transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    -ms-transition: opacity .25s ease-out, -ms-transform .25s ease-out;
    transition: opacity .25s ease-out, transform .25s ease-out;
}
.footer .social a:hover span{
    left: 2px; top: -10px;
    opacity: 0;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.footer .social .facebook a:hover span{
    left: -3px; top: -8px;
}
.footer .social .utube a:hover span{
    left: 4px; top: -9px;
}
*/
/*-------------------------------------------------------------------------
=GRID
-------------------------------------------------------------------------*/
.grid {
  position: relative;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.grid-cell,
.grid-cell-1of2,
.grid-cell-1of3,
.grid-cell-1of5 {
  position: relative;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.grid-cell-1of3 {
  max-width: 33%; }

.grid-cell-1of5 {
  max-width: 20%; }

.grid-cell-2of3 {
  max-width: 66%; }

.grid-cell-6of10 {
  max-width: 60%; }

.grid-cell-4of10 {
  max-width: 40%; }

.grid-cell-2of1 {
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto; }

.grid-cell-1of2 {
  width: 50%; }

.grid-cell-offset {
  padding-left: 60px; }

.no-flexbox .grid {
  float: none;
  overflow: hidden; }

.grid-cell {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.no-flexbox .grid-cell {
  float: left;
  width: 50%; }

.grid-center-vh {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center; }

.grid-center-h {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.grid-bottom-h {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.grid-flex-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.grid-flex110-list li {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0; }

.no-flexbox .grid-flex110-list li {
  display: inline-block; }

.align-flex-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.align-flex-vcenter {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

/*=Vertical Flex - Columns*/
.grid-flex-columns {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

/******* =TABLES *******/
.table-vh-center {
  display: table;
  border-collapse: collapse;
  text-align: center; }

.table-vh-center .table-cell {
  position: relative;
  display: table-cell;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle; }

/******* =SIZES *******/
.vp-fullH {
  height: 100vh; }

.vh-fullH-min {
  min-height: 100vh; }

.block-content {
  max-width: 1133px;
  margin: 0 auto; }

/** =LIKE BACKGROUND COVER **/
.block-bg-cover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

/* important can't use translate 3d with object-fit cover */
.block-bg-cover .element-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden; }

.block-bg-cover.overlay-darken:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.2; }

/* Exceptions for Firefox */
.firefox .footer .block-bg-cover .element-cover {
  max-width: none;
  width: auto; }

/*-------------------------------------------------------------------------
=CONTENT SECTIONS
-------------------------------------------------------------------------*/
/* =form centrado em full height section */
.content-form-centerVH {
  position: relative;
  padding: 120px 40px;
  overflow: hidden; }

.ginasios .content-form-centerVH {
  padding: 140px 40px; }

.content-form-centerVH form {
  position: relative;
  margin: 0 auto; }

/* =IMAGE with 100% with scale for translate */
.content-image-scale {
  position: relative;
  display: block;
  height: 0;
  padding-top: 49.43%;
  /* height/width of image (size for gyms) */ }

.element-image {
  width: 100%;
  max-width: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.element-image.to-anim {
  -webkit-transform: translate3d(0, 0, 0) scale(1.3);
  transform: translate3d(0, 0, 0) scale(1.3);
  -webkit-transition: -webkit-transform 8s cubic-bezier(0.04, 0.73, 0, 1.01);
  transition: transform 4s cubic-bezier(0.2, 0.74, 0, 1); }

.element-image.anim-go {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

.mobile .element-image.to-anim {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  -webkit-transition: none;
  transition: none; }

/* =Grid 2 Columns 50% like gym full height*/
.content-2c-fullH-gym {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden; }

.no-flexbox .content-2c-fullH-gym > grid-cell {
  height: 800px; }

.corporate .content-2c-fullH-gym {
  height: auto;
  flex-direction: column; }
  .corporate .content-2c-fullH-gym .gym-block-wrapper {
    width: 100%;
    display: flex;
    display: -webkit-flex; }
    @media screen and (max-width: 1023px) {
      .corporate .content-2c-fullH-gym .gym-block-wrapper {
        flex-direction: column;
        -webkit-flex-direction: column; } }
    .corporate .content-2c-fullH-gym .gym-block-wrapper .visual {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      width: auto;
      height: auto;
      flex-grow: 1;
      -webkit-flex-grow: 1; }
      @media screen and (max-width: 1023px) {
        .corporate .content-2c-fullH-gym .gym-block-wrapper .visual {
          -webkit-order: 1;
          -ms-flex-order: 1;
          order: 1;
          height: 60vh; } }
    .corporate .content-2c-fullH-gym .gym-block-wrapper .info {
      width: auto;
      height: auto;
      flex-direction: column;
      -webkit-flex-direction: column; }
      @media screen and (max-width: 1023px) {
        .corporate .content-2c-fullH-gym .gym-block-wrapper .info {
          padding: 50px 30px;
          -webkit-order: 2;
          -ms-flex-order: 2;
          order: 2;
          flex-direction: row;
          -webkit-flex-direction: row; } }
      @media screen and (max-width: 767px) {
        .corporate .content-2c-fullH-gym .gym-block-wrapper .info {
          padding: 40px 20px;
          flex-direction: column;
          -webkit-flex-direction: column; } }
      .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell {
        min-height: inherit;
        margin-bottom: 50px; }
        @media screen and (max-width: 1023px) {
          .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell {
            width: auto;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            margin-bottom: 0;
            margin-right: 20px; }
            .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell.grid-btn {
              margin: 0; }
              .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell.grid-btn .btn-show {
                display: flex;
                justify-content: flex-end; } }
        @media screen and (max-width: 767px) {
          .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell {
            margin-right: 0;
            margin-bottom: 30px;
            margin-top: 0; } }
        .corporate .content-2c-fullH-gym .gym-block-wrapper .info .grid-cell:last-child {
          margin-bottom: 0; }

.content-2c-fullH-gym .visual {
  position: relative;
  width: 100%;
  height: calc(100vh - 250px); }

.no-csscalc .content-2c-fullH-gym .visual {
  height: 550px; }

.content-2c-fullH-gym .picture,
.content-2c-gym .picture,
.anim-scale .picture {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.content-2c-fullH-gym .picture.to-anim-now,
.content-2c-gym .picture.to-anim-now,
.anim-scale .picture.to-anim-now {
  -webkit-transform: translate3d(0, 0, 0) scale(1.05);
  transform: translate3d(0, 0, 0) scale(1.05);
  -webkit-transition: -webkit-transform 4s cubic-bezier(0.04, 0.73, 0, 1.01);
  transition: transform 9s cubic-bezier(0.42, 0, 0.58, 1); }

.content-2c-fullH-gym .picture.anim-go,
.content-2c-gym .picture.anim-go,
.anim-scale .picture.anim-go {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

.mobile .content-2c-fullH-gym .picture,
.mobile .content-2c-gym .picture,
.mobile .anim-scale .picture {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  -webkit-transition: none;
  transition: none; }

.content-2c-fullH-gym .info {
  position: relative;
  width: 100%;
  height: 250px; }

/* =Grid 2 Columns 56% like gym full height*/
.content-2c-gym {
  position: relative;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden; }

.content-2c-gym .visual {
  position: relative;
  width: 44.42%;
  height: 0;
  padding-top: 45.59%;
  /* 775/1700*/ }

.content-2c-gym .info {
  position: relative;
  width: 55.58%; }

.content-2c-gym .info p {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 80px 80px 100px;
  font: normal 28px/38px "p-Light", Helvetica, Arial, sans-serif; }

/* =Grid contacts info - flex */
.info-contacts-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 75px; }

.info-contacts-flex .grid-cell {
  font: normal 400 20px/24px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.info-contacts-flex .grid-btn {
  width: 94px;
  min-height: 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.info-contacts-flex li {
  position: relative;
  overflow: hidden;
  display: block; }

.info-contacts-flex li span {
  display: block;
  float: left;
  max-width: 50%; }

.info-contacts-flex li span.title {
  width: 40%;
  min-width: 140px;
  max-width: 160px;
  opacity: 0.6; }

.info-contacts-flex li span.title.short {
  min-width: 100px;
  width: 100px; }

.info-contacts-flex li span.title.short + span {
  min-width: 145px;
  width: 100%; }

@media screen and (max-width: 1487px) {
  .content-2c-fullH-gym .info-contacts-flex {
    height: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .content-2c-fullH-gym .info-contacts-flex .grid-cell {
      margin-top: 40px; }
      .content-2c-fullH-gym .info-contacts-flex .grid-cell:first-child {
        margin-top: 0; }
  .corporate .content-2c-fullH-gym .visual {
    height: calc(100vh - 338px); }
  .info-contacts-flex li span.title.short {
    width: 40%;
    min-width: 140px;
    max-width: 160px; } }

@media screen and (max-width: 1160px) {
  .content-2c-fullH-gym .info-contacts-flex {
    padding: 50px; }
  .corporate .content-2c-fullH-gym .visual {
    height: calc(100vh - 288px); } }

@media screen and (max-width: 1024px) {
  .content-2c-fullH-gym .visual {
    height: calc(100vh - 250px); }
  .content-2c-fullH-gym {
    height: auto;
    min-height: 0;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .content-2c-fullH-gym .info-contacts-flex {
    position: relative;
    width: 100%;
    height: 250px;
    padding: 75px;
    -webkit-flex-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit; }
  .info-contacts-flex .grid-cell {
    min-height: 100%; }
  .info-contacts-flex .grid-cell:nth-child(2) {
    margin-top: 0; }
  .info-contacts-flex .grid-btn {
    position: relative;
    top: auto;
    right: auto;
    height: auto;
    min-height: 0;
    margin-top: 0 !important; }
  .info-contacts-flex .grid-btn .btn-show {
    position: relative;
    top: auto;
    margin-top: 0; }
  .info-contacts-flex li span.title.short {
    min-width: 100px;
    width: 100px; }
  .content-2c-fullH-gym div.grid-cell:nth-child(2) {
    position: relative;
    width: 100%;
    height: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .content-2c-fullH-gym div.grid-cell:nth-child(2) .info-contacts-flex {
    position: relative;
    width: 100%;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .content-2c-fullH-gym div.grid-cell:nth-child(2) .visual {
    position: relative;
    width: 100%;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .content-2c-gym .visual {
    padding-top: 64.59%; }
  .content-2c-gym .info p {
    padding: 75px; }
  /* GINASIOS SINGLE */
  .ginasios.celas
.content-2c-fullH-gym:first-of-type
div.grid-cell:nth-child(1) {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .ginasios.celas
.content-2c-fullH-gym:first-of-type
div.grid-cell:nth-child(2) {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .content-2c-gym {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .content-2c-gym .visual {
    width: 100%; }
  .content-2c-gym .info {
    width: 100%; }
  .content-2c-gym .info p {
    position: relative; } }

@media screen and (max-width: 750px) {
  .content-2c-fullH-gym .info-contacts-flex {
    height: auto;
    padding: 50px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .info-contacts-flex .grid-cell {
    min-height: 50px;
    height: auto; }
  .info-contacts-flex .grid-cell:nth-child(2) {
    margin-top: 20px; }
  .info-contacts-flex .grid-btn {
    position: absolute;
    top: 0;
    right: 50px;
    height: 100%;
    margin-top: 0 !important; }
  .info-contacts-flex .grid-btn .btn-show {
    top: 50%;
    margin-top: -47px; }
  .content-2c-fullH-gym .visual {
    height: calc(100vh - 288px); }
  .info-contacts-flex li span.title.short {
    width: 40%;
    min-width: 140px;
    max-width: 160px; } }

@media screen and (max-width: 568px) {
  .content-2c-fullH-gym .info-contacts-flex {
    height: auto; }
  .content-2c-fullH-gym .visual {
    height: 400px; }
  .info-contacts-flex .grid-btn {
    position: relative;
    top: auto;
    right: auto;
    height: auto;
    min-height: 0;
    margin-top: 50px !important;
    margin-left: 0; }
  .info-contacts-flex .grid-btn .btn-show {
    position: relative;
    top: auto;
    margin-top: 0; }
  .info-contacts-flex li span {
    margin-left: 10px; }
  .info-contacts-flex li span.title {
    margin-left: 0; }
  .info-contacts-flex li span.title.short + span {
    min-width: 100px;
    width: 42%; }
  .ginasios .content-2c-fullH-gym .visual,
  .ginasios .content-2c-gym .visual {
    height: 400px;
    padding-top: 0; }
  .ginasios .content-2c-gym .info p {
    font-size: 22px;
    line-height: 32px;
    padding: 50px; } }

@media screen and (max-width: 414px) {
  .ginasios header.vp-fullH {
    height: 400px;
    height: 50vh; }
  .content-2c-fullH-gym .info-contacts-flex {
    padding: 50px 30px; }
  .info-contacts-flex .grid-btn {
    margin-top: 50px; }
  .ginasios .content-2c-gym .info p {
    padding: 50px 30px;
    font-size: 18px;
    line-height: 24px; } }

@media screen and (max-height: 635px) and (min-width: 1024px) {
  .corporate .content-2c-fullH-gym {
    height: auto; }
  .corporate .content-2c-fullH-gym .visual {
    height: 450px; }
  .content-2c-gym .info p {
    font-size: 24px;
    line-height: 34px; } }

@media screen and (max-height: 600px) {
  .ginasios header.vp-fullH {
    height: 300px; } }

@media screen and (max-height: 550px) {
  .content-2c-fullH-gym .visual,
  .corporate .content-2c-fullH-gym .visual {
    height: 300px; } }

/*-------------------------------------------------------------------------
=BUTTONS
-------------------------------------------------------------------------*/
.btn {
  display: inline-block;
  cursor: pointer;
  font: normal 400 20px/22px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 0; }

.btn-square-center,
.btn-rect {
  position: relative;
  display: table;
  border-collapse: collapse;
  text-align: center;
  text-transform: uppercase;
  width: 94px;
  height: 94px;
  border: 3px solid #fff; }

.btn-square-center span {
  position: relative;
  display: table-cell;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle; }

button.btn-square-center span {
  position: relative;
  display: inline;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle; }

.btn-square {
  width: 94px;
  height: 94px; }

.btn-square-center.cross {
  pointer-events: none; }

.btn-square-center.cross:after {
  position: absolute;
  bottom: -1px;
  left: 1px;
  content: " ";
  height: 3px;
  width: 125px;
  background-color: #fff;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -ms-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg); }

.btn-rect {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 13px 28px 13px 31px; }

.btn-rect.arrow span {
  padding-right: 23px;
  background: transparent url(/public/imgs/icon-arrow-btn.png) no-repeat 123px 8px;
  /*120px was 100%*/
  background-size: 6px auto;
  -webki-transition: background-position 0.26s ease-in-out;
  -ms-transition: background-position 0.26s ease-in-out;
  transition: background-position 0.26s ease-in-out; }

.btn-rect.arrow:hover span {
  background-position: 129px 8px; }

.cursor-pointer {
  cursor: pointer; }

/* VER MODALIDADE */
.btn-rect.watch-modality {
  display: inline-block;
  width: 210px;
  height: 54px;
  /*width: auto; height: auto;*/
  padding: 13px 28px 13px 28px;
  border-color: #ffffff;
  color: #ffffff; }

.btn-rect.watch-modality .open-modality {
  position: absolute;
  right: 14px; }

.btn-rect.watch-modality .button-text {
  margin-left: -40px; }

.btn-rect.watch-modality:hover {
  background-color: transparent;
  color: #ffffff; }

/* right - skew exception */
.btn-rect.watch-modality.rgt .bg {
  -webkit-transform: translate3d(0, -100%, 0) skewY(19deg);
  -ms-transform: translate3d(0, -100%, 0) skewY(19deg);
  transform: translate3d(0, -100%, 0) skewY(19deg);
  -webkit-animation: animBtnWatchModalityBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnWatchModalityBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnWatchModalityBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnWatchModalityBgOutRgt {
  0% {
    -webkit-transform: translate3d(0, 0, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate3d(150%, 0, 0) skewX(-19deg); } }

@-ms-keyframes animBtnWatchModalityBgOutRgt {
  0% {
    -ms-transform: translate3d(0, 0, 0) skewX(0); }
  100% {
    -ms-transform: translate3d(150%, 0, 0) skewX(-19deg); } }

@keyframes animBtnWatchModalityBgOutRgt {
  0% {
    transform: translate3d(0, 0, 0) skewX(0deg); }
  100% {
    transform: translate3d(150%, 0, 0) skewX(-19deg); } }

.mobile .btn-rect.watch-modality.rgt .bg,
.mobile .btn-rect.watch-modality.lft .bg {
  display: none; }

.btn-rect.watch-modality.rgt.over .bg {
  -webkit-animation: animBtnWatchModalityBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1) forwards;
  -ms-animation: animBtnWatchModalityBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1) forwards;
  animation: animBtnWatchModalityBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1) forwards; }

@-webkit-keyframes animBtnWatchModalityBgRgt {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-19deg); }
  100% {
    -webkit-transform: translate3d(0, 0, 0) skewX(0deg); } }

@-ms-keyframes animBtnWatchModalityBgRgt {
  0% {
    -ms-transform: translate3d(-100%, 0, 0) skewX(-19deg); }
  100% {
    -ms-transform: translate3d(0, 0, 0) skewX(0deg); } }

@keyframes animBtnWatchModalityBgRgt {
  0% {
    transform: translate3d(-100%, 0, 0) skewX(-19deg); }
  100% {
    transform: translate3d(0, 0, 0) skewX(0deg); } }

.btn-rect.watch-modality .watch {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: " ";
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-animation: animBtnWatchModalityOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnWatchModalityOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnWatchModalityOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnWatchModalityOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(100%, 0, 0); } }

@-ms-keyframes animBtnWatchModalityOut {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(100%, 0, 0); } }

@keyframes animBtnWatchModalityOut {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(100%, 0, 0); } }

.btn-rect.watch-modality.over .watch {
  -webkit-animation: animBtnWatchModality 0.25s ease-out 0.05s forwards;
  -ms-animation: animBtnWatchModality 0.25s ease-out 0.05s forwards;
  animation: animBtnWatchModality 0.25s ease-out 0.05s forwards; }

@-webkit-keyframes animBtnWatchModality {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animBtnWatchModality {
  0% {
    -ms-transform: translate3d(-100%, 0, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animBtnWatchModality {
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.btn-rect.watch-modality .container {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 48px;
  width: 168px;
  overflow: hidden;
  /* because btn is table with border i can't overflow hidden*/
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.btn-rect.watch-modality .bg {
  position: relative;
  display: table-cell;
  top: 0px;
  left: 0px;
  height: 48px;
  width: 168px;
  vertical-align: middle;
  background: #ffffff; }

.btn-rect.watch-modality.btn-arrow-svg svg {
  position: absolute;
  left: 75px;
  top: 8px;
  width: 26px;
  height: auto;
  transform: rotateZ(-90deg); }

/* DEFAULT */
.btn-square-center,
.btn-rect {
  border: 3px solid #ffffff;
  color: #ffffff; }

.btn-arrow .bg {
  background: #ffffff; }

/* BLUE */
.btn-square-center.btn-blue,
.btn-rect.btn-blue {
  border: 3px solid #151a1f;
  color: #151a1f; }

.btn-arrow.btn-blue .bg {
  background: #151a1f; }

/* GRAY */
.btn-square-center.btn-gray,
.btn-rect.btn-gray {
  border: 3px solid #aeafb0;
  color: #aeafb0; }

.btn-arrow.btn-gray .bg {
  background: #aeafb0; }

/* DARK BROWN */
.btn-square-center.btn-dbrown,
.btn-rect.btn-dbrown {
  border: 3px solid #52433a;
  color: #52433a; }

/* LIGHT BROWN */
.btn-square-center.btn-lbrown,
.btn-rect.btn-lbrown {
  border: 3px solid #9d8679;
  color: #9d8679; }

/******* Close *******/
.btn-x {
  display: block;
  width: 64px;
  height: 64px;
  overflow: hidden;
  text-indent: -5555px;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.no-csstransforms3d .btn-x {
  text-indent: 0; }

.btn-x.med {
  width: 32px;
  height: 32px; }

.btn-x::before,
.btn-x::after {
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.15s ease-out;
  -moz-transition: -moz-transform 0.15s ease-out;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.btn-x::before {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -ms-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg); }

.btn-x::after {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg); }

.btn-x:hover::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg); }

.btn-x:hover::after {
  -webkit-transform: rotate3d(0, 0, 1, 135deg);
  -ms-transform: rotate3d(0, 0, 1, 135deg);
  -moz-transform: rotate3d(0, 0, 1, 135deg);
  transform: rotate3d(0, 0, 1, 135deg); }

.btn-x.btn-blue::before,
.btn-x.btn-blue::after {
  background: #151a1f; }

.btn-x.btn-dbrown::before,
.btn-x.btn-dbrown::after {
  background: #52433a; }

.btn-x.btn-lbrown::before,
.btn-x.btn-lbrown::after {
  background: #9d8679; }

/******* =BTN SQUARE with =ARROWS *******/
.btn-arrow .container {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 92px;
  width: 92px;
  overflow: hidden;
  /* because btn is table with border i can't overflow hidden*/
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.no-borderimage .btn-arrow .container {
  display: none; }

button.btn-arrow .container {
  top: -3px;
  left: -3px; }

/* down */
.btn-arrow .bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /*background: #151a1f;*/
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: translate3d(0, -100%, 0) skewY(-19deg);
  -ms-transform: translate3d(0, -100%, 0) skewY(-19deg);
  transform: translate3d(0, -100%, 0) skewY(-19deg);
  -webkit-animation: animBtnArrowBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnArrowBgOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0) skewY(0deg); }
  100% {
    -webkit-transform: translate3d(0, 150%, 0) skewY(-19deg); } }

@-ms-keyframes animBtnArrowBgOut {
  0% {
    -ms-transform: translate3d(0, 0, 0) skewY(0); }
  100% {
    -ms-transform: translate3d(0, 150%, 0) skewY(-19deg); } }

@keyframes animBtnArrowBgOut {
  0% {
    transform: translate3d(0, 0, 0) skewY(0deg); }
  100% {
    transform: translate3d(0, 150%, 0) skewY(-19deg); } }

.btn-arrow.over .bg {
  height: 102%;
  -webkit-animation: animBtnArrowBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -ms-animation: animBtnArrowBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  animation: animBtnArrowBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards; }

@-webkit-keyframes animBtnArrowBg {
  0% {
    -webkit-transform: translate3d(0, -100%, 0) skewY(-19deg); }
  100% {
    -webkit-transform: translate3d(0, -1px, 0) skewY(0deg); } }

@-ms-keyframes animBtnArrowBg {
  0% {
    -ms-transform: translate3d(0, -100%, 0) skewY(-19deg); }
  100% {
    -ms-transform: translate3d(0, -1px, 0) skewY(0deg); } }

@keyframes animBtnArrowBg {
  0% {
    transform: translate3d(0, -100%, 0) skewY(-19deg); }
  100% {
    transform: translate3d(0, -1px, 0) skewY(0deg); } }

/* right - skew exception */
.btn-arrow.rgt .bg {
  -webkit-transform: translate3d(0, -100%, 0) skewY(19deg);
  -ms-transform: translate3d(0, -100%, 0) skewY(19deg);
  transform: translate3d(0, -100%, 0) skewY(19deg);
  -webkit-animation: animBtnArrowBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowBgOutRgt 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

.mobile .btn-arrow.rgt .bg,
.mobile .btn-arrow.lft .bg {
  display: none; }

@-webkit-keyframes animBtnArrowBgOutRgt {
  0% {
    -webkit-transform: translate3d(0, 0, 0) skewY(0deg); }
  100% {
    -webkit-transform: translate3d(0, 150%, 0) skewY(19deg); } }

@-ms-keyframes animBtnArrowBgOutRgt {
  0% {
    -ms-transform: translate3d(0, 0, 0) skewY(0); }
  100% {
    -ms-transform: translate3d(0, 150%, 0) skewY(19deg); } }

@keyframes animBtnArrowBgOutRgt {
  0% {
    transform: translate3d(0, 0, 0) skewY(0deg); }
  100% {
    transform: translate3d(0, 150%, 0) skewY(19deg); } }

.btn-arrow.rgt.over .bg {
  -webkit-animation: animBtnArrowBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -ms-animation: animBtnArrowBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  animation: animBtnArrowBgRgt 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards; }

@-webkit-keyframes animBtnArrowBgRgt {
  0% {
    -webkit-transform: translate3d(0, -100%, 0) skewY(19deg); }
  100% {
    -webkit-transform: translate3d(0, 0, 0) skewY(0deg); } }

@-ms-keyframes animBtnArrowBgRgt {
  0% {
    -ms-transform: translate3d(0, -100%, 0) skewY(19deg); }
  100% {
    -ms-transform: translate3d(0, 0x, 0) skewY(0deg); } }

@keyframes animBtnArrowBgRgt {
  0% {
    transform: translate3d(0, -100%, 0) skewY(19deg); }
  100% {
    transform: translate3d(0, 0, 0) skewY(0deg); } }

.btn-arrow .arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: " ";
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnArrowArrowOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 100%, 0); } }

@-ms-keyframes animBtnArrowArrowOut {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(0, 100%, 0); } }

@keyframes animBtnArrowArrowOut {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

.btn-arrow.over .arrow {
  -webkit-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  -ms-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards; }

@-webkit-keyframes animBtnArrowArrow {
  0% {
    -webkit-transform: translate3d(0, -100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animBtnArrowArrow {
  0% {
    -ms-transform: translate3d(0, -100%, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animBtnArrowArrow {
  0% {
    transform: translate3d(0, -100%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.btn-arrow.down .container {
  -webkit-transform: translateZ(0) rotate(0deg);
  -ms-transform: translateZ(0) rotate(0deg);
  transform: translateZ(0) rotate(0deg); }

.btn-arrow.lft .container {
  -webkit-transform: translateZ(0) rotate(90deg);
  -ms-transform: translateZ(0) rotate(90deg);
  transform: translateZ(0) rotate(90deg); }

.btn-arrow.top .container {
  -webkit-transform: translateZ(0) rotate(180deg);
  -ms-transform: translateZ(0) rotate(180deg);
  transform: translateZ(0) rotate(180deg); }

.btn-arrow.rgt .container {
  -webkit-transform: translateZ(0) rotate(-90deg);
  -ms-transform: translateZ(0) rotate(-90deg);
  transform: translateZ(0) rotate(-90deg); }

/* =top right */
.btn-arrow.trgt .bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  /*background: #151a1f;*/
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: translate3d(-50%, 0, 0) skewY(45deg);
  -ms-transform: translate3d(-50%, 0, 0) skewY(45deg);
  transform: translate3d(-50%, 0, 0) skewY(45deg);
  -webkit-animation: animBtnArrowTrgBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowTrgBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowTrgBgOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnArrowTrgBgOut {
  0% {
    -webkit-transform: translate3d(-50%, -100%, 0) skewY(45deg); }
  100% {
    -webkit-transform: translate3d(-50%, -200%, 0) skewY(45deg); } }

@-ms-keyframes animBtnArrowTrgBgOut {
  0% {
    -ms-transform: translate3d(-50%, -100%, 0) skewY(45deg); }
  100% {
    -ms-transform: translate3d(-50%, -200%, 0) skewY(45deg); } }

@keyframes animBtnArrowTrgBgOut {
  0% {
    transform: translate3d(-50%, -100%, 0) skewY(45deg); }
  100% {
    transform: translate3d(-50%, -200%, 0) skewY(45deg); } }

.btn-arrow.trgt.over .bg {
  -webkit-animation: animBtnArrowTrgtBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -ms-animation: animBtnArrowTrgtBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  animation: animBtnArrowTrgtBg 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards; }

@-webkit-keyframes animBtnArrowTrgtBg {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0) skewY(45deg); }
  100% {
    -webkit-transform: translate3d(-50%, -100%, 0) skewY(45deg); } }

@-ms-keyframes animBtnArrowTrgtBg {
  0% {
    -ms-transform: translate3d(-50%, 0, 0) skewY(45deg); }
  100% {
    -ms-transform: translate3d(-50%, -100%, 0) skewY(45deg); } }

@keyframes animBtnArrowTrgtBg {
  0% {
    transform: translate3d(-50%, 0, 0) skewY(45deg); }
  100% {
    transform: translate3d(-50%, -100%, 0) skewY(45deg); } }

.btn-arrow.trgt .arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-100%, 100%, 0);
  -ms-transform: translate3d(-100%, 100%, 0);
  transform: translate3d(-100%, 100%, 0);
  -webkit-animation: animBtnArrowTrgtArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowTrgtArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowTrgtArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animBtnArrowTrgtArrowOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(100%, -100%, 0); } }

@-ms-keyframes animBtnArrowTrgtArrowOut {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(100%, -100%, 0); } }

@keyframes animBtnArrowTrgtArrowOut {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(100%, -100%, 0); } }

.btn-arrow.trgt.over .arrow {
  -webkit-animation: animBtnArrowTrgtArrow 0.25s ease-out 0.05s forwards;
  -ms-animation: animBtnArrowTrgtArrow 0.25s ease-out 0.05s forwards;
  animation: animBtnArrowTrgtArrow 0.25s ease-out 0.05s forwards; }

@-webkit-keyframes animBtnArrowTrgtArrow {
  0% {
    -webkit-transform: translate3d(-100%, 100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animBtnArrowTrgtArrow {
  0% {
    -ms-transform: translate3d(-100%, 100%, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animBtnArrowTrgtArrow {
  0% {
    transform: translate3d(-100%, 100%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

/* =arrows BTS color and directions */
/******* =arrows *******/
.btn-arrow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-lbrown.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-blue .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-bigblue.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-yellow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-bigyellow.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-purple .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-bigpurple.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-lbrown .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-lbrown.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-dbrown .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-dbrown.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.arrow-lblue .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-lblue.svg) no-repeat center center;
  background-size: 26px auto; }

/*Top Right*/
.btn-arrow.trgt .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.trgt.arrow-blue .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right-blue.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.trgt.arrow-yellow .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right-yellow.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.trgt.arrow-orange .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right-orange.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.trgt.arrow-lbrown .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right-lbrown.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.trgt.arrow-dbrown .arrow {
  background: transparent url(/public/imgs/icon-arrow-top-right-dbrown.svg) no-repeat center center;
  background-size: 26px auto; }

/* =arrows BTS width svg inline */
.btn-arrow.btn-arrow-svg .arrow {
  /*background: none;*/ }

.btn-arrow.btn-arrow-svg.rgt .arrow svg {
  /*  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);*/ }

.btn-arrow.btn-arrow-svg svg {
  position: absolute;
  left: 33px;
  top: 30px;
  width: 26px;
  height: auto; }

.leiria-horario {
  padding: 40px 0;
  text-align: center;
  background-color: #feba24; }
  .leiria-horario .btn-rect.watch-modality:hover {
    background-color: #fff;
    color: #feba24; }

.leiria-horario-btn {
  width: 200px;
  position: relative;
  z-index: 1; }

.btn-rect.leiria-horario-btn.watch-modality .button-text {
  margin-left: 0; }

.ie .btn-arrow.btn-arrow-svg svg {
  display: none; }

.mobile .btn-arrow.over .bg,
.mobile .btn-arrow.over .arrow,
.mobile .btn-arrow.trgt.over .bg,
.mobile .btn-arrow.trgt.over .arrow {
  -webkit-animation: none;
  -ms-animation: none;
  animation: none; }

.mobile .btn .container,
.mobile .btn-arrow .container {
  display: none; }

/******* =BTNS HALF ARROWS *******/
#arrow-halfdown-svg {
  width: 43px;
  height: 25px; }

#arrow-halfdown-svg path,
.arrow-half-small-svg {
  fill: #fff; }

#arrow-halfdown-svg text {
  visibility: hidden;
  width: 1px;
  height: 1px; }

.btn-arrow-half {
  display: block; }

.btn .btn-arrow-half.down {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.btn:hover .btn-arrow-half.down {
  -webkit-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0); }

.arrow-half-small-svg {
  width: 25px;
  height: 17px;
  margin-top: 5px;
  margin-left: -2px; }

.arrow-gyms {
  position: absolute;
  left: 50%;
  display: block;
  width: 130px;
  height: 78px;
  margin-top: 26px;
  overflow: hidden;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.mobile .arrow-gyms {
  display: none; }

.arrow-gyms svg {
  position: absolute;
  display: block;
  width: 16px;
  height: 26px;
  top: 39px;
  margin-top: -14px; }

.arrow-gyms.left svg {
  -webkit-animation: animArrowLeftGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -moz-animation: animArrowLeftGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animArrowLeftGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animArrowLeftGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animArrowLeftGymOut {
  0% {
    -webkit-transform: translate3d(57px, 0, 0); }
  100% {
    -webkit-transform: translate3d(-114px, 0, 0); } }

@-moz-keyframes animArrowLeftGymOut {
  0% {
    -moz-transform: translate3d(57px, 0, 0); }
  100% {
    -moz-transform: translate3d(-114px, 0, 0); } }

@-ms-keyframes animArrowLeftGymOut {
  0% {
    -ms-transform: translate3d(57px, 0, 0); }
  100% {
    -ms-transform: translate3d(-114px, 0, 0); } }

@keyframes animArrowLeftGymOut {
  0% {
    transform: translate3d(57px, 0, 0); }
  100% {
    transform: translate3d(-114px, 0, 0); } }

.arrow-gyms.left.anim-arrow svg {
  -webkit-animation: animArrowLeftGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -moz-animation: animArrowLeftGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -ms-animation: animArrowLeftGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  animation: animArrowLeftGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards; }

@-webkit-keyframes animArrowLeftGym {
  0% {
    -webkit-transform: translate3d(114px, 0, 0); }
  100% {
    -webkit-transform: translate3d(57px, 0, 0); } }

@-moz-keyframes animArrowLeftGym {
  0% {
    -moz-transform: translate3d(114px, 0, 0); }
  100% {
    -moz-transform: translate3d(57px, 0, 0); } }

@-ms-keyframes animArrowLeftGym {
  0% {
    -ms-transform: translate3d(114px, 0, 0); }
  100% {
    -ms-transform: translate3d(57px, 0, 0); } }

@keyframes animArrowLeftGym {
  0% {
    transform: translate3d(114px, 0, 0); }
  100% {
    transform: translate3d(57px, 0, 0); } }

.arrow-gyms.right svg {
  -webkit-animation: animArrowRightGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -moz-animation: animArrowRightGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animArrowRightGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animArrowRightGymOut 0.2s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

@-webkit-keyframes animArrowRightGymOut {
  0% {
    -webkit-transform: translate3d(57px, 0, 0); }
  100% {
    -webkit-transform: translate3d(171px, 0, 0); } }

@-moz-keyframes animArrowRightGymOut {
  0% {
    -moz-transform: translate3d(57px, 0, 0); }
  100% {
    -moz-transform: translate3d(171px, 0, 0); } }

@-ms-keyframes animArrowRightGymOut {
  0% {
    -ms-transform: translate3d(57px, 0, 0); }
  100% {
    -ms-transform: translate3d(171px, 0, 0); } }

@keyframes animArrowRightGymOut {
  0% {
    transform: translate3d(57px, 0, 0); }
  100% {
    transform: translate3d(171px, 0, 0); } }

.arrow-gyms.right.anim-arrow svg {
  -webkit-animation: animArrowRightGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -moz-animation: animArrowRightGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  -ms-animation: animArrowRightGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards;
  animation: animArrowRightGym 0.4s cubic-bezier(0.17, 0.86, 0.31, 1.05) forwards; }

@-webkit-keyframes animArrowRightGym {
  0% {
    -webkit-transform: translate3d(-171px, 0, 0); }
  100% {
    -webkit-transform: translate3d(57px, 0, 0); } }

@-moz-keyframes animArrowRightGym {
  0% {
    -moz-transform: translate3d(-171px, 0, 0); }
  100% {
    -moz-transform: translate3d(57px, 0, 0); } }

@-ms-keyframes animArrowRightGym {
  0% {
    -ms-transform: translate3d(-171px, 0, 0); }
  100% {
    -ms-transform: translate3d(57px, 0, 0); } }

@keyframes animArrowRightGym {
  0% {
    transform: translate3d(-171px, 0, 0); }
  100% {
    transform: translate3d(57px, 0, 0); } }

@media screen and (max-width: 1023px) {
  .arrow-gyms {
    display: none; } }

/************* =BTN SUBMIT / BTN SUBSCRIBE ***********/
.btn-arrow .subscribe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: " ";
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

.btn-arrow.over .subscribe {
  -webkit-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  -ms-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards; }

.btn-arrow .join {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: " ";
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  -ms-animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
  animation: animBtnArrowArrowOut 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }

.btn-arrow.over .join {
  -webkit-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  -ms-animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards;
  animation: animBtnArrowArrow 0.25s ease-out 0.05s forwards; }

.btn-arrow .join {
  background: transparent url(/public/imgs/icon-btn-join.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow .subscribe {
  background: transparent url(/public/imgs/icon-btn-subscribe.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.join-blue .join {
  background: transparent url(/public/imgs/icon-btn-join-blue.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.join-yellow .join {
  background: transparent url(/public/imgs/icon-btn-join-yellow.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.join-purple .join {
  background: transparent url(/public/imgs/icon-btn-join-purple.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.subscribe-blue .subscribe {
  background: transparent url(/public/imgs/icon-btn-subscribe-blue.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.subscribe-yellow .subscribe {
  background: transparent url(/public/imgs/icon-btn-subscribe-yellow.svg) no-repeat center center;
  background-size: 26px auto; }

.btn-arrow.subscribe-purple .subscribe {
  background: transparent url(/public/imgs/icon-btn-subscribe-purple.svg) no-repeat center center;
  background-size: 26px auto; }

/******* =BTN SUBMIT =FORMS *******/
.btn-submit-anim {
  border-radius: 0; }

.btn-submit-anim.loading {
  cursor: default; }

.btn-submit-anim.go {
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.btn-submit-anim.go-reset {
  border-color: white;
  -webkit-animation: animFormReset 0.1s ease-out forwards 0.3s;
  animation: animFormReset 0.1s ease-out forwards 0.3s; }

@-webkit-keyframes animFormReset {
  0% {
    border-radius: 50%; }
  100% {
    border-radius: 0; } }

@keyframes animFormReset {
  0% {
    border-radius: 50%; }
  100% {
    border-radius: 0; } }

/******* =BTN GYMS *******/
.gym-btn {
  position: absolute;
  bottom: 20px;
  left: 40px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  opacity: 1;
  pointer-events: visible;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  @media screen and (max-width: 413px) {
    .gym-btn {
      width: 40px;
      height: 40px; } }
  .gym-btn .bg-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 100%;
    z-index: 2;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center; }
    .gym-btn .bg-hover .btn-svg path {
      stroke: #52453c; }
  .gym-btn .btn-svg {
    position: relative;
    z-index: 1;
    width: 10px;
    height: 18px;
    top: 2px; }
    @media screen and (max-width: 413px) {
      .gym-btn .btn-svg {
        width: 8px;
        height: 16px; } }
    .gym-btn .btn-svg path {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: none;
      stroke: #ffffff;
      stroke-width: 3;
      stroke-miterlimit: 10; }
  .gym-btn.swiper-button-disabled {
    opacity: 0.2;
    pointer-events: none; }
  .gym-btn.next {
    left: auto;
    right: 40px; }
    .gym-btn.next .bg-hover {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      -webkit-animation: gymArrowAnimeOutRight 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
      animation: gymArrowAnimeOutRight 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }
      .gym-btn.next .bg-hover.over {
        -webkit-animation: gymArrowAnimeRight 0.25s ease-out 0.05s forwards;
        animation: gymArrowAnimeRight 0.25s ease-out 0.05s forwards; }

@-webkit-keyframes gymArrowAnimeOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(100%, 0, 0); } }

@keyframes gymArrowAnimeOutRight {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes gymArrowAnimeRight {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@keyframes gymArrowAnimeRight {
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }
  .gym-btn.prev .btn-svg {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    left: -2px; }
  .gym-btn.prev .bg-hover {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-animation: gymArrowAnimeOutLeft 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards;
    animation: gymArrowAnimeOutLeft 0.3s cubic-bezier(0.92, 0.05, 0.95, 0.52) forwards; }
    .gym-btn.prev .bg-hover.over {
      -webkit-animation: gymArrowAnimeLeft 0.25s ease-out 0.05s forwards;
      animation: gymArrowAnimeLeft 0.25s ease-out 0.05s forwards; }

@-webkit-keyframes gymArrowAnimeOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0); } }

@keyframes gymArrowAnimeOutLeft {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes gymArrowAnimeLeft {
  0% {
    -webkit-transform: translate3d(100%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@keyframes gymArrowAnimeLeft {
  0% {
    transform: translate3d(100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

/* =form txt enviar */
.btn-submit-anim.go .msg {
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out; }

.btn-submit-anim.go-reset .msg {
  opacity: 1;
  -webkit-transition: opacity 0.1s ease-out 0.3s;
  -ms-transition: opacity 0.1s ease-out 0.3s;
  transition: opacity 0.1s ease-out 0.3s; }

/* =form submit svgs section */
.svg-progress {
  opacity: 1;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 90px;
  height: 90px;
  -webkit-transform: scale(1.03);
  transform: scale(1.03); }

.go-ok .svg-progress {
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98);
  transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98); }

.svg-progress-path {
  opacity: 0;
  stroke-dasharray: 279;
  stroke-dashoffset: 839;
  stroke: #ffffff; }

.btn-submit-anim.loading.go .svg-progress-path {
  opacity: 0;
  stroke-dasharray: 279;
  stroke-dashoffset: 279;
  -webkit-animation: animSVGprogress 2.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) 0.3s forwards;
  animation: animSVGprogress 2.3s cubic-bezier(0.5, 0.02, 0.18, 0.98) 0.3s forwards; }

@-webkit-keyframes animSVGprogress {
  0% {
    stroke-dashoffset: 279;
    opacity: 1; }
  20% {
    stroke-dashoffset: 300;
    opacity: 1; }
  40% {
    stroke-dashoffset: 350; }
  65% {
    stroke-dashoffset: 490; }
  100% {
    stroke-dashoffset: 559;
    opacity: 1; } }

@keyframes animSVGprogress {
  0% {
    stroke-dashoffset: 279;
    opacity: 1; }
  20% {
    stroke-dashoffset: 300;
    opacity: 1; }
  40% {
    stroke-dashoffset: 350; }
  65% {
    stroke-dashoffset: 490; }
  100% {
    stroke-dashoffset: 559;
    opacity: 1; } }

.go-ok.go-reset .svg-progress,
.go-error.go-reset .svg-progress {
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98);
  transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98); }

/* =form send ok */
/*.svg-success{
    opacity: 0;
    position: absolute;
    left: 8px; top: 20px;
    width: 70px; height: 70px;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    -ms-transition: transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    transition: transform .2s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.go-ok .svg-success{
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}*/
.svg-success {
  opacity: 0;
  position: absolute;
  left: 14px;
  top: 23px;
  width: 60px;
  height: 60px;
  stroke-dasharray: 148;
  stroke-dashoffset: 170; }

.go-ok .svg-success {
  opacity: 1;
  -webkit-animation: animSVGformSentOK 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-animation: animSVGformSentOK 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  animation: animSVGformSentOK 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

@-webkit-keyframes animSVGformSentOK {
  0% {
    stroke-dashoffset: 170; }
  100% {
    stroke-dashoffset: 299; } }

@-ms-keyframes animSVGformSentOK {
  0% {
    stroke-dashoffset: 170; }
  100% {
    stroke-dashoffset: 299; } }

@keyframes animSVGformSentOK {
  0% {
    stroke-dashoffset: 170; }
  100% {
    stroke-dashoffset: 299; } }

.go-reset .svg-success {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

/*Forms styles for errors and validations feedback*/
form#form-home .input-border.erro {
  border-bottom: 3px solid #d51d37; }

form#form-home .input-border.erro:before,
#form-home .input-border.erro:after {
  background: #d51d37; }

form#form-home .input-border.erro {
  color: #d51d37; }

form.inscricoes-form input.erro,
form.inscricoes-form input.select.erro {
  border-left: 8px solid #d51d37; }

.svg-fail {
  opacity: 0;
  position: absolute;
  left: 14px;
  top: 12px;
  width: 60px;
  height: 60px; }

.go-error .svg-progress {
  opacity: 1;
  /*opacity: 0;*/
  -webkit-transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98);
  transition: opacity 0.15s cubic-bezier(0.5, 0.02, 0.18, 0.98); }

.go-error .svg-fail {
  opacity: 1;
  -webkit-transition: opacity 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  -ms-transition: opacity 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards;
  transition: opacity 0.2s cubic-bezier(0.5, 0.02, 0.18, 0.98) forwards; }

.go-reset .svg-fail {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.btn-submit-anim.loading.go.pause .svg-progress-path {
  -webkit-animation-play-state: paused;
  /* Chrome, Safari, Opera */
  animation-play-state: paused; }

/*-------------------------------------------------------------------------
=IMAGES and =ICONS
-------------------------------------------------------------------------*/
img {
  display: block;
  max-width: 100%;
  height: auto;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none; }

.icon-svg {
  display: inline-block;
  width: 100%;
  height: auto; }

.icon-svg-auto {
  display: inline-block;
  width: auto;
  height: auto; }

.id-main {
  position: relative;
  width: 256px;
  height: 56px;
  text-indent: -5555px;
  background: transparent url(/public/imgs/id/phive-id-big.svg) no-repeat 0 0;
  background-size: 256px 56px; }

.icon-horarios,
.icon-horarios-bw {
  display: block;
  width: 159px;
  height: 146px;
  background: transparent url(/public/imgs/icon-horario.svg) no-repeat center center;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

.icon-horarios.go {
  opacity: 1; }

.content-horarios .icon-horarios.icon-horarios-lightbrown,
.content-horarios .icon-horarios.icon-horarios-darkbrown,
.content-horarios .icon-horarios.icon-horarios-white,
.content-horarios .icon-horarios.icon-horarios-white-opac {
  left: 50%; }

.icon-horarios-lightbrown {
  background: transparent url(/public/imgs/icon-horario-lightbrown.svg) no-repeat center center; }

.icon-horarios-darkbrown {
  background: transparent url(/public/imgs/icon-horario-darkbrown.svg) no-repeat center center; }

.icon-horarios-white-opac {
  background: transparent url(/public/imgs/icon-horario-white-opac.svg) no-repeat center center; }

.icon-horarios-white {
  background: transparent url(/public/imgs/icon-horario-white.svg) no-repeat center center; }

.icon-horarios-wrapper {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: clip 300ms cubic-bezier(0.175, 0.885, 0.22, 1);
  -webkit-transition: clip 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition: clip 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition: clip 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition: clip 300ms cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.22, 1.125); }

.icon-horarios-bw {
  background: transparent url(/public/imgs/icon-horario-bw.svg) no-repeat center center; }

.icon-horarios-ld-brown {
  background: transparent url(/public/imgs/icon-horario-ld-brown.svg) no-repeat center center; }

.icon-arrow-r {
  display: inline-block;
  width: 6px;
  height: 9px;
  background: transparent url(/public/imgs/icon-arrow-btn.png) no-repeat;
  background-size: 6px auto; }

.icon-infinit,
.icon-watch,
.icon-nutri,
.icon-shirt,
.icon-modality,
.icon-pool,
.icon-spa,
.icon-beauty,
.icon-low-cost,
.icon-box-crossfit,
.icon-massagens {
  display: block;
  width: 102px;
  height: 102px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 102px auto; }

.icon-infinit {
  background-image: url(/public/imgs/icon-ilimitado.svg); }

.icon-watch {
  background-image: url(/public/imgs/icon-watch-big.svg); }

.icon-nutri {
  background-image: url(/public/imgs/icon-nutricao.svg); }

.icon-shirt {
  background-image: url(/public/imgs/icon-tshirt.svg); }

.icon-modality {
  background-image: url(/public/imgs/icon-modality.svg); }

.icon-pool {
  background-image: url(/public/imgs/icon-pool.svg);
  background-size: 140px auto; }

/** NEW ICONS **/
.icon-spa {
  background-image: url(/public/imgs/icon-spa.svg);
  background-size: 80px auto; }

.icon-beauty {
  background-image: url(/public/imgs/icon-beleza.svg);
  background-size: 80px auto; }

.icon-box-crossfit {
  background-image: url(/public/imgs/icon-box-crossfit.svg);
  background-size: 80px auto; }

.icon-box-crossfit.big {
  background-size: 105px auto; }

.icon-low-cost {
  background-image: url(/public/imgs/icon-low-cost.svg);
  background-size: 80px auto; }

.icon-massagens {
  background-image: url(/public/imgs/icon-massagens.svg);
  background-size: 80px auto; }

.icon-massagens.big {
  background-size: 105px auto; }

.icon-number {
  display: block;
  font-size: 50px;
  line-height: 102px;
  width: 102px;
  height: 102px; }

.icon-piscina {
  display: block;
  width: 128px;
  height: 102px;
  background-image: url(/public/imgs/icon-piscina.svg);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 128px auto; }

/* =SOCIAL =SOCIALCOUNT */
.social {
  overflow: hidden; }

.social li {
  position: relative;
  display: inline-block; }

.social .icon-svg {
  width: auto;
  height: 22px;
  fill: #fff; }

.social.socialcount .icon-svg {
  width: 17px;
  height: 28px; }

.social.socialcount .twitter .icon-svg {
  width: 27px;
  height: 23px;
  margin: 2px 0 3px 0; }

.social.socialcount em {
  display: block;
  position: relative; }

.socialcount > li {
  background-color: transparent; }

.socialcount > li > a {
  padding: 0;
  position: relative; }

.socialcount > li > a:focus,
.socialcount > li:hover,
.socialcount > li.hover {
  background-color: transparent; }

.socialcount .button {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: auto;
  bottom: 8px;
  text-align: center;
  pointer-events: none; }

.socialcount .count {
  color: inherit;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 10px;
  display: inline-block;
  zoom: 1;
  text-align: center;
  margin-left: 0;
  width: 100%;
  height: 10px; }

.socialcount .count {
  color: inherit;
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  display: inline;
  zoom: 1;
  text-align: center;
  margin-left: 0; }

.socialcount > li:hover em,
.socialcount > li.hover em {
  opacity: 0; }

.socialcount .loading {
  top: -2px;
  background-color: transparent; }

/* =FORMS =OK =X */
.ico-x {
  background: transparent url(/public/imgs/icon-x.svg) no-repeat center center; }

.ico-ok {
  background: transparent url(/public/imgs/icon-ok.svg) no-repeat center center; }

/* ICONS LIST */
.grid-icons {
  text-align: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start; }

.grid-icons li {
  width: 25%;
  height: 140px; }

.grid-icons .icon {
  position: relative;
  display: block;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.no-flexbox .grid-icons .icon {
  float: left; }

.grid-icons .icon span {
  margin: 0 auto;
  padding-top: 5px;
  display: block;
  min-height: 50px;
  display: block;
  font: normal 26px/26px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.grid-icons .icon .icon-number {
  display: block;
  font-size: 50px;
  line-height: 102px;
  width: 102px;
  height: 102px;
  font: normal 75px/102px "s-Light", Helvetica, Arial, sans-serif; }

.grid-icons .icon .icon-text {
  width: auto;
  height: 102px;
  font: normal 42px/102px "s-Light", Helvetica, Arial, sans-serif; }

@media screen and (max-width: 1160px) {
  .grid-icons .icon .icon-text {
    width: 54%;
    font-size: 36px;
    line-height: 32px; }
  .grid-icons .icon .icon-text svg {
    width: 130px; } }

@media screen and (max-width: 1024px) {
  .grid-icons .icon .icon-text {
    width: auto;
    font-size: 42px;
    line-height: 102px; }
  .grid-icons .icon .icon-text svg {
    width: 146px; } }

@media screen and (max-width: 568px) {
  .grid-icons .icon .icon-text {
    width: 54%;
    font-size: 36px;
    line-height: 32px; }
  .grid-icons .icon .icon-text svg {
    width: 130px; } }

@media screen and (max-width: 414px) {
  .grid-icons .icon .icon-text {
    width: 65%;
    font-size: 34px;
    line-height: 30px; }
  .grid-icons .icon .icon-text svg {
    width: 120px; } }

@media screen and (max-width: 320px) {
  .grid-icons .icon .icon-text {
    width: 100%;
    font-size: 32px;
    line-height: 30px; }
  .grid-icons .icon .icon-text svg {
    width: 110px; } }

.grid-icons-full {
  height: 100%; }

.grid-icons-full .icon-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

/* ICONS CIRCLE */
.grid-icons-circle {
  position: relative;
  width: 100%;
  font: normal 48px/48px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center; }

.grid-icons-circle .text {
  display: inline-block;
  margin: 40px 0 20px 0; }

.grid-icons-circle .icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 178px;
  height: 178px;
  margin: 0 auto;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.grid-icons-circle .icon .svg-circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 178px;
  height: 178px; }

.grid-icons-circle .icon .svg-anim {
  fill: none;
  stroke: white;
  stroke-width: 2; }

.grid-icons-circle .home-icon path {
  fill: none;
  stroke: white;
  stroke-width: 1; }

.grid-icons-circle .icon-modal36 {
  width: 100px; }

.grid-icons-circle .icon-crossfit {
  width: 75px; }

.grid-icons-circle .icon-massag {
  width: 95px; }

.grid-icons-circle .icon-modal24 {
  width: 100px; }

.grid-icons-circle .icon-pool-home {
  width: 130px; }

.grid-icons-circle .icon-spa-home {
  width: 100px; }

/*-------------------------------------------------------------------------
=COLORS
-------------------------------------------------------------------------*/
.bg-blue {
  background-color: #151a1f;
  color: #ffffff; }

.bg-blue .txt-second {
  color: #a1a3a5; }

.bg-lbrown {
  background-color: #b9afa9;
  color: #242a30; }

.bg-lbrown .txt-second {
  color: #605f60; }

.bg-yellow {
  background-color: #f5b50f;
  color: #242a30; }

.bg-yellow .txt-second {
  color: #715e25; }

.bg-purple {
  background-color: #9d819b;
  color: #ffffff; }

.bg-purple .txt-second {
  color: #d1c7d1; }

.bg-dyellow {
  background-color: #ffa200;
  color: #ffffff; }

.bg-gyellow {
  background-color: #f0db17;
  color: #ffffff; }

.bg-lyellow {
  background-color: #ffd100;
  color: #ffffff; }

.bg-lgrey {
  background-color: #ccccc9; }

.bg-grey {
  background-color: #707070; }

/*Changed lbrown and added dbrown*/
.bg-lbrown {
  background-color: #9d8677;
  color: #ffffff; }

.bg-lbrown-lighter {
  background-color: #b49d90;
  color: #ffffff; }

.bg-dgrey {
  background-color: #343438;
  color: #ffffff; }

.bg-dgrey-darker {
  background-color: #606065;
  color: #ffffff; }

.bg-dbrown {
  background-color: #5f4d42;
  color: #ffffff; }

.bg-dbrown-darker {
  background-color: #52433a;
  color: #ffffff; }

.bg-lbrown .btn-rect {
  border-color: #52433a;
  color: #52433a; }

.bg-lbrown .btn-rect:hover,
.bg-lbrown .btn-rect.on {
  background: #52433a;
  color: #ffffff; }

.bg-dbrown .btn-rect {
  border-color: #ffffff;
  color: #ffffff; }

.bg-dbrown .btn-rect:hover,
.bg-dbrown .btn-rect.on {
  background: #ffffff;
  color: #52433a; }

/*-------------------------------------------------------------------------
=MODALS
-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------
=ANIMATIONS
-------------------------------------------------------------------------*/
.anim-wrap-DownUp {
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

.underline-anim {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) no-repeat;
  background-size: 100% 1px;
  background-position: -100px 18px;
  -webkit-transition: background-position 0.3s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  -ms-transition: background-position 0.3s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  transition: background-position 0.3s cubic-bezier(0.17, 0.86, 0.31, 1.05); }

.underline-anim:not(.hover-out):hover {
  background: linear-gradient(white, white) no-repeat;
  background-position: 0 18px; }

.underline-anim.hover-out {
  -webkit-transition-timing-function: cubic-bezier(0.92, 0.05, 0.95, 0.52);
  transition-timing-function: cubic-bezier(0.92, 0.05, 0.95, 0.52);
  background: linear-gradient(white, white) no-repeat;
  background-position: 100px 18px; }

.underline-anim.h15 {
  background-position: -109px 15px; }

.underline-anim.h15:not(.hover-out):hover {
  background-position: 0 15px; }

.underline-anim.h15.hover-out {
  background-position: 109px 15px; }

/* TEXT UP AND DOWN */
.anim-txt-ud {
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0.5) translateY(150%) translateZ(0);
  transform: scaleY(0.5) translateY(150%) translateZ(0); }

.up .anim-txt-ud,
.anim-txt-ud.up {
  transform-origin: left top;
  -webkit-animation: animTxtUDup 0.35s cubic-bezier(0.17, 0.89, 0.32, 1.12) 0s forwards;
  animation: animTxtUDup 0.35s cubic-bezier(0.17, 0.89, 0.32, 1.12) 0s forwards; }

@-webkit-keyframes animTxtUDup {
  0% {
    -webkit-transform: scaleY(0.5) translateY(0) translateZ(0); }
  100% {
    -webkit-transform: scaleY(1) translateY(0) translateZ(0); } }

@keyframes animTxtUDup {
  0% {
    transform: scaleY(0.5) translateY(150%) translateZ(0); }
  100% {
    transform: scaleY(1) translateY(0) translateZ(0); } }

.down .anim-txt-ud,
.anim-txt-ud.down {
  transform-origin: left bottom;
  -webkit-animation: animTxtUDdown 0.35s cubic-bezier(0.17, 0.89, 0.32, 1.12) 0s forwards;
  animation: animTxtUDdown 0.35s cubic-bezier(0.17, 0.89, 0.32, 1.12) 0s forwards; }

@-webkit-keyframes animTxtUDdown {
  0% {
    -webkit-transform: scaleY(0.5) translateY(-100%) translateZ(0); }
  100% {
    -webkit-transform: scaleY(1) translateY(0) translateZ(0); } }

@keyframes animTxtUDdown {
  0% {
    transform: scaleY(0.5) translateY(-150%) translateZ(0); }
  100% {
    transform: scaleY(1) translateY(0) translateZ(0); } }

/*.block-hover-shadow {
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
  box-shadow: none;
  -webkit-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.block-hover-shadow:hover {
  -webkit-transform: translate3d(0,-5px,0);
  -ms-transform: translate3d(0,-5px,0);
  transform: translate3d(0,-5px,0);
  box-shadow: 0 20px 25px 0 rgba(0,0,0,.15);
}*/
.block-hover-shadow {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.32, 0.82, 0.4, 1);
  transition: transform 0.45s cubic-bezier(0.32, 0.82, 0.4, 1); }

.block-hover-shadow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.45s cubic-bezier(0.32, 0.82, 0.4, 1);
  transition: opacity 0.45s cubic-bezier(0.32, 0.82, 0.4, 1); }

.block-hover-shadow:hover {
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0); }

.block-hover-shadow:hover::after {
  opacity: 1; }

/*-------------------------------------------------------------------------
  =RESET STYLES and GENERAL CLASSES
  NOTES: Can't use translate 3d with object-fit cover + js backup when not suported
-------------------------------------------------------------------------*/
/** =GENERAL CLASSES **/
.relative {
  position: relative; }

.fixed {
  position: fixed;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0); }

.respons-hook {
  visibility: hidden; }

/* if is just for design use also aria-hidden="true" */
.responsive-info {
  display: none; }

.element-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden; }

.z8 {
  z-index: 10; }

.z10 {
  z-index: 10; }

.z11 {
  z-index: 10; }

.z12 {
  z-index: 10; }

.z13 {
  z-index: 10; }

.z14 {
  z-index: 10; }

.z15 {
  z-index: 10; }

.z16 {
  z-index: 10; }

.z17 {
  z-index: 10; }

.z18 {
  z-index: 10; }

.z20 {
  z-index: 20; }

.z25 {
  z-index: 25; }

.z50 {
  z-index: 50; }

.z100 {
  z-index: 100; }

/**  =OPTIMIZATION =HARDWARE =ACCELERATION ***/
.change-trans {
  will-change: transform; }

.change-transopac {
  will-change: transform, opacity; }

.change-no {
  will-change: auto; }

.h3d {
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/** LINKS , COLORS, SELECTIONS, IMGS **/
a {
  text-decoration: none;
  color: #ffffff; }

a:hover,
a:focus {
  text-decoration: none; }

a[href^="tel:"] {
  cursor: default; }

::-moz-selection {
  background: #252322;
  color: #ffffff; }

::selection {
  background: #252322;
  color: #ffffff; }

/** =FORMS **/
input:focus,
textarea:focus,
button:focus {
  outline: none; }

:invalid,
:-moz-ui-invalid {
  box-shadow: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

button {
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer; }

.issuuembed {
  width: 100% !important;
  height: 500px !important; }

/*! --------------------------------------------------------------------
  BUROCRATIK's PARALLAX (jQuery plugin)
* @Version:    1.0.0 - 2015
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com
*
* Originally idea by Drew Wilson, "atvIMG" :: https://github.com/drewwilson/atvImg

-----------------------------------------------------------------------*/
.parallax-b {
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* CAN NOT HAVE BACKGROUND COLOR*/ }

.parallax-b-container {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  will-change: transition; }

.parallax-b-shadow {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  /*  -webkit-box-shadow: 0 8px 30px rgba(14,21,47,0);
          box-shadow: 0 8px 30px rgba(14,21,47,0);*/ }

.parallax-b-container.over .parallax-b-shadow {
  -webkit-box-shadow: 0 45px 100px #0e152f, 0 16px 40px rgba(14, 21, 47, 0.4);
  box-shadow: 0 45px 100px #0e152f, 0 16px 40px rgba(14, 21, 47, 0.4); }

.parallax-b-layers {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.parallax-b-layer {
  position: absolute;
  width: 104%;
  height: 104%;
  top: -2%;
  left: -2%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.parallax-b-shine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: linear-gradient(135deg, rgba(255,255,255,.25) 0%,rgba(255,255,255,0) 60%);*/ }

/* SLICK DEFAULT */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

/* Awwwards */
#awwwards {
  position: fixed;
  z-index: 999;
  transform: translateY(-50%);
  top: 50%;
  right: 0; }

/* Phone Area code overwrites */
#form-parceiro .mce-CONTACT-pop {
  left: -35px; }

#form-visita .mce-CONTACT-gym {
  left: -35px; }

.iti--show-selected-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0); }

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(255, 255, 255, 0.1); }

.iti--show-selected-dial-code.iti--show-flags .iti__selected-dial-code {
  margin-left: 6px;
  font: 18px/50px "p-Medium", Helvetica, Arial, sans-serif;
  color: #ffffff; }

.iti--inline-dropdown .iti__dropdown-content {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0); }

.iti__arrow {
  border-top: 4px solid #ffffff; }

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #ffffff; }

.iti__selected-flag > div:first-child {
  display: none; }

/*-----------------------------------------------------------------------
PAGES

* @Version:    1.0 - 2014
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
/*-------------------------------------------------------------------------
    =MAIN NAVIGATIONS
-------------------------------------------------------------------------*/
.has-arrows #nav-main-control .bg {
  width: calc(100% - 460px); }

.ie .has-arrows #nav-main-control .bg {
  width: 100%; }

.has-arrows #nav-main-control .nav-modality {
  display: block; }

/*-------------------------------------------------------------------------
    =RGPD
-------------------------------------------------------------------------*/
.rgpd-wrapper {
  clear: both;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff; }

.rgpd-wrapper .rgpd-text {
  position: relative;
  top: 1px;
  margin-left: 10px;
  color: #ffffff; }

.rgpd-wrapper .rgpd-text a {
  font-family: "p-Medium", Helvetica, Arial, sans-serif; }

/*-------------------------------------------------------------------------
    =HOME
-------------------------------------------------------------------------*/
.js-no-ajax.home .animate-sprite {
  opacity: 0; }

/* =header main */
/*.home #header-main{
  -webkit-transform: translate3d(0, -350px, 0);
  -moz-transform: translate3d(0, -350px, 0);
  transform: translate3d(0, -350px, 0);
  -webkit-transition: -webkit-transform .3s ease;
  -ms-transition: -ms-transform .3s ease;
  transition: transform .3s ease;
}
.home #header-main.show{
  -webkit-transform: translate3d(0, -258px, 0);
  -moz-transform: translate3d(0, -258px, 0);
  transform: translate3d(0, -258px, 0);
}
.mobile .home #header-main.show {
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}*/
/******* =HEADER VIDEO *******/
#home-start {
  position: relative;
  height: 200vh;
  z-index: 10; }

.home-start-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: hidden; }

#home-start .block-bg-cover {
  top: -15px;
  left: -15px;
  width: 105%;
  height: 105%; }

.no-object-fit #home-start .bg-video {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background: transparent url(/public/video/flow-video.jpg) no-repeat center center;
  background-size: cover; }

#home-start .bg-p {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

#home-start .home-p {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  max-width: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 45%;
  transform-origin: 50% 45%; }

#home-start .home-p .p {
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

#home-start .fadeInsideP .home-p .p {
  opacity: 0; }

#home-start .home-p {
  -webkit-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out; }

#home-start .go-p .home-p {
  -webkit-transform: scale(12, 12) !important;
  transform: scale(12, 12) !important; }

#home-start .sentences {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

#home-start .sentence {
  position: absolute;
  width: 100%;
  top: 43%;
  font: normal 400 84px/84px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  transform: translate3d(0, -50%, 0);
  opacity: 0;
  overflow: hidden; }

#home-start .sentence span {
  display: inline-block;
  transform: translate3d(0, 100%, 0); }

#home-start .call-to-action-leiria {
  position: absolute;
  top: calc(43% + 60px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  height: 60px;
  width: 190px;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out; }

#home-start .call-to-action-leiria .container {
  height: 190px;
  width: 60px;
  left: 62px;
  top: -68px; }

#home-start .call-to-action-leiria.in {
  opacity: 1;
  visibility: visible; }

#home-start .txt-help {
  position: absolute;
  z-index: 20;
  left: 0;
  bottom: 50px;
  width: 100%;
  font: normal 400 20px/20px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff; }

#home-start .line {
  position: absolute;
  z-index: 20;
  display: block;
  top: auto;
  bottom: 100px;
  left: 50%;
  width: 2px;
  height: 200px;
  margin-left: -1px;
  background: white;
  background: -webkit-linear-gradient(top, white 50%, rgba(255, 255, 255, 0) 50%);
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 100% 200%;
  background-position: 0 -200px; }

#home-start .line.startScroll {
  animation: animHomeHeaderLine 3s ease forwards infinite; }

@keyframes animHomeHeaderLine {
  0% {
    background-position: 0 -200px; }
  50% {
    background-position: 0 0px; }
  100% {
    background-position: 0 202px; } }

@keyframes animHomeHeaderLineMobile {
  0% {
    background-position: 0 -100px; }
  50% {
    background-position: 0 0px; }
  100% {
    background-position: 0 102px; } }

@keyframes animHomeHeaderLineMobileMinimum {
  0% {
    background-position: 0 -50px; }
  50% {
    background-position: 0 0px; }
  100% {
    background-position: 0 52px; } }

#home-start .header-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: none;
  min-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-60%, -50%, 0);
  -ms-transform: translate3d(-60%, -50%, 0);
  transform: translate3d(-60%, -50%, 0); }

/*.orientation_portrait #home-start .bg{
    background: transparent url(/public/files/home/start-portrait.svg) no-repeat center center;
    background-size: cover;
}
*/
.mobile #home-start .sentences {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column; }
  .mobile #home-start .sentences .sentence {
    position: relative;
    top: auto;
    -webkit-transform: none;
    transform: none; }

.mobile #home-start .call-to-action-leiria,
.mobile #home-start .btn {
  position: relative;
  top: auto;
  -webkit-transform: none;
  transform: none;
  left: auto;
  margin-top: 20px; }

@media screen and (max-width: 1024px) {
  #home-start .bg-p {
    display: none; }
  #home-start {
    height: 100vh; }
  #home-start .sentence {
    top: 49%; } }

@media screen and (max-width: 768px) {
  #home-start .sentence {
    padding: 0 180px; }
  .mobile #home-start .sentence {
    padding: 0; } }

@media screen and (max-width: 414px) {
  .home header h1 {
    top: 25px;
    width: 115px;
    height: 26px;
    background-size: auto 26px; }
  #home-start .sentence {
    font-size: 64px;
    line-height: 64px;
    padding: 0 20px; }
  #home-start .line {
    height: 100px;
    background-position: 0 -100px; }
  #home-start .line.startScroll {
    animation-name: animHomeHeaderLineMobile; }
  .safari #home-start .sentence {
    top: 45%; }
  .safari #home-start .line {
    bottom: 80px; }
  .safari #home-start .txt-help {
    bottom: 40px; }
  #home-start .header-cover {
    -webkit-transform: translate3d(-65%, -45%, 0);
    -ms-transform: translate3d(-65%, -45%, 0);
    transform: translate3d(-65%, -45%, 0); } }

@media screen and (max-width: 374px) {
  #home-start .line {
    display: none; } }

@media screen and (max-height: 750px) and (min-width: 1024px) {
  #home-start .line.startScroll {
    animation-name: animHomeHeaderLineMobile; }
  #home-start .line {
    height: 100px;
    background-position: 0 -100px; } }

/******* =content-fotos *******/
/******* =MODALIDADES *******/
.modalidades .cities-picker {
  position: fixed;
  z-index: 110;
  top: 50%;
  left: -2%;
  font: normal 400 24px/24px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
  -webkit-transform: translate3d(0, -50%, 0) rotate(-90deg);
  transform: translate3d(0, -50%, 0) rotate(-90deg); }
  .modalidades .cities-picker ul {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0); }
  .modalidades .cities-picker li {
    cursor: pointer;
    margin-right: 10px;
    opacity: 0.4; }
    .modalidades .cities-picker li.underline-anim.hover-out {
      background-position: 100px 23px; }
    .modalidades .cities-picker li.underline-anim:not(.hover-out):hover {
      background-position: 0 23px; }
    .modalidades .cities-picker li.active {
      opacity: 1;
      pointer-events: none; }
      .modalidades .cities-picker li.active.underline-anim {
        background: linear-gradient(white, white) no-repeat;
        background-position: 0 23px; }

.mobile .modalidades .cities-picker {
  position: absolute;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  left: 50%;
  top: 80vh; }
  @media screen and (max-width: 767px) and (orientation: portrait) {
    .mobile .modalidades .cities-picker {
      top: 62vh; } }

.content-modal-home {
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 180px 0;
  background: #252322;
  overflow: hidden; }

.content-modal-home h2 {
  position: absolute;
  z-index: 1;
  left: -34px;
  top: -96px;
  width: 100%;
  height: 100%;
  font: normal 220px/220px "s-Medium", Helvetica, Arial, sans-serif;
  letter-spacing: -10px;
  text-transform: uppercase;
  color: #2a2827; }

.content-modal-home h2 span {
  position: absolute;
  left: 0;
  top: 0; }

.content-modal-home h2 .br {
  position: absolute;
  left: auto;
  top: auto;
  bottom: -185px;
  right: -63px; }

.content-modal-home .btn-all {
  position: relative;
  z-index: 10;
  margin: 0 auto 60px auto;
  text-align: center; }

.list-modal-home {
  position: relative;
  z-index: 20;
  max-width: 100%;
  min-height: 550px;
  margin: 0 40px 0 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center; }

.modality-home {
  position: relative;
  width: 400px;
  min-height: 550px;
  overflow: hidden;
  /*padding: 0 60px 0 60px;*/
  /*  background-color: #302d2b;*/
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /*transition: width .3s ease-out;*/
  /*  transition: all .3s ease-out;*/ }

.list-modal-home .modality-home {
  background-color: #252322; }

.modality-home.over {
  /*width: 396px;*/
  /*width: 516px;*/
  /*transform: scale3d(1.29,1.29,1);*/ }

/*.modality-home .circle{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 280px;
  height: 280px;
  margin: -70px auto 0 auto;
  border-radius: 50% 50%;
  background-color: #302d2b;
  -webkit-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transform: translate3d(-50%, -50%, 0);
  transition: all .3s cubic-bezier(.35,.55,.44,.97);
}*/
/*.no-cssclippathpolygon .modality-home .circle { overflow: hidden;}*/
.modality-home .circle:hover {
  /*   width: 396px;
   height: 396px;*/
  /*  -webkit-animation: aniModalityHome .35s cubic-bezier(.35,.55,.44,.97) forwards;
          animation: aniModalityHome .4s cubic-bezier(.35,.55,.44,.97) forwards;*/ }

/*@-webkit-keyframes animNavModalAllHover{
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.2); }
    100% { -webkit-transform: scale(1); }
}*/
/*@keyframes aniModalityHome {
    0% { width: 280px; height: 280px; }
    20% { width: 250px; height: 250px;  }
    100% { width: 396px; height: 396px; }
}*/
.modality-home .visual {
  z-index: 25;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 860px;
  height: auto;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.5, 0.5, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.5, 0.5, 1);
  -webkit-transition: -webkit-transform 0.21s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.21s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.modality-home.over .visual {
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.65, 0.65, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.65, 0.65, 1); }

.modality-home.fadeout .visual {
  opacity: 0;
  transition: opacity 0.1s linear; }

/*.slick-active .modality-home .visual {
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(.65,.65,1);
          transform: translate3d(-50%, -50%, 0) scale3d(.65,.65,1);
}

.slick-active .modality-home.bodycombat .circle-bg {
    background-color: #535b4f;
}

.slick-active .modality-home::before, .modality-home::after {
    transform: scale3d(1.29,1.29,1);
}

.slick-active .content-modal-home h3 span::before { display: none; }*/
.modality-home::before {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 100%;
  content: "";
  background: transparent url(/public/imgs/home/home-modalidades-mask-top.svg) no-repeat;
  background-size: cover;
  transform-origin: 50% 200px; }

.modality-home::after {
  z-index: 50;
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 100%;
  content: "";
  background: transparent url(/public/imgs/home/home-modalidades-mask-btm.svg) no-repeat;
  background-size: cover;
  transform-origin: 50% 200px; }

.modality-home::before,
.modality-home::after {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.modality-home.over::before,
.modality-home.over::after {
  transform: scale3d(1.29, 1.29, 1); }

.modality-home .circle-bg {
  position: absolute;
  z-index: 5;
  left: 10px;
  top: 20px;
  display: block;
  width: 375px;
  height: 400px;
  background-color: #302d2b;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: background-color 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: background-color 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.modality-home .circle-btn {
  position: absolute;
  z-index: 60;
  left: 51px;
  top: 55px;
  display: block;
  width: 300px;
  height: 300px;
  opacity: 0;
  cursor: pointer; }

.modality-home.step::before,
.modality-home.powerjump::before,
.modality-home.crossphive::before,
.modality-home.cxworx::before,
.modality-home.trx::before,
.modality-home.flyoga::before,
.modality-home.phivekangoo::before,
.modality-home.bodyattack::before,
.modality-home.bodyjam::before,
.modality-home.hidroginastica::before,
.modality-home.borntomove::before,
.modality-home.phitkids::before {
  z-index: 50; }

.modality-home.step::after,
.modality-home.powerjump::after,
.modality-home.cxworx::after,
.modality-home.pilates::after,
.modality-home.yoga::after,
.modality-home.flyoga::after,
.modality-home.phivekangoo::after,
.modality-home.bodyjam::after,
.modality-home.swimcoach::after,
.modality-home.borntomove::after {
  z-index: 10; }

@media screen and (max-width: 2000px) {
  .list-modal-home .modality-home:nth-child(5) {
    display: none; } }

@media screen and (max-width: 1620px) {
  .list-modal-home .modality-home:nth-child(4) {
    display: none; } }

@media screen and (max-width: 1230px) {
  /*.modality-home::before,
  .modality-home::after,
  .modality-home .circle-bg,
  .modality-home .circle-btn {
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .modality-home.over::before,
  .modality-home.over::after {
    -webkit-transform: scale3d(1.29,1.29,1) translateX(-40%);
        -ms-transform: scale3d(1.29,1.29,1) translateX(-40%);
            transform: scale3d(1.29,1.29,1) translateX(-40%);
  }*/
  .list-modal-home .modality-home:nth-child(3) {
    display: none; } }

@media screen and (max-width: 870px) {
  .list-modal-home .modality-home:nth-child(2) {
    display: none; } }

@media screen and (max-width: 768px) and (orientation: landscape) {
  .mobile .home header h1 {
    top: 25px;
    width: 115px;
    height: 26px;
    background-size: auto 26px; }
  .mobile #home-start .sentence {
    font-size: 50px;
    line-height: 54px; }
  .safari.mobile #home-start .sentence {
    top: 38%; }
  .mobile #home-start .line {
    height: 50px;
    background-position: 0 -50px; }
  .mobile #home-start .line.startScroll {
    animation-name: animHomeHeaderLineMobileMinimum; } }

@media screen and (max-width: 414px) {
  .content-modal-home {
    padding: 80px 0; }
  .content-modal-home .btn-all {
    margin-bottom: 20px; }
  .list-modal-home {
    margin: 0; }
  .slick-initialized .slick-slide {
    padding: 0 40px; } }

@media screen and (max-width: 375px) {
  .modality-home::before,
  .modality-home::after,
  .mobile .modality-home .circle-bg {
    left: -12px; } }

@media screen and (max-width: 360px) {
  .modality-home::before {
    background: transparent url(/public/imgs/home/home-modalidades-mask-top-mobile.svg) no-repeat; }
  .modality-home::after {
    background: transparent url(/public/imgs/home/home-modalidades-mask-btm-mobile.svg) no-repeat; }
  .content-modal-home .btn-all {
    margin-bottom: 0; }
  .modality-home .visual {
    width: 680px; }
  .modality-home::before,
  .modality-home::after,
  .mobile .modality-home .circle-bg {
    left: -20px; } }

@media screen and (max-width: 320px) {
  .modality-home::before,
  .modality-home::after,
  .mobile .modality-home .circle-bg {
    left: -40px; } }

.content-modal-home h3 {
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100%;
  font: normal 400 32px/32px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff; }

.content-modal-home h3 span {
  display: inline;
  position: relative; }

.content-modal-home h3 span::before {
  position: absolute;
  top: -103px;
  left: 49%;
  display: block;
  width: 2px;
  height: 64px;
  content: "";
  background: white;
  background: -webkit-linear-gradient(top, white 50%, rgba(255, 255, 255, 0) 50%);
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 100% 200%;
  background-position: 0 0;
  -webkit-transition: background-position 0.2s cubic-bezier(0.17, 0.89, 0.2, 0.99);
  transition: background-position 0.2s cubic-bezier(0.17, 0.89, 0.2, 0.99); }

.modality-home.over h3 span::before {
  background-position: 0 40px; }

/******* =GYM =GINASIOS HOME *******/
.content-gym-home {
  position: relative;
  z-index: 100;
  width: 100%;
  height: auto;
  min-height: 100vh;
  color: #ffffff;
  overflow: hidden;
  font: normal 28px/40px "p-Light", Helvetica, Arial, sans-serif;
  text-align: center; }

.no-cssvhunit .content-gym-home {
  min-height: 700px; }

.content-gym-home .visual {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 50%;
  height: 100vh; }

.content-gym-home .visual.fixed {
  position: fixed;
  top: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0); }

.content-gym-home h3 {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.content-gym-home .visual .element-cover {
  transition: all 1s linear; }

/*.content-gym-home .visual .block-bg-cover{
  visibility: hidden;
}
.content-gym-home .visual .block-bg-cover.show{
  visibility: visible;
}*/
.content-gym-home .info {
  position: relative;
  z-index: 50;
  width: 50%;
  min-height: 100vh;
  margin-left: 50%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.content-gym-home .info-content {
  width: 100%;
  padding: 0 10%;
  margin-top: -100px; }

.content-gym-home .info-content > p {
  margin-bottom: 40px; }

.content-gym-home .info-content .sup {
  font-size: 60%;
  top: -0.6em; }

.content-gym-home .click-form {
  margin: -35px 0 0 0;
  font: normal 400 24px/24px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  opacity: 0.5; }

.click-form .underline-anim {
  background-position: -227px 27px; }

.click-form .underline-anim:not(.hover-out):hover {
  background-position: 0 27px; }

.click-form .underline-anim.hover-out {
  background-position: 227px 27px; }

.content-gym-home .info-content .info-btn {
  position: absolute;
  bottom: 8%;
  left: 0;
  width: 100%;
  margin: 0; }
  .content-gym-home .info-content .info-btn.double {
    position: relative;
    bottom: 0;
    width: 100%; }

.content-gym-home .info-content .btn-wrapper {
  position: absolute;
  bottom: 8%;
  left: 0;
  width: 100%;
  margin: 0;
  display: flex; }
  @media screen and (max-width: 1024px) {
    .content-gym-home .info-content .btn-wrapper {
      position: relative; } }
  .content-gym-home .info-content .btn-wrapper .info-btn.double:first-child {
    padding-right: 10px; }
    .content-gym-home .info-content .btn-wrapper .info-btn.double:first-child .btn {
      margin-right: 0; }
  .content-gym-home .info-content .btn-wrapper .info-btn.double:last-child {
    padding-left: 10px; }
    .content-gym-home .info-content .btn-wrapper .info-btn.double:last-child .btn {
      margin-left: 0; }
  .content-gym-home .info-content .btn-wrapper .info-btn .text {
    z-index: 10; }
  .content-gym-home .info-content .btn-wrapper .info-btn .modal .arrow {
    display: none; }
  .content-gym-home .info-content .btn-wrapper .info-btn .modal.over {
    transition: color 0.25s ease-in-out;
    color: #9d8677; }

.content-gym-home.health-clubs .info-content .btn-wrapper .info-btn .modal.over {
  color: #52433a; }

.content-gym-home .info-btn .btn {
  margin: 0 auto; }

.content-gym-home .grid-icons-circle .break {
  overflow: hidden; }

.content-gym-home .grid-icons-circle .break span {
  display: inline-block;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.mobile .content-gym-home .grid-icons-circle .break span {
  display: inline;
  -webkit-transform: none;
  transform: none; }

.content-gym-home .grid-icons-circle .icon .svg-circle {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
  -webkit-transition: all 0.3s cubic-bezier(1, 0.01, 0.23, 1.2);
  transition: all 0.3s cubic-bezier(1, 0.01, 0.23, 1.2); }

.content-gym-home .goAnim .grid-icons-circle .icon .svg-circle,
.mobile .content-gym-home .grid-icons-circle .icon .svg-circle {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* celas*/
.content-gym-home.fitness-clubs {
  background-color: #9d8677; }

/* lagrimas*/
.content-gym-home.health-clubs {
  background-color: #52433a; }

.content-gym-home.health-clubs .visual {
  left: auto;
  right: 0;
  top: 0; }

.content-gym-home.health-clubs .info {
  margin-left: 0; }

/******* =CAMPAIGNS *******/
.content-campg-home {
  position: relative;
  z-index: 150;
  width: 100%;
  background: #252322;
  font: normal 400 66px/57px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff; }

.content-campg-home h4 {
  padding: 10px 40px 32px 40px; }

.content-campg-home .sec-name,
.content-campg-home .sec-info,
.content-campg-home .sec-extra {
  padding: 0 40px;
  font-size: 24px;
  line-height: 24px; }

.content-campg-home .sec-extra {
  position: absolute;
  left: 0;
  top: auto;
  bottom: 48px;
  width: 100%;
  height: auto;
  opacity: 0.5; }

.content-campg-home .sec-info {
  line-height: 28px;
  opacity: 0.5; }

.content-campg-home .underline {
  text-decoration: underline; }

.content-campg-home .hide-parallax {
  visibility: hidden; }

.content-campg-home-grid {
  position: relative;
  width: 100%;
  min-height: 520px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.content-campg-home-grid li {
  position: relative;
  z-index: 10;
  width: 33.333%; }

.content-campg-home-grid li:hover {
  z-index: 20; }

.content-campg-home-grid li.green a,
.content-campg-home-grid li.cblue a,
.content-campg-home-grid li.blue a,
.content-campg-home-grid li.black a {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%; }

.content-campg-home-grid li.green,
.content-campg-home-grid li.green .dummy,
.content-campg-home-grid li.green .block-bg-cover {
  background-color: #c8dc83; }

.content-campg-home-grid li.cblue,
.content-campg-home-grid li.cblue .block-bg-cover {
  background-color: #8983dc; }

.content-campg-home-grid li.blue,
.content-campg-home-grid li.blue .block-bg-cover {
  background-color: #1f202b; }

.content-campg-home-grid li.black,
.content-campg-home-grid li.black .block-bg-cover {
  background-color: #191614; }

.content-campg-home .grid-center-vh {
  position: absolute;
  width: 100%;
  height: 100%; }

.content-campg-home .block-bg-cover img {
  width: 100%;
  max-width: auto; }

.content-campg-home .parallax-b {
  position: absolute;
  width: 100%;
  height: 100%; }

.content-campg-home .parallax-b-container.over .parallax-b-shadow {
  -webkit-box-shadow: 0 35px 80px rgba(53, 42, 35, 0.8), 0 16px 40px rgba(53, 42, 35, 0.6);
  box-shadow: 0 35px 80px rgba(53, 42, 35, 0.8), 0 16px 40px rgba(53, 42, 35, 0.6); }

.content-campg-home .parallax-b-shine {
  opacity: 0;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear; }

.content-campg-home .parallax-b-container.over .parallax-b-shine {
  opacity: 1;
  -webkit-mix-blend-mode: overlay;
  -ms-mix-blend-mode: overlay;
  mix-blend-mode: overlay; }

/******* =MAP =CONTACTS *******/
.content-map-home:not(.map-gym) {
  position: relative;
  display: block;
  width: 100vw;
  z-index: 100;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
  padding: 0; }
  .content-map-home:not(.map-gym) .scroll-wrapper-horizontal {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column {
      position: relative;
      width: 45vw;
      overflow: hidden;
      padding: 260px 0 180px 0;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform; }
      .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div {
        width: 80%;
        margin: 0 auto; }
        .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym {
          display: block; }
          .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym h3 {
            pointer-events: none;
            transition: transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
            -webkit-transition: -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
            .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym h3:before, .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym h3:after,
            .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym h3 .city {
              transition: transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
              -webkit-transition: -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
          @media (hover: hover) {
            .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym:hover h3 {
              transform: scale(1.05);
              -webkit-transform: scale(1.05); }
              .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym:hover h3:before {
                -webkit-transform: translateX(-50%) translateY(8px);
                transform: translateX(-50%) translateY(8px); }
              .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym:hover h3:after {
                -webkit-transform: translateX(-50%) translateY(-8px);
                transform: translateX(-50%) translateY(-8px); }
              .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div .link-gym:hover h3 .city {
                -webkit-transform: translate3d(-50%, 8px, 0);
                transform: translate3d(-50%, 8px, 0); } }
        @media screen and (max-width: 566px) {
          .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column div {
            width: 100%; } }
      @media screen and (max-height: 768px) {
        .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column {
          padding: 200px 0 150px 0; } }
      @media screen and (max-width: 1080px) {
        .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column {
          width: 85vw; } }
      @media screen and (max-width: 767px) {
        .content-map-home:not(.map-gym) .scroll-wrapper-horizontal .gym-column {
          padding: 100px 0 140px 0; } }
  .content-map-home:not(.map-gym) .gym-btn {
    display: none; }

.mobile .content-map-home .gym-btn {
  display: block; }
  @media screen and (max-width: 767px) {
    .mobile .content-map-home .gym-btn.prev {
      left: 20px; }
    .mobile .content-map-home .gym-btn.next {
      right: 20px; } }

.content-map-home.map-gym {
  position: relative;
  z-index: 100;
  min-height: 425px; }
  .content-map-home.map-gym .grid-cell {
    padding: 260px 0 180px 0; }
    .content-map-home.map-gym .grid-cell div {
      padding: 0;
      width: 80%;
      margin: 0 auto; }

.content-map-home h3 {
  max-width: 100%;
  padding: 0; }
  @media screen and (max-height: 768px) {
    .content-map-home h3 {
      font: normal 400 60px/74px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
      letter-spacing: 0.5px; }
      .content-map-home h3::before, .content-map-home h3::after {
        -webkit-transform: translateX(-50%) scale(0.8);
        -ms-transform: translateX(-50%) scale(0.8);
        transform: translateX(-50%) scale(0.8); } }
    @media screen and (max-height: 768px) and (max-width: 767px) {
      .content-map-home h3::before, .content-map-home h3::after {
        -webkit-transform: translateX(-50%) scale(0.65);
        -ms-transform: translateX(-50%) scale(0.65);
        transform: translateX(-50%) scale(0.65); } }
  @media screen and (max-height: 768px) {
      .content-map-home h3.yellow::before {
        top: -80px; } }
    @media screen and (max-height: 768px) and (max-width: 767px) {
      .content-map-home h3.yellow::before {
        top: -70px; } }
    @media screen and (max-height: 768px) and (max-width: 413px) {
      .content-map-home h3.yellow::before {
        top: -60px; } }
  @media screen and (max-height: 768px) {
      .content-map-home h3.yellow::after {
        top: 80px; } }
    @media screen and (max-height: 768px) and (max-width: 767px) {
      .content-map-home h3.yellow::after {
        top: 70px; } }
    @media screen and (max-height: 768px) and (max-width: 413px) {
      .content-map-home h3.yellow::after {
        top: 60px; } }
  @media screen and (max-height: 768px) {
      .content-map-home h3 .city {
        font-size: 22px;
        top: -26px; } }
    @media screen and (max-height: 768px) and (max-width: 767px) {
      .content-map-home h3 .city {
        font-size: 20px;
        top: -22px; } }
    @media screen and (max-height: 768px) and (max-width: 413px) {
      .content-map-home h3 .city {
        top: -11px; } }
  @media screen and (max-width: 600px) {
    .content-map-home h3 {
      font-size: 58px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (max-width: 430px) {
    .content-map-home h3 {
      font-size: 51px; } }
  @media screen and (max-width: 413px) {
    .content-map-home h3 {
      font-size: 12vw; } }

.content-map-home .link-gym + p {
  text-align: center;
  padding-top: 153px;
  font: 15px/24px "p-Regular", Helvetica, Arial, sans-serif; }
  @media screen and (max-height: 768px) {
    .content-map-home .link-gym + p {
      padding-top: 120px; } }
  @media screen and (max-width: 767px) {
    .content-map-home .link-gym + p {
      padding-top: 100px; } }
  @media screen and (max-width: 413px) {
    .content-map-home .link-gym + p {
      padding-left: 20px;
      padding-right: 20px; } }

.content-map-home .lagrimas-health-club {
  background: transparent url(/public/files/home/map-lagrimas.gif) no-repeat center center;
  background-size: cover; }

.content-map-home .celas-fitness-club {
  background: transparent url(/public/files/home/map-celas.gif) no-repeat center center;
  background-size: cover; }

.content-map-home .leiria-health-club {
  background: transparent url(/public/files/home/map-leiria.jpg) no-repeat center center;
  background-size: cover; }

.content-map-home .gym-column div {
  width: 100%;
  padding-top: 70px; }

.content-map-home .btn {
  margin: 65px auto 90px auto; }
  @media screen and (max-height: 768px) {
    .content-map-home .btn {
      margin: 65px auto; } }
  @media screen and (max-width: 767px) {
    .content-map-home .btn {
      margin: 55px auto; } }

.content-map-home .ball {
  display: block;
  width: 18px;
  height: 18px;
  margin: 45px auto;
  border-radius: 50% 50%;
  background: #fff; }
  @media screen and (max-width: 767px) {
    .content-map-home .ball {
      margin: 40px auto; } }

.content-map-home ul {
  float: right;
  width: 45%;
  font: normal 26px/30px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }
  @media screen and (max-width: 374px) {
    .content-map-home ul {
      font-size: 22px;
      line-height: 26px; } }

.content-map-home ul.days {
  float: left;
  width: 55%; }

.content-map-home ul span {
  display: inline-block;
  width: 87px;
  opacity: 0.6;
  padding-right: 10px;
  text-align: right; }

/*.content-map-home .bg-blue ul span{color: #a1a3a5;}
.content-map-home .bg-lbrown ul span{color: #605f60;}*/
.email.underline-anim {
  background-position: -112px 30px; }

.email.underline-anim:not(.hover-out):hover {
  background-position: 0 30px; }

.email.underline-anim.hover-out {
  background-position: 112px 30px; }

.content-map-home ul.days span {
  width: 176px; }

.mobile .parallax-b-layer {
  top: 0;
  left: 0; }

.content-gym-home.fitness-clubs .info:nth-child(3),
.content-gym-home.fitness-clubs .info:nth-child(5) {
  background-color: #b49d90; }

.content-gym-home.health-clubs .info:nth-child(2),
.content-gym-home.health-clubs .info:nth-child(4) {
  background-color: #5f4d42; }

@media screen and (max-width: 1280px) {
  .content-map-home ul {
    float: none !important;
    margin: 0 auto;
    width: 320px !important; }
  .content-map-home ul.days {
    padding-bottom: 33px; }
  .content-map-home ul span,
  .content-map-home ul.days span {
    width: 50%; }
  .content-map-home ul span {
    margin-left: 4px; } }

@media screen and (max-width: 1134px) {
  .content-campg-home h4 {
    font-size: 52px;
    line-height: 1; }
  .content-campg-home .sec-extra {
    bottom: 28px; } }

@media screen and (max-width: 1024px) {
  .content-gym-home {
    position: relative;
    width: 100%;
    height: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .content-gym-home .visual {
    position: relative;
    width: 100%;
    height: 509px;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .content-gym-home .info {
    position: relative;
    width: 100%;
    min-height: 400px;
    padding: 80px 0;
    margin-left: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .content-gym-home .info-content {
    width: 100%;
    padding: 0 80px;
    margin-top: 0; }
  .content-gym-home .info-content .info-btn {
    position: relative;
    bottom: auto;
    left: auto; }
  .content-campg-home-grid {
    display: block; }
  .content-campg-home-grid li {
    width: 50%;
    height: 450px;
    float: left;
    overflow: hidden; } }

@media screen and (max-width: 1023px) {
  .content-map-home h3 {
    margin: 0 auto; }
  .home footer p span {
    display: inline-block;
    padding: 0 2px 0 0;
    margin-left: 2px;
    color: #505267;
    width: 50%;
    text-align: right; } }

@media screen and (max-width: 566px) {
  .content-gym-home .info {
    min-height: 200px;
    padding-top: 40px;
    padding-bottom: 0; }
  .content-gym-home.fitness-clubs .info:nth-child(3),
  .content-gym-home.fitness-clubs .info:nth-child(5) {
    background-color: #b49d90; }
  .content-gym-home.health-clubs .info:nth-child(2),
  .content-gym-home.health-clubs .info:nth-child(4) {
    background-color: #5f4d42; }
  .content-gym-home .info:not(.triggerToRect) {
    padding-top: 40px; }
  .content-gym-home .info-content .info-btn {
    display: none; }
  .content-gym-home .info-content p {
    font-size: 18px;
    line-height: 32px; }
  .content-gym-home .info-content > p.grid-icons-circle {
    margin-bottom: 10px; }
  .grid-icons-circle .text {
    font-size: 32px;
    margin-top: 30px; }
  .grid-icons-circle .icon,
  .grid-icons-circle .icon .svg-circle {
    height: 94px; }
  .grid-icons-circle .icon-modal36,
  .grid-icons-circle .icon-modal24,
  .grid-icons-circle .icon-spa-home {
    width: 50px; }
  .grid-icons-circle .icon-crossfit {
    width: 48px; }
  .grid-icons-circle .icon-massag {
    width: 55px;
    margin-bottom: 3px; }
  .grid-icons-circle .icon-pool-home {
    width: 75px;
    margin-bottom: 5px; }
  .content-campg-home-grid li {
    width: 100%;
    height: 400px;
    float: none; }
  .content-campg-home h4 {
    padding-left: 20px;
    padding-right: 20px; }
  .content-campg-home .sec-name,
  .content-campg-home .sec-info,
  .content-campg-home .sec-extra {
    padding-left: 20px;
    padding-right: 20px; }
  .content-map-home h3 {
    padding: 0 20px; }
  .content-map-home .gym-column div {
    width: 100%; }
  .content-map-home .gym-column {
    padding: 160px 0 120px 0; } }

@media screen and (max-width: 420px) {
  .content-map-home .gym-column div {
    width: 100%; }
  .content-map-home ul {
    width: 90% !important; }
  .content-campg-home h4 {
    font-size: 46px; }
  .content-campg-home-grid li {
    height: 350px;
    height: 50vh; } }

@media screen and (max-width: 414px) {
  .content-gym-home .info-content {
    padding-left: 40px;
    padding-right: 40px; }
  .content-modal-home h3 span::before {
    height: 24px;
    top: -50px; }
  .content-modal-home h3 {
    bottom: 60px; }
  .modality-home {
    min-height: 527px;
    height: 320px; } }

@media screen and (max-width: 360px) {
  .content-modal-home h3 {
    bottom: 80px; } }

@media screen and (max-width: 320px) {
  .content-gym-home .info-content {
    padding-left: 20px;
    padding-right: 20px; } }

@media screen and (max-height: 635px) and (min-width: 1024px) {
  .content-gym-home .info-content {
    margin-top: 0; }
  .content-gym-home .info-content > p.grid-icons-circle {
    margin-bottom: 0; }
  .content-gym-home .info-content p:not(.grid-icons-circle):not(.click-form) {
    font-size: 22px;
    line-height: 38px; }
  .content-gym-home .info-content .info-btn {
    position: relative; } }

@media screen and (max-height: 500px) {
  .content-campg-home-grid li {
    height: 400px;
    height: 100vh; }
  .content-campg-home h4 {
    font-size: 42px; }
  .content-campg-home .sec-name,
  .content-campg-home .sec-info,
  .content-campg-home .sec-extra {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px; }
  .content-map-home .gym-column {
    padding: 160px 0 120px 0; } }

/*ul.days:after {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: blueviolet;
  margin-left: -1px;
}*/
/******* =footer *******/
.home footer {
  position: relative;
  z-index: 150;
  width: 100%;
  height: 0;
  min-height: 0;
  text-align: center;
  padding: 0;
  background-color: transparent; }

.home footer p span {
  display: inline-block;
  padding: 0 5px;
  color: #151a1f; }

.home footer p a {
  color: #fff; }

/*-------------------------------------------------------------------------
    =MODALIDADES TODAS
-------------------------------------------------------------------------*/
.modalidades {
  background-color: #252322; }

.section-group-all {
  position: relative;
  overflow: hidden; }

@media screen and (max-height: 800px) and (min-width: 1024px) {
  .modalidades:not(.modalidades-all):not(.team-all) {
    margin-top: -70px; } }

/*.modalidades .content-horarios h3 {
  font: normal 50px/58px 'p-Light', Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  max-width: 262px;
  padding-bottom: 50px;
}*/
.modalidades .modality-info-list span.odometer-digit {
  font: normal 19px/19px "p-Medium", Helvetica, Arial, sans-serif; }

.modalidades .socialcount.like .facebook iframe {
  width: 59px; }

.modalidades .socialcount > li > a {
  display: table; }

/* =header  svg and txt*/
.modalidades .header-group {
  position: relative;
  width: 100%; }

.mobile .header-group {
  height: 90vh; }

.section-group-all .center-stage {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 174px;
  width: 100%;
  text-align: center;
  text-transform: uppercase; }

.mobile .section-group-all .center-stage {
  bottom: 30%; }

.section-group-all .center-stage a.btn {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 300px;
  height: auto;
  margin-left: -150px;
  font: normal 400 42px/42px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase; }

.section-group-all .center-stage a.btn .down {
  margin-top: 10px; }

.mobile .section-group-all .center-stage a.btn .down {
  display: none; }

.modalidades .header-group.header-fixed .center-stage {
  position: fixed; }

.mobile .modalidades .header-group.header-fixed .center-stage {
  position: absolute; }

.modalidades .header-group .bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.15; }

.mobile .modalidades .header-group .bg {
  opacity: 0.95; }

.modalidades .header-group.fixed-always .bg,
.modalidades .header-group.header-fixed .bg {
  position: fixed; }

.mobile .modalidades .header-group.fixed-always .bg,
.mobile .modalidades .header-group.header-fixed .bg {
  position: relative; }

.section-group-all.power .header-group .bg {
  background: transparent url(/public/files/modalidades-power.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

.section-group-all.moves .header-group .bg {
  background: transparent url(/public/files/modalidades-moves.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

.section-group-all.ride .header-group .bg {
  background: transparent url(/public/files/modalidades-ride.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

.section-group-all.flow .header-group .bg {
  background: transparent url(/public/files/modalidades-flow.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

.section-group-all.aqua .header-group .bg {
  background: transparent url(/public/files/modalidades-aqua.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

.section-group-all.growup .header-group .bg {
  background: transparent url(/public/files/modalidades-growup.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

/* Exceptions for Safari */
.safari:not(.mobile) .section-group-all.power .header-group .bg {
  height: 100vh; }

.safari:not(.mobile)
.modalidades
.power
.header-group.header-fixed
.center-stage {
  top: calc(100vh - 174px); }

@media screen and (max-width: 767px) {
  .section-group-all .center-stage a.btn {
    font: normal 400 33px/42px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif; } }

@media screen and (max-width: 767px) and (orientation: landscape) {
  .mobile .modalidades .header-group {
    height: 75vh; }
  .mobile .section-group-all .center-stage {
    bottom: 26%; } }

@media screen and (max-width: 767px) and (orientation: portrait) {
  .mobile .modalidades .header-group {
    height: 72vh; }
  .mobile .section-group-all .center-stage {
    bottom: 35%; }
  .section-group-all.power .header-group .bg {
    background: transparent url(/public/files/modalidades-power-vertical.svg) no-repeat center center;
    background-size: cover; }
  .section-group-all.moves .header-group .bg {
    background: transparent url(/public/files/modalidades-moves-vertical.svg) no-repeat center center;
    background-size: cover; }
  .section-group-all.ride .header-group .bg {
    background: transparent url(/public/files/modalidades-ride-vertical.svg) no-repeat center center;
    background-size: cover; }
  .section-group-all.flow .header-group .bg {
    background: transparent url(/public/files/modalidades-flow-vertical.svg) no-repeat center center;
    background-size: cover; }
  .section-group-all.aqua .header-group .bg {
    background: transparent url(/public/files/modalidades-aqua-vertical.svg) no-repeat center center;
    background-size: cover; }
  .section-group-all.growup .header-group .bg {
    background: transparent url(/public/files/modalidades-growup-vertical.svg) no-repeat center center;
    background-size: cover; } }

@media screen and (max-width: 320px) {
  .mobile .section-group-all .center-stage {
    bottom: 30%; } }

/** =header video */
.modalidades .bg-video-container {
  /* = used for performance */
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.modalidades .bg-video-container.fixed,
.modalidades .bg-video-container.fixed-always {
  position: fixed;
  -webkit-backface-visibility: hidden;
  /* =bug - chrome position fixed */
  -webkit-transform: translateZ(0); }

.modalidades .bg-video-container.off {
  /* = used for performance */
  display: none; }

.mobile .modalidades .bg-video-container.fixed,
.mobile .modalidades .bg-video-container.fixed-always {
  position: relative; }

.mobile .modalidades .bg-video-container.off {
  display: block; }

.modalidades .bg-video,
.modalidades .bg-video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

/* Estilo especifico para imagem em mobile nao ficar cover */
.mobile .modalidades .bg-video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 100%;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.no-csstransforms3d .modalidades .bg-video,
.no-csstransforms3d .modalidades .bg-video-container img {
  top: 0;
  left: 0; }

/* =modality list group */
/* NOTES: using srcset 2x + normal size 110% biger for nor blur on scale */
.nav-modality-list {
  position: relative;
  z-index: 20;
  max-width: 100%;
  margin: -140px 6% 0 6%;
  background-color: #252322;
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.mobile .nav-modality-list {
  margin: 0; }

.nav-modality-list li {
  position: relative;
  width: 25%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }
  .nav-modality-list li.no-image {
    pointer-events: none; }
    .nav-modality-list li.no-image h3 {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .nav-modality-list li.no-image p {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .nav-modality-list li.no-image .bg-diag {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .nav-modality-list li.no-image .darker {
      -webkit-transform: skewY(22deg);
      -ms-transform: skewY(22deg);
      transform: skewY(22deg); }

.no-flexbox .nav-modality-list li {
  display: block;
  float: left; }

@media screen and (min-width: 2000px) {
  .nav-modality-list li {
    width: 20%; } }

@media screen and (max-width: 1280px) {
  .nav-modality-list li {
    width: 33.333%; } }

@media screen and (max-width: 822px) {
  .nav-modality-list {
    margin: 0; }
  .nav-modality-list li {
    width: 50%; } }

@media screen and (max-width: 566px) {
  .nav-modality-list li {
    width: 100%; } }

.nav-modality-list li a {
  position: relative;
  z-index: 5;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

.nav-modality-list li a.clicked {
  pointer-events: none;
  cursor: default;
  opacity: 0; }

.nav-modality-list li img {
  z-index: 5;
  max-width: inherit;
  height: 350px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: scale(1) translate3d(0, 0, 0);
  -ms-transform: scale(1) translate3d(0, 0, 0);
  transform: scale(1) translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  -ms-transition: -ms-transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  transition: transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05); }

.nav-modality-list h3 {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 43px;
  /*bottom: 23px;*/
  letter-spacing: 0;
  font: normal 400 42px/42px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  opacity: 0.2;
  -webkit-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  -webkit-transition: -webkit-transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.nav-modality-list p {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 21px;
  opacity: 0;
  font: normal 400 27px/27px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  -webkit-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: all 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.nav-modality-list li .bg-diag {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  -webkit-transform: translate3d(0, 175px, 0);
  -ms-transform: translate3d(0, 175px, 0);
  transform: translate3d(0, 175px, 0);
  width: 100%;
  height: 175px;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  -ms-transition: -ms-transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05);
  transition: transform 0.2s cubic-bezier(0.17, 0.86, 0.31, 1.05); }

.no-borderimage .nav-modality-list li .bg-diag {
  display: none; }

.nav-modality-list li .darker {
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: skewY(25deg);
  -ms-transform: skewY(25deg);
  transform: skewY(25deg);
  -webkit-transition: -webkit-transform 0.2s ease 0.05s;
  -ms-transition: -ms-transform 0.2s ease 0.05s;
  transition: transform 0.2s ease 0.05s; }

.nav-modality-list li:hover img {
  -webkit-transform: scale(1.02) translate3d(0, -5px, 0);
  -webkit-transform: scale(1.02) translate3d(0, -5px, 0);
  -webkit-transform: scale(1.02) translate3d(0, -5px, 0); }

.nav-modality-list li:hover h3 {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.nav-modality-list li:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.nav-modality-list li:hover .bg-diag {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.nav-modality-list li:hover .darker {
  -webkit-transform: skewY(22deg);
  -ms-transform: skewY(22deg);
  transform: skewY(22deg); }

.modalidades .trigger {
  width: 1px;
  height: 1px; }

/* =Modality all NAVIGATION */
nav.nav-modality-all {
  position: fixed;
  z-index: 110;
  top: 50%;
  right: 2%;
  width: 30px;
  height: 300px;
  margin-top: -150px;
  font: normal 400 24px/24px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
  -webkit-backface-visibility: hidden;
  /* =bug - chrome position fixed */
  -webkit-transform: translateZ(0); }

@media screen and (max-width: 1024px) {
  nav.nav-modality-all {
    right: 13px; } }

@media screen and (max-width: 822px) {
  nav.nav-modality-all {
    display: none; } }

.mobile nav.nav-modality-all {
  display: none; }

nav.nav-modality-all ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

nav.nav-modality-all li {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  margin: 11px 0;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.3s ease-out; }

nav.nav-modality-all a {
  position: relative;
  display: block;
  width: 18px;
  height: 100%;
  color: #fff; }

nav.nav-modality-all a.on {
  pointer-events: none; }

.nav-modality-all .crl {
  display: block;
  opacity: 1;
  width: 18px;
  height: 100%;
  border-radius: 18px 18px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transition: height 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
  -ms-transition: height 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
  transition: height 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s; }

.nav-modality-all .on .crl {
  opacity: 0;
  -webkit-transition: height 0.3s ease-out, opacity 0.3s ease-out;
  -ms-transition: height 0.3s ease-out, opacity 0.3s ease-out;
  transition: height 0.3s ease-out, opacity 0.3s ease-out; }

.nav-modality-all .crl span {
  display: block;
  position: absolute;
  top: 0;
  left: -5555px;
  width: 100%;
  height: 100%;
  border-radius: 18px 18px;
  opacity: 0.5;
  background-color: #fff;
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: opacity 0.25s ease-out, -webkit-transform 0.25s ease-out;
  -ms-transition: opacity 0.25s ease-out, -ms-transform 0.25s ease-out;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out; }

nav.nav-modality-all a:hover .crl {
  -webkit-animation: animNavModalAllHover 0.35s ease-in-out forwards;
  -ms-animation: animNavModalAllHover 0.35s ease-in-out forwards;
  animation: animNavModalAllHover 0.35s ease-in-out forwards; }

@-webkit-keyframes animNavModalAllHover {
  0% {
    -webkit-transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1); } }

@-ms-keyframes animNavModalAllHover {
  0% {
    -ms-transform: scale(1); }
  50% {
    -ms-transform: scale(1.2); }
  100% {
    -ms-transform: scale(1); } }

@keyframes animNavModalAllHover {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

nav.nav-modality-all a:hover .crl span {
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3); }

.nav-modality-all .txt {
  position: absolute;
  left: 0;
  top: 50%;
  opacity: 0;
  text-indent: -5555px;
  width: 18px;
  -webkit-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s; }

.nav-modality-all .on .txt {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  -ms-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s; }

.nav-modality-all a[data-go="#power"] .txt {
  height: 42px;
  margin-top: -21px;
  background: transparent url(/public/imgs/modalidades/txt-nav-power.svg) no-repeat; }

.nav-modality-all a[data-go="#moves"] .txt {
  height: 42px;
  margin-top: -21px;
  background: transparent url(/public/imgs/modalidades/txt-nav-moves.svg) no-repeat; }

.nav-modality-all a[data-go="#ride"] .txt {
  height: 28px;
  margin-top: -14px;
  background: transparent url(/public/imgs/modalidades/txt-nav-ride.svg) no-repeat; }

.nav-modality-all a[data-go="#flow"] .txt {
  height: 33px;
  margin-top: -16px;
  background: transparent url(/public/imgs/modalidades/txt-nav-flow.svg) no-repeat; }

.nav-modality-all a[data-go="#aqua"] .txt {
  height: 33px;
  margin-top: -16px;
  background: transparent url(/public/imgs/modalidades/txt-nav-aqua.svg) no-repeat; }

.nav-modality-all a[data-go="#growup"] .txt {
  height: 59px;
  margin-top: -29px;
  background: transparent url(/public/imgs/modalidades/txt-nav-growup.svg) no-repeat; }

/*-------------------------------------------------------------------------
    =MODALIDADES INDIVIDUAIS
-------------------------------------------------------------------------*/
/** =HEADER - pic */
.modality-pic {
  position: relative;
  width: 100%;
  max-height: none;
  min-height: 100vh;
  /*padding: 100px 0 0 0;*/ }

.no-picture .modality-pic {
  min-height: 100vh; }

.modality-pic-wrapper {
  position: relative;
  z-index: 20;
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px; }

.modality-img {
  position: relative;
  margin: 0 auto;
  display: block; }

.modality-pic-current {
  position: relative;
  z-index: 20; }

.modality-pic-next,
.modality-pic-prev {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%; }

.modality-pic-wrapper .for3d {
  -webkit-transform-style: preserve-3d;
  -mz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center; }

.modality-pic-current.for3d {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg); }

.modality-pic-wrapper.exit-goingToRght .modality-pic-current {
  -webkit-animation: anim-exit-goingToRght-current 0.2s ease-in both;
  -ms-animation: anim-exit-goingToRght-current 0.2s ease-in both;
  animation: anim-exit-goingToRght-current 0.2s ease-in both; }

@-webkit-keyframes anim-exit-goingToRght-current {
  0% {
    -webkit-transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(-40deg);
    opacity: 0; } }

@-ms-keyframes anim-exit-goingToRght-current {
  0% {
    transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: rotateY(-40deg);
    opacity: 0; } }

@keyframes anim-exit-goingToRght-current {
  0% {
    transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: rotateY(-40deg);
    opacity: 0; } }

.modality-pic-wrapper.exit-goingToLft .modality-pic-current {
  -webkit-animation: anim-exit-goingToLft-current 0.2s ease-in both;
  -ms-animation: anim-exit-goingToLft-current 0.2s ease-in both;
  animation: anim-exit-goingToLft-current 0.2s ease-in both; }

@-webkit-keyframes anim-exit-goingToLft-current {
  0% {
    -webkit-transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(40deg);
    opacity: 0; } }

@-ms-keyframes anim-exit-goingToLft-current {
  0% {
    -webkit-transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(40deg);
    opacity: 0; } }

@keyframes anim-exit-goingToLft-current {
  0% {
    transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: rotateY(40deg);
    opacity: 0; } }

.modality-pic-next.for3d {
  -webkit-transform: rotateY(30deg);
  -ms-transform: rotateY(30deg);
  transform: rotateY(30deg);
  opacity: 0; }

.modality-pic-wrapper.exit-goingToRght .modality-pic-next {
  -webkit-animation: anim-exit-goingToRght-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  -ms-animation: anim-exit-goingToRght-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  animation: anim-exit-goingToRght-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  z-index: 25; }

@-webkit-keyframes anim-exit-goingToRght-next {
  0% {
    -webkit-transform: rotateY(30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@-ms-keyframes anim-exit-goingToRght-next {
  0% {
    -webkit-transform: rotateY(30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@keyframes anim-exit-goingToRght-next {
  0% {
    transform: rotateY(30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    transform: rotateY(0deg);
    opacity: 1; } }

.modality-pic-prev.for3d {
  -webkit-transform: rotateY(-30deg);
  -ms-transform: rotateY(-30deg);
  transform: rotateY(-30deg);
  opacity: 0; }

.modality-pic-wrapper.exit-goingToLft .modality-pic-prev {
  -webkit-animation: anim-exit-goingToLft-prev 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  -ms-animation: anim-exit-goingToLft-prev 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  animation: anim-exit-goingToLft-prev 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.2s;
  z-index: 25; }

@-webkit-keyframes anim-exit-goingToLft-prev {
  0% {
    -webkit-transform: rotateY(-30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@-ms-keyframes anim-exit-goingToLft-prev {
  0% {
    -webkit-transform: rotateY(-30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@keyframes anim-exit-goingToLft-prev {
  0% {
    transform: rotateY(-30deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    transform: rotateY(0deg);
    opacity: 1; } }

/** =HEADER info- social and text */
.modality-info {
  position: absolute;
  z-index: 30;
  top: 410px;
  left: 50%;
  margin-left: -460px; }

.modality-info .social li {
  margin-right: 18px; }

.modality-title {
  margin: 0 0 28px -6px;
  font: normal 140px/140px "s-Medium", Helvetica, Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: -1px;
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px; }

.modality-title.next,
.modality-title.prev {
  position: absolute;
  left: 0;
  top: 0; }

.modality-title span {
  -webkit-transform-style: preserve-3d;
  -mz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center; }

.modality-title span {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg); }

.modality-info.exit-goingToRght-title .current span {
  -webkit-animation: anim-exit-goingToRght-title-current 0.2s ease-in both 0.1s;
  -ms-animation: anim-exit-goingToRght-title-current 0.2s ease-in both 0.1s;
  animation: anim-exit-goingToRght-title-current 0.2s ease-in both 0.1s; }

@-webkit-keyframes anim-exit-goingToRght-title-current {
  0% {
    -webkit-transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(-90deg);
    opacity: 0; } }

@keyframes anim-exit-goingToRght-title-current {
  0% {
    transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: rotateY(-90deg);
    opacity: 0; } }

.modality-info.exit-goingToLft-title .current span {
  -webkit-animation: anim-exit-goingToLft-title-current 0.2s ease-in both 0.1s;
  -ms-animation: anim-exit-goingToLft-title-current 0.2s ease-in both 0.1s;
  animation: anim-exit-goingToLft-title-current 0.2s ease-in both 0.1s; }

@-webkit-keyframes anim-exit-goingToLft-title-current {
  0% {
    -webkit-transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(90deg);
    opacity: 0; } }

@keyframes anim-exit-goingToLft-title-current {
  0% {
    transform: rotateY(0);
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: rotateY(90deg);
    opacity: 0; } }

.modality-title.next span {
  -webkit-transform: rotateY(90deg);
  -ms-transform: rotateY(90deg);
  transform: rotateY(90deg);
  opacity: 0; }

.modality-info.exit-goingToRght-title .next span {
  -webkit-animation: anim-exit-goingToRght-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s;
  -ms-animation: anim-exit-goingToRght-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s;
  animation: anim-exit-goingToRght-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s; }

@-webkit-keyframes anim-exit-goingToRght-title-next {
  0% {
    -webkit-transform: rotateY(90deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@keyframes anim-exit-goingToRght-title-next {
  0% {
    transform: rotateY(90deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    transform: rotateY(0deg);
    opacity: 1; } }

.modality-title.prev span {
  -webkit-transform: rotateY(-90deg);
  -ms-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  opacity: 0; }

.modality-info.exit-goingToLft-title .next span {
  -webkit-animation: anim-exit-goingToLft-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s;
  -ms-animation: anim-exit-goingToLft-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s;
  animation: anim-exit-goingToLft-title-next 0.6s cubic-bezier(0.11, 0.7, 0.01, 1.03) both 0.3s; }

@-webkit-keyframes anim-exit-goingToLft-title-next {
  0% {
    -webkit-transform: rotateY(-90deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: rotateY(0deg);
    opacity: 1; } }

@keyframes anim-exit-goingToLft-title-next {
  0% {
    transform: rotateY(-90deg);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    transform: rotateY(0deg);
    opacity: 1; } }

/** =HEADER - text extra */
.modality-title-extra {
  position: absolute;
  z-index: 10;
  top: 272px;
  left: 50%;
  margin: 0 0 0 130px;
  font: normal 140px/170px "s-thin", Helvetica, Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  word-break: normal;
  letter-spacing: -1px;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.modality-title-extra span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  clip: rect(0px, 800px, 180px, 0px);
  -webkit-transition: clip 0.3s ease-out;
  -ms-transition: clip 0.3s ease-out;
  transition: clip 0.3s ease-out; }

.modality-title-extra.next span {
  clip: rect(0px, 800px, 150px, 800px); }

.exit-goingToRght-title .modality-title-extra span {
  clip: rect(0px, 0px, 150px, 0px); }

.exit-goingToRght-title .modality-title-extra.next span {
  clip: rect(0px, 800px, 150px, 0px); }

.modality-title-extra.prev span {
  clip: rect(0px, 0, 150px, 0); }

.exit-goingToLft-title .modality-title-extra span {
  clip: rect(0, 800px, 150px, 800px); }

.exit-goingToLft-title .modality-title-extra.prev span {
  clip: rect(0px, 800px, 150px, 0px); }

/** =HEADER - linfo list */
.modality-info-list {
  position: absolute;
  z-index: 20;
  top: 453px;
  left: 50%;
  margin-left: 376px;
  font: normal 400 24px/26px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center; }

.modality-info-list li {
  position: relative;
  max-width: 100px;
  margin: 36px 0 0 0; }

.modality-info-list li:first-child {
  margin: 0; }

.modality-info-list svg {
  width: 74px; }

.ie .modality-info-list svg {
  height: 74px; }

.modality-info-list .time svg {
  margin-bottom: 5px; }

.modality-info-list .inten svg {
  margin-bottom: -7px; }

.modality-info-list path {
  fill: #fff; }

.modality-info-list .time .table-vh-center {
  font: normal 19px/19px "pM", Helvetica, Arial, sans-serif;
  position: absolute;
  width: 100%;
  height: 87px; }

/* time */
.time.t30s #time-button,
.time.t45s #time-button,
.time.t60s #time-button,
.time.click-mainBtn #time-button {
  -webkit-animation: animTimeButtonWatch 0.1s linear 5ms forwards;
  -ms-animation: animTimeButtonWatch 0.1s linear 5ms forwards;
  animation: animTimeButtonWatch 0.1s linear 5ms forwards; }

.click-mainBtn-reset #time-button {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 3px, 0); }

@-webkit-keyframes animTimeButtonWatch {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(0, 4px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@keyframes animTimeButtonWatch {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  50% {
    -ms-transform: translate3d(0, 4px, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTimeButtonWatch {
  0% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(0, 4px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

#time_00,
#time_01,
#time_02,
#time_03,
#time_04,
#time_05,
#time_06,
#time_07,
#time_08,
#time_09,
#time_10,
#time_11 {
  opacity: 0; }

.time.t30 #time_00,
.time.t30 #time_01,
.time.t30 #time_02,
.time.t30 #time_03,
.time.t30 #time_04,
.time.t30 #time_05,
.time.t30 #time_06,
.time.t45 #time_00,
.time.t45 #time_01,
.time.t45 #time_02,
.time.t45 #time_03,
.time.t45 #time_04,
.time.t45 #time_05,
.time.t45 #time_06,
.time.t45 #time_07,
.time.t45 #time_08,
.time.t45 #time_09,
.time.t60 #time_00,
.time.t60 #time_01,
.time.t60 #time_02,
.time.t60 #time_03,
.time.t60 #time_04,
.time.t60 #time_05,
.time.t60 #time_06,
.time.t60 #time_07,
.time.t60 #time_08,
.time.t60 #time_09,
.time.t60 #time_10,
.time.t60 #time_11 {
  opacity: 1; }

.time.t30 #time_01 {
  transition-delay: 50ms; }

.time.t30 #time_02 {
  transition-delay: 100ms; }

.time.t30 #time_03 {
  transition-delay: 150ms; }

.time.t30 #time_04 {
  transition-delay: 200ms; }

.time.t30 #time_05 {
  transition-delay: 250ms; }

.time.t30 #time_06 {
  transition-delay: 300ms; }

.time.t45 #time_01 {
  transition-delay: 50ms; }

.time.t45 #time_02 {
  transition-delay: 100ms; }

.time.t45 #time_03 {
  transition-delay: 150ms; }

.time.t45 #time_04 {
  transition-delay: 200ms; }

.time.t45 #time_05 {
  transition-delay: 250ms; }

.time.t45 #time_06 {
  transition-delay: 300ms; }

.time.t45 #time_07 {
  transition-delay: 350ms; }

.time.t45 #time_08 {
  transition-delay: 400ms; }

.time.t45 #time_09 {
  transition-delay: 450ms; }

.time.t60 #time_01 {
  transition-delay: 50ms; }

.time.t60 #time_02 {
  transition-delay: 100ms; }

.time.t60 #time_03 {
  transition-delay: 150ms; }

.time.t60 #time_04 {
  transition-delay: 200ms; }

.time.t60 #time_05 {
  transition-delay: 250ms; }

.time.t60 #time_06 {
  transition-delay: 300ms; }

.time.t60 #time_07 {
  transition-delay: 350ms; }

.time.t60 #time_08 {
  transition-delay: 400ms; }

.time.t60 #time_09 {
  transition-delay: 450ms; }

.time.t60 #time_10 {
  transition-delay: 500ms; }

.time.t60 #time_11 {
  transition-delay: 550ms; }

.time.reset #reset-time,
.time.reset #reset-time,
.time.reset #reset-time {
  -webkit-animation: animTimeResetWatch 0.1s linear 5ms forwards;
  -ms-animation: animTimeResetWatch 0.1s linear 5ms forwards;
  animation: animTimeResetWatch 0.1s linear 5ms forwards; }

@-webkit-keyframes animTimeResetWatch {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(0, 4px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@keyframes animTimeResetWatch {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  50% {
    -ms-transform: translate3d(0, 4px, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTimeResetWatch {
  0% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(0, 4px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.time.t30.reset #time_00,
.time.t30.reset #time_01,
.time.t30.reset #time_02,
.time.t30.reset #time_03,
.time.t30.reset #time_04,
.time.t30.reset #time_05,
.time.t30.reset #time_06,
.time.t45.reset #time_00,
.time.t45.reset #time_01,
.time.t45.reset #time_02,
.time.t45.reset #time_03,
.time.t45.reset #time_04,
.time.t45.reset #time_05,
.time.t45.reset #time_06,
.time.t45.reset #time_07,
.time.t45.reset #time_08,
.time.t45.reset #time_09,
.time.t60.reset #time_00,
.time.t60.reset #time_01,
.time.t60.reset #time_02,
.time.t60.reset #time_03,
.time.t60.reset #time_04,
.time.t60.reset #time_05,
.time.t60.reset #time_06,
.time.t60.reset #time_07,
.time.t60.reset #time_08,
.time.t60.reset #time_09,
.time.t60.reset #time_10,
.time.t60.reset #time_11 {
  opacity: 0; }

.time.t30.reset #time_06 {
  transition-delay: 50ms; }

.time.t30.reset #time_05 {
  transition-delay: 100ms; }

.time.t30.reset #time_04 {
  transition-delay: 150ms; }

.time.t30.reset #time_03 {
  transition-delay: 200ms; }

.time.t30.reset #time_02 {
  transition-delay: 250ms; }

.time.t30.reset #time_01 {
  transition-delay: 300ms; }

.time.t30.reset #time_00 {
  transition-delay: 350ms; }

.time.t45.reset #time_09 {
  transition-delay: 50ms; }

.time.t45.reset #time_08 {
  transition-delay: 100ms; }

.time.t45.reset #time_07 {
  transition-delay: 150ms; }

.time.t45.reset #time_06 {
  transition-delay: 200ms; }

.time.t45.reset #time_05 {
  transition-delay: 250ms; }

.time.t45.reset #time_04 {
  transition-delay: 300ms; }

.time.t45.reset #time_03 {
  transition-delay: 350ms; }

.time.t45.reset #time_02 {
  transition-delay: 400ms; }

.time.t45.reset #time_01 {
  transition-delay: 450ms; }

.time.t45.reset #time_00 {
  transition-delay: 500ms; }

.time.t60.reset #time_11 {
  transition-delay: 50ms; }

.time.t60.reset #time_10 {
  transition-delay: 100ms; }

.time.t60.reset #time_09 {
  transition-delay: 150ms; }

.time.t60.reset #time_08 {
  transition-delay: 200ms; }

.time.t60.reset #time_07 {
  transition-delay: 250ms; }

.time.t60.reset #time_06 {
  transition-delay: 300ms; }

.time.t60.reset #time_05 {
  transition-delay: 350ms; }

.time.t60.reset #time_04 {
  transition-delay: 400ms; }

.time.t60.reset #time_03 {
  transition-delay: 450ms; }

.time.t60.reset #time_02 {
  transition-delay: 500ms; }

.time.t60.reset #time_01 {
  transition-delay: 550ms; }

.time.t60.reset #time_00 {
  transition-delay: 600ms; }

/* target */
.modality-info-list .target span {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.modality-info-list .target.goAnim span {
  opacity: 1; }

.modality-info-list #target-top {
  -webkit-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

.modality-info-list #target-right {
  transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.modality-info-list #target-bottom {
  -webkit-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0); }

.modality-info-list #target-left {
  -webkit-transform: translate3d(-10px, 0, 0);
  -ms-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.modality-info-list .target.goAnim #target-top {
  -webkit-animation: animTargetLineTop 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  -ms-animation: animTargetLineTop 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  animation: animTargetLineTop 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards; }

.modality-info-list .target.goAnim #target-bottom {
  -webkit-animation: animTargetLineBottom 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  -ms-animation: animTargetLineBottom 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  animation: animTargetLineBottom 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards; }

.modality-info-list .target.goAnim #target-right {
  -webkit-animation: animTargetLineRight 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  -ms-animation: animTargetLineRight 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  animation: animTargetLineRight 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards; }

.modality-info-list .target.goAnim #target-left {
  -webkit-animation: animTargetLineLeft 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  -ms-animation: animTargetLineLeft 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards;
  animation: animTargetLineLeft 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19) forwards; }

@-webkit-keyframes animTargetLineTop {
  0% {
    -webkit-transform: translate3d(0, -10px, 0); }
  50% {
    -webkit-transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animTargetLineTop {
  0% {
    -ms-transform: translate3d(0, -10px, 0); }
  50% {
    -ms-transform: translate3d(0, 5px, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTargetLineTop {
  0% {
    transform: translate3d(0, -10px, 0); }
  50% {
    transform: translate3d(0, 5px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes animTargetLineBottom {
  0% {
    -webkit-transform: translate3d(0, 10px, 0); }
  50% {
    -webkit-transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animTargetLineBottom {
  0% {
    -ms-transform: translate3d(0, 10px, 0); }
  50% {
    -ms-transform: translate3d(0, -5px, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTargetLineBottom {
  0% {
    transform: translate3d(0, 10px, 0); }
  50% {
    transform: translate3d(0, -5px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes animTargetLineRight {
  0% {
    -webkit-transform: translate3d(10px, 0, 0); }
  50% {
    -webkit-transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animTargetLineRight {
  0% {
    -ms-transform: translate3d(10px, 0, 0); }
  50% {
    -ms-transform: translate3d(-5px, 0, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTargetLineRight {
  0% {
    transform: translate3d(10px, 0, 0); }
  50% {
    transform: translate3d(-5px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes animTargetLineLeft {
  0% {
    -webkit-transform: translate3d(-10px, 0, 0); }
  50% {
    -webkit-transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-ms-keyframes animTargetLineLeft {
  0% {
    -ms-transform: translate3d(-10px, 0, 0); }
  50% {
    -ms-transform: translate3d(5px, 0, 0); }
  100% {
    -ms-transform: translate3d(0, 0, 0); } }

@keyframes animTargetLineLeft {
  0% {
    transform: translate3d(-10px, 0, 0); }
  50% {
    transform: translate3d(5px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.modality-info-list .target.exitAnim #target-top {
  -webkit-animation: animTargetLineTopExit 0.3s ease forwards;
  -ms-animation: animTargetLineTopExit 0.3s ease forwards;
  animation: animTargetLineTopExit 0.3s ease forwards; }

.modality-info-list .target.exitAnim #target-bottom {
  -webkit-animation: animTargetLineBottomExit 0.3s ease forwards;
  -ms-animation: animTargetLineBottomExit 0.3s ease forwards;
  animation: animTargetLineBottomExit 0.3s ease forwards; }

.modality-info-list .target.exitAnim #target-right {
  -webkit-animation: animTargetLineRightExit 0.3s ease forwards;
  -ms-animation: animTargetLineRightExit 0.3s ease forwards;
  animation: animTargetLineRightExit 0.3s ease forwards; }

.modality-info-list .target.exitAnim #target-left {
  -webkit-animation: animTargetLineLeftExit 0.3s ease forwards;
  -ms-animation: animTargetLineLeftExit 0.3s ease forwards;
  animation: animTargetLineLeftExit 0.3s ease forwards; }

@-webkit-keyframes animTargetLineTopExit {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, -10px, 0); } }

@-ms-keyframes animTargetLineTopExit {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(0, -10px, 0); } }

@keyframes animTargetLineTopExit {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, -10px, 0); } }

@-webkit-keyframes animTargetLineBottomExit {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 10px, 0); } }

@keyframes animTargetLineBottomExit {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(0, 10px, 0); } }

@keyframes animTargetLineBottomExit {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, 10px, 0); } }

@-webkit-keyframes animTargetLineRightExit {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(10px, 0, 0); } }

@keyframes animTargetLineRightExit {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(10px, 0, 0); } }

@keyframes animTargetLineRightExit {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(10px, 0, 0); } }

@-webkit-keyframes animTargetLineLeftExit {
  0% {
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(-10px, 0, 0); } }

@keyframes animTargetLineLeftExit {
  0% {
    -ms-transform: translate3d(0, 0, 0); }
  100% {
    -ms-transform: translate3d(-10px, 0, 0); } }

@keyframes animTargetLineLeftExit {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-10px, 0, 0); } }

/* Intensidade */
.modality-info-list #pointer {
  /* usar px (bug FF) e relativo ao viewbox */
  -webkit-transform-origin: 37px 37px;
  -ms-transform-origin: 37px 37px;
  transform-origin: 37px 37px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19);
  -ms-transition: -ms-transform 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19);
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.485, 1.19); }

.modality-info-list .val-0 #pointer {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.modality-info-list .val-1 #pointer {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.modality-info-list .val-2 #pointer {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.modality-info-list .val-3 #pointer {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

.modality-info-list .val-4 #pointer {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.modality-info-list .val-5 #pointer {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.modalidades .content-horarios span.spare-off {
  position: absolute;
  margin-top: 120px;
  width: 100%;
  font: normal 400 18px/22px "49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase; }

.modalidades .content-horarios .close span.spare-off {
  display: none; }

/* Min-height exception for horizontal images */
@media screen and (max-width: 1700px) {
  .hidrobike .modality-pic,
  .aquacircuit .modality-pic,
  .hidroginastica .modality-pic,
  .hidrohiit .modality-pic,
  .crossphive .modality-pic,
  .bodystep .modality-pic,
  .bodybalance .modality-pic,
  .estrelasmar .modality-pic,
  .borntomove .modality-pic {
    min-height: 100%;
    min-height: 100vh; } }

@media screen and (max-height: 800px) and (min-width: 1024px) {
  .hidrobike .modality-pic,
  .aquacircuit .modality-pic,
  .hidroginastica .modality-pic,
  .hidrohiit .modality-pic,
  .crossphive .modality-pic,
  .bodystep .modality-pic,
  .bodybalance .modality-pic,
  .estrelasmar .modality-pic,
  .borntomove .modality-pic {
    min-height: 150%;
    min-height: 150vh; } }

@media screen and (max-width: 920px) {
  .hidrobike .modality-pic,
  .aquacircuit .modality-pic,
  .hidroginastica .modality-pic,
  .hidrohiit .modality-pic,
  .crossphive .modality-pic,
  .bodystep .modality-pic,
  .bodybalance .modality-pic,
  .estrelasmar .modality-pic,
  .borntomove .modality-pic,
  .pilates .modality-pic,
  .yoga .modality-pic,
  .bodyattack .modality-pic,
  .bodycombat .modality-pic,
  .swimcoach .modality-pic,
  .natacao .modality-pic,
  .phitkids .modality-pic {
    min-height: 100%;
    min-height: 100vh;
    height: 100vh; }
  .mobile .modality-img {
    top: 0; }
  .mobile .modality-title {
    top: 50%; }
  .mobile .hidrobike .modality-img,
  .mobile .aquacircuit .modality-img,
  .mobile .hidroginastica .modality-img,
  .mobile .hidrohiit .modality-img,
  .mobile .crossphive .modality-img,
  .mobile .bodystep .modality-img,
  .mobile .bodybalance .modality-img,
  .mobile .estrelasmar .modality-img {
    top: 40px;
    max-width: 180%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .mobile .pilates .modality-img,
  .mobile .yoga .modality-img {
    top: 0;
    max-width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .mobile .bodybalance .modality-img,
  .mobile .bodystep .modality-img {
    max-width: 100%;
    left: 0;
    transform: translateX(0); }
  .mobile .crossphive .modality-img {
    top: 50px;
    max-width: 100%; }
  .mobile .bodyattack .modality-img {
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .mobile .trx .modality-img,
  .mobile .bodycombat .modality-img {
    top: 50px;
    max-width: 100%; }
  .mobile .bodypump .modality-img {
    max-width: 90%; }
  .mobile .yoguitos .modality-img,
  .mobile .ballet .modality-img,
  .mobile .ballet2 .modality-img {
    max-width: 70%; }
  .mobile .bodyattack .modality-img {
    top: 80px; }
  .mobile .natacao .modality-img {
    top: 40px; }
  .mobile .borntomove .modality-img {
    top: 100px; }
  .forclip .modality-info {
    left: 0;
    top: 310px;
    width: 100%;
    margin: 0; }
  .forclip .modality-title,
  .forclip .modality-title.next,
  .forclip .modality-title.prev {
    width: 100%;
    margin: 0;
    text-align: center; }
  .modality-title-extra-wrapper .modality-title-extra {
    left: 0;
    top: 455px;
    z-index: 20;
    width: 100%;
    margin: 0;
    text-align: center; }
  .modality-title-extra-wrapper .modality-title-extra span {
    position: relative;
    display: inline-block;
    margin: 0 !important; }
  .modality-info-list {
    position: absolute;
    left: 0;
    top: 75vh;
    right: auto;
    display: table;
    border-collapse: collapse;
    margin: 0 0 25px 0;
    width: 100%;
    vertical-align: top;
    text-align: center; }
  .modality-info-list li {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 33%;
    max-width: 0; }
  .modality-info-list span {
    display: block; }
  .modality-info-list .target span {
    padding: 0 20px; }
  .modality-info-list .inten svg {
    margin: 0 0 5px 0; }
  .modality-info-list .target svg {
    margin: 0 0 5px 0; }
  .modalidades .modality-info .social {
    display: none; } }

@media screen and (max-width: 767px) {
  .modality-info .modality-title {
    font-size: 80px;
    line-height: 80px; }
  .modality-title-extra-wrapper .modality-title-extra {
    top: 390px;
    font-size: 80px;
    line-height: 80px; }
  .modalidades .content-horarios span.spare-off {
    margin-top: 90px; } }

@media screen and (max-width: 620px) {
  .mobile .modalidades .modality-pic {
    height: 100vh; }
  .safari .mobile .modalidades .modality-pic {
    height: calc(105vh - 60px); }
  /*exception for safari bar */
  .safari .mobile .hidrobike .modality-pic,
  .safari .mobile .aquacircuit .modality-pic,
  .safari .mobile .hidroginastica .modality-pic,
  .safari .mobile .hidrohiit .modality-pic,
  .safari .mobile .crossphive .modality-pic,
  .safari .mobile .bodystep .modality-pic,
  .safari .mobile .bodybalance .modality-pic,
  .safari .mobile .estrelasmar .modality-pic,
  .safari .mobile .borntomove .modality-pic,
  .safari .mobile .pilates .modality-pic,
  .safari .mobile .yoga .modality-pic,
  .safari .mobile .bodyattack .modality-pic,
  .safari .mobile .bodycombat .modality-pic,
  .safari .mobile .swimcoach .modality-pic,
  .safari .mobile .natacao .modality-pic {
    min-height: calc(105vh - 60px);
    min-height: calc(105vh - 60px);
    height: calc(105vh - 60px); }
  .mobile .modality-img {
    top: 40px; }
  .mobile .phitt .modality-img {
    top: 0; }
  .mobile .bodybalance .modality-img,
  .mobile .bodystep .modality-img {
    max-width: 130%;
    left: 0;
    transform: translateX(0); }
  .mobile .crossphive .modality-img {
    max-width: 130%; }
  .mobile .bodyattack .modality-img {
    max-width: 130%; }
  .mobile .trx .modality-img,
  .mobile .bodycombat .modality-img {
    max-width: 130%; }
  .mobile .pilates .modality-img,
  .mobile .yoga .modality-img {
    top: 40px;
    max-width: 130%; }
  .mobile .phivekangoo .modality-pic {
    height: 110vh; }
  .modality-info-list svg {
    width: 64px; }
  .modalidades .modality-info-list span.odometer-digit {
    font-size: 16px;
    bottom: 1px; }
  .modalidades .content-horarios span.spare-off {
    margin-top: 70px;
    font-size: 14px;
    line-height: 1; }
  .modality-info-list {
    top: 60vh; } }

@media screen and (max-width: 413px) {
  .forclip .modality-info {
    top: 260px; }
  .modality-info .modality-title {
    font-size: 60px;
    line-height: 60px; }
  .modality-title-extra-wrapper .modality-title-extra {
    top: 320px;
    font-size: 60px;
    line-height: 60px; } }

@media screen and (max-height: 570px) {
  .modality-title-extra-wrapper .modality-title-extra {
    top: 220px; }
  .forclip .modality-info {
    top: 160px; } }

/*-------------------------------------------------------------------------
    =TEAM - ALL
-------------------------------------------------------------------------*/
.team-all .section-group-all .header-group .bg {
  background: transparent url(/public/files/instrutores.svg) no-repeat center center;
  background-size: cover;
  background-size: 101%; }

@media screen and (max-width: 767px) and (orientation: portrait) {
  .team-all .section-group-all .header-group .bg {
    background: transparent url(/public/files/instrutores-vertical.svg) no-repeat center center;
    background-size: cover; } }

.mobile .team-all .bg-video-container.fixed {
  position: absolute; }

.team-all .center-stage {
  position: fixed; }

.team-all .nav-modality-list li {
  border-left: 1px solid #151a1f;
  border-bottom: 1px solid #151a1f; }

.team-bg {
  background-color: #0e0f10; }

.team-bg .darker {
  background-color: #0b0b0c; }

.team-all .footer {
  position: relative;
  z-index: 10;
  display: block; }

/* Exceptions for Safari */
.safari:not(.mobile) .team-all .section-group-all .header-group .bg {
  height: 100vh; }

.safari:not(.mobile) .team-all .header-group.header-fixed .center-stage {
  top: calc(100vh - 174px); }

.safari:not(.mobile) .modalidades.team-all .bg-video-container.fixed {
  height: 100vh;
  top: 0; }

.safari:not(.mobile) .modalidades.team-all .footer {
  top: auto; }

/*-------------------------------------------------------------------------
    =TEAM EACH ONE
-------------------------------------------------------------------------*/
.team.member {
  background-color: #151a1f; }

.team.member .content-main-wrapper {
  position: relative;
  width: 100%;
  height: 200vh;
  overflow: hidden;
  /*background: #151a1f url(/public/files/team/team-bg-test.jpg) repeat;*/
  background: #060606; }

/*@media screen and (max-height:1000px){
  .team.member .content-main-wrapper{ height: 250vh; }
}
@media screen and (max-height:800px){
  .team.member .content-main-wrapper{ height: 280vh; }
}*/
.no-borderimage .team.member .content-main-wrapper {
  height: 90vh; }

.team .modality-pic,
.team .modality-pic-wrapper,
.team .for3d {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0; }

.team .instruct-pic {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: none;
  min-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.no-csstransforms3d .team .instruct-pic {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 0; }

.team .filter .instruct-pic {
  /* FUNCIONA! mas na transicçao do flip ve-se rendering! */
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0'); }

/* Instrutor */
.team .instruct-info h2 {
  font: normal 74px/82px "s-Medium", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -1px; }

.team .instruct-info .grid-cell {
  position: relative;
  z-index: 20;
  background: transparent;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.no-csstransforms3d .team .instruct-info .grid-cell {
  padding-bottom: 200px; }

.team .instruct-info .rgt {
  /* no translate because txt gets blur */
  /*font: normal 46px/56px 'pEL', Helvetica, Arial, sans-serif;*/
  font: normal 400 60px/60px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase; }

.team .instruct-info .rgt p,
.team .instruct-info .list-modality {
  max-width: 430px;
  margin: 0 auto; }

.team .instruct-info .list-modality {
  position: relative;
  max-width: 310px;
  margin-top: 50px;
  text-align: center; }

.team .instruct-info .list-modality li {
  display: inline-block;
  margin: 0 11px 15px 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0); }

.team .instruct-info .list-modality .txt {
  display: block;
  position: absolute;
  left: -5555px; }

.team .instruct-info .name-modality {
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 100%;
  overflow: hidden;
  font: 12px/16px "p-Medium", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  /*Type cortado ajuste - Testar se n parte nada*/
  left: -7.5%;
  width: 115%; }

.team .instruct-info .name-modality .anim-txt-ud {
  display: inline-block; }

.team .center-stage {
  position: fixed;
  z-index: 50;
  top: auto;
  bottom: 77px;
  left: 50%;
  margin: 0 0 0 -47px; }

.js-fixed-all .team .center-stage {
  position: absolute;
  bottom: auto;
  top: calc(100vh - 171px); }

.no-borderimage .team .center-stage,
.no-borderimage .js-fixed-all .team .center-stage {
  position: absolute;
  bottom: 0; }

.team .instruct-info .center-stage .container {
  /* prevent inside animation to happen */
  display: none; }

.team .instruct-info.next,
.team .instruct-info.prev {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0; }

.team .instruct-info.next .vp-fullH,
.team .instruct-info.prev .vp-fullH {
  opacity: 0; }

/* =FORM team */
.team .form-team-member {
  position: relative;
  z-index: 20;
  margin-top: -17px;
  font: normal 400 49px/49px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: left;
  color: #59626c; }

.team .form-team-member p {
  font: normal 400 49px/49px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  padding: 0 0 10px 2px;
  color: #5c5857; }

.team .form-team-member p .align {
  min-width: 335px;
  display: inline-block; }

.team .form-team-member p .align-space {
  padding: 0 10px; }

.form-team-member label {
  display: inline-block;
  min-width: 335px;
  font: normal 400 48px/49px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  color: #5c5857; }

.form-team-member input.text {
  font: normal 400 48px/0 "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  line-height: 0;
  height: 55px;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  border-radius: 0;
  width: auto;
  min-width: 50px;
  /*    padding: 0 1px;
    margin: 0 5px;*/ }

.team .form-team-member .rgpd-wrapper .rgpd-text {
  font-family: "p-Regular"; }

.mobile .form-team-member input.text {
  height: auto;
  border: 0;
  border-bottom: 2px solid #fff;
  max-width: 280px;
  width: auto;
  min-width: 54px;
  padding: 0; }

.mobile .form-team-member input.text.phone {
  max-width: 135px; }

.no-borderimage .form-team-member input.text {
  line-height: 49px; }

.ie .form-team-member input.text {
  width: 235px; }

.ie .form-team-member input.text.phone {
  width: 135px; }

.ie .form-team-member input.text.email {
  width: 120px; }

.form-team-member input.text.erro {
  border-bottom: 2px solid #d51d37; }

.form-team-member select {
  font: normal 400 49px/49px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  background: transparent url(/public/imgs/icon-arrow-down.svg) no-repeat right 21px;
  border: 0;
  border-bottom: 2px solid transparent;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: none; }

.mobile .form-team-member select {
  padding: 0; }

.form-team-member select:hover {
  background: transparent url(/public/imgs/icon-arrow-down.svg) no-repeat right 19px; }

.form-team-member select option {
  text-align: center; }

.ie .form-team-member select option {
  color: #59626c; }

.form-team-member .like-selected {
  display: block; }

.team .form-team-member p.submit-form {
  margin: 41px 0 0 -1px;
  text-align: center; }

.team .form-team-member .btn-square-center {
  display: inline-block; }

.form-team-member .error input.text.error {
  border-color: #d51d37; }

.team .form-team-member .btn {
  opacity: 0.3;
  pointer-events: none; }

.team .form-team-member .btn.enable {
  opacity: 1;
  pointer-events: all; }

@media screen and (max-width: 1024px) {
  .team .form-team-member {
    margin: 10px; }
  .team .form-team-member p {
    font-size: 39px;
    line-height: 39px; }
  .form-team-member label,
  .team .form-team-member p .align {
    min-width: 295px; }
  .form-team-member label,
  .form-team-member input.text,
  .form-team-member select {
    height: 45px;
    font-size: 39px;
    line-height: 39px; }
  .mobile .form-team-member input.text {
    max-width: 173px; }
  .mobile .form-team-member input.text.phone {
    max-width: 102px; }
  .team .form-team-member p.submit-form {
    margin-top: 50px; } }

@media screen and (max-width: 767px) {
  .form-team-member label {
    display: block;
    text-align: center; }
  .form-team-member input,
  .form-team-member select {
    display: block;
    margin: 0 auto;
    text-align: center; }
  .team .form-team-member p .align,
  .team .form-team-member p .align-space {
    display: block;
    min-width: 0;
    text-align: center; }
  .team .form-team-member p.submit-form {
    margin: 50px; } }

@media screen and (max-height: 850px) {
  .team .instruct-pic {
    top: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  .team.member .content-main-wrapper {
    height: 250vh; } }

@media screen and (max-height: 667px) {
  .team.member .instruct-info {
    top: calc(50vh + 65px); }
  .team .instruct-info h2 {
    margin-bottom: 20px; }
  .team.member .instruct-info .grid-cell {
    height: auto; }
  .team.member .center-stage {
    position: relative;
    bottom: auto;
    margin: 50px 0 0 -47px; } }

@media screen and (max-width: 414px) {
  .team.member .instruct-info {
    top: 60vh; }
  .team.member .content-main-wrapper {
    height: 960px; }
  .team .instruct-pic {
    top: 40px;
    min-width: inherit;
    min-height: inherit;
    width: inherit;
    height: 100%; } }

@media screen and (max-width: 320px) {
  .team.member .instruct-info {
    top: 58vh; } }

@media screen and (max-width: 320px) and (max-height: 450px) {
  .team.member .instruct-info {
    top: 50vh; } }

/* Horarios ???? */
/*-------------------------------------------------------------------------
    =INSCRICOES
    NOTES: because image and nav i need this z-index, and header without background;
-------------------------------------------------------------------------*/
.inscricoes {
  background: #151a1f; }

.inscricoes .header-section {
  position: relative;
  z-index: 20;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 80px 0 109px 0; }

/* =main nav */
.inscricoes .id-sec-full {
  padding-bottom: 95px;
  margin: 0 auto; }

.inscricoes .header-section {
  font: normal 32px/43px "pEL", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0px;
  max-width: none;
  margin: 0; }

#nav-inscricoes {
  position: relative; }

#nav-inscricoes button {
  font: normal 32px/43px "pR", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0px;
  max-width: none;
  margin: 0;
  color: #fff; }

#nav-inscricoes button span.text {
  position: relative;
  cursor: pointer;
  background: transparent url(/public/imgs/underline-dashed.png) repeat-x bottom right;
  background-size: auto 1px;
  white-space: nowrap; }

#nav-inscricoes button span.arrow {
  display: inline-block;
  width: 20px;
  height: 15px;
  margin-left: 1px;
  background: transparent url(/public/imgs/icon-arrow-down.svg) no-repeat;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: -ms-transform 0.15s ease-out;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

#nav-inscricoes button:hover span.arrow {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px); }

#nav-inscricoes-wrapper {
  position: absolute;
  left: 50%;
  top: 90px;
  width: 350px;
  height: 220px;
  margin-left: -175px;
  clip: rect(-28px, 350px, -28px, 0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: clip 0.22s ease-out, transform 0.25s ease-out;
  -ms-transition: clip 0.22s ease-out, transform 0.25s ease-out;
  transition: clip 0.22s ease-out, transform 0.25s ease-out; }

#nav-inscricoes-wrapper.open {
  clip: rect(-28px, 350px, 250px, 0px);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: clip 0.3s ease-out, transform 0.32s ease-out;
  -ms-transition: clip 0.3s ease-out, transform 0.32s ease-out;
  transition: clip 0.3s ease-out, transform 0.32s ease-out; }

#nav-inscricoes ul {
  display: block;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 90px;
  left: 0;
  top: 0;
  min-width: 350px;
  width: 100%;
  padding: 32px 48px 25px 48px;
  font: normal 20px/35px "pR", Helvetica, Arial, sans-serif;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0); }

#nav-inscricoes ul:after {
  bottom: 99%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(217, 52, 71, 0);
  border-bottom-color: #d93447;
  border-width: 26px;
  margin-left: -26px; }

#nav-inscricoes ul a:hover,
#nav-inscricoes ul a.on {
  font-family: "pM", Helvetica, Arial, sans-serif; }

#nav-inscricoes li {
  opacity: 0; }

#nav-inscricoes li.division {
  border-bottom: 1px solid #fff;
  margin-bottom: 12px;
  padding-bottom: 14px; }

.morph-svg {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.morph-svg svg path {
  fill: #d93447;
  stroke-width: 0; }

/** =form content **/
.inscricoes .content {
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
  min-height: 900px;
  /*need when I have no image*/
  padding: 0 86px 100px 86px;
  background: #b9afa9;
  /*overflow: hidden; =NO i need for the image*/ }

.inscricoes .content .grid-1 {
  position: relative;
  float: left;
  width: 33.33333%;
  text-align: center; }

.inscricoes .content .grid-2 {
  position: relative;
  float: right;
  width: 66.66666%; }

.inscricoes .grid-1 img {
  display: inline-block;
  margin: -150px 0 0 -25px; }

/** =FORMS **/
.inscricoes-form {
  display: none;
  position: relative;
  max-width: 1013px;
  margin-top: 90px; }

.inscricoes-form fieldset {
  margin-bottom: 90px;
  clear: both; }

.inscricoes-form fieldset.dados > div {
  overflow: hidden;
  padding-bottom: 18px; }

.inscricoes-form fieldset.dados p {
  width: 49%; }

.inscricoes-form h5 {
  font: normal 28px/28px "pR", Helvetica, Arial, sans-serif;
  color: #242a30;
  margin-bottom: 25px; }

.inscricoes-form fieldset p {
  position: relative;
  float: left; }

.inscricoes-form fieldset p:first-child {
  margin-right: 13px; }

.inscricoes-form .info-price {
  padding-right: 5px;
  color: #5f5e5e;
  font: normal 15px/64px "pR", Helvetica, Arial, sans-serif; }

.inscricoes-form .selected .info-price {
  color: #beb5b0; }

.inscricoes-form .info-price em {
  display: none; }

.inscricoes-form input.text {
  /*width: 498px;*/
  width: 100%;
  height: 64px;
  padding: 0 30px;
  font: normal 20px/64px "pR", Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
  color: #242a30;
  background-color: #ada6a2;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border 0.16s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.inscricoes-form input.text:focus {
  border-left: 8px solid #242a30; }

/* =radio */
.inscricoes-form input[type="radio"] {
  position: absolute;
  right: 31px;
  top: 19px;
  z-index: -1; }

.inscricoes-form .inputType-radio {
  position: relative;
  float: left;
  /*width: 498px;*/
  width: 49%;
  height: 64px;
  padding: 0 27px;
  background-color: #ada6a2;
  color: #242a30;
  font: normal 22px/58px "pR", Helvetica, Arial, sans-serif;
  cursor: pointer;
  text-align: right;
  overflow: visible;
  border: 3px solid #ada6a2; }

.inputType-radio.start {
  margin-right: 13px; }

.inputType-radio.selected {
  background-color: #242a30;
  color: #c4bcb7;
  border: 3px solid #242a30;
  cursor: default;
  pointer-events: none; }

.periocidade .inputType-radio.selected {
  background-color: #ada6a2;
  color: #242a30;
  border: 3px solid #242a30; }

.inputType-radio label {
  cursor: pointer;
  float: left; }

.periocidade .selected .info-price {
  color: #5f5e5e; }

/* =radio drop down */
.inscricoes-form .column .inputType-radio {
  float: none;
  width: 100%;
  margin-bottom: 8px; }

.inscricoes-form .column {
  position: relative;
  float: left;
  width: 49%;
  height: auto; }

.inscricoes-form .column.start {
  margin-right: 13px; }

.inscricoes-form .periocidade .inputType-radio {
  margin-bottom: 2px; }

/* =form interactividade */
.planos .periocidade {
  display: none; }

.planos .main-selection[data-option="mensal-selected"] .mensal,
.planos .main-selection[data-option="sem-selected"] .sem {
  display: inline; }

.planos .main-selection[data-option="anual-selected"] .anual,
.planos .main-selection[data-option="com-selected"] .com {
  display: inline; }

/* =tooltip */
.info-extra {
  position: relative;
  display: inline-block; }

.inscricoes-form fieldset .info-tooltip {
  display: none;
  position: absolute;
  left: -114px;
  top: -180px;
  float: none;
  min-height: 150px;
  width: 250px;
  padding: 20px 20px;
  background-color: #242a30;
  font: normal 14px/18px "pR", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #beb5b0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0); }

.inscricoes-form fieldset .info-tooltip .wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center; }

.info-tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #242a30;
  border-width: 26px;
  margin-left: -26px; }

/* =svg */
.inscricoes-form .icon-svg-auto {
  position: relative;
  top: 5px;
  width: 26px;
  height: 26px;
  margin-left: 5px; }

.inscricoes-form .icon-svg-auto path,
.inscricoes-form .icon-svg-auto circle,
.inscricoes-form .periocidade .selected .icon-svg-auto path,
.inscricoes-form .periocidade .selected .icon-svg-auto circle {
  fill: #242a30; }

.inscricoes-form .icon-svg-auto polyline,
.inscricoes-form .periocidade .selected .icon-svg-auto polyline {
  stroke: #242a30; }

.inscricoes-form .selected .icon-svg-auto path,
.inscricoes-form .selected .icon-svg-auto circle {
  fill: #beb5b0; }

.inscricoes-form .selected .icon-svg-auto polyline {
  stroke: #beb5b0; }

.inscricoes-form .icon-svg-auto path.transp {
  fill: #beb5b0; }

.inscricoes-form .selected .icon-svg-auto path.transp {
  fill: #242a30; }

.icon-svg-auto .check-anim {
  stroke-dasharray: 17;
  stroke-dashoffset: 18;
  transition: stroke-dashoffset 0.1s ease-out; }

.selected .icon-svg-auto .check-anim {
  stroke-dashoffset: 0; }

.icon-svg-auto.info {
  cursor: help; }

/*.icon-svg-auto.info .info-anim{
    transform: translateY(0);
}
.icon-svg-auto.info:hover .info-anim{
    transform: translateY(-1px);
}*/
.inscricoes-form ::-webkit-input-placeholder {
  color: #858180; }

.inscricoes-form ::-moz-placeholder {
  color: #858180; }

.inscricoes-form :-ms-input-placeholder {
  color: #858180; }

.inscricoes-form input:-moz-placeholder {
  color: #858180; }

/* =table */
.table-inscricoes {
  position: relative;
  width: 100%;
  font: normal 22px/22px "pR", Helvetica, Arial, sans-serif;
  color: #242a30; }

.table-inscricoes thead {
  display: none; }

.table-inscricoes tr.total {
  border-top: 3px solid #242a30;
  font: normal 26px/22px "pB", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.table-inscricoes tr.total td {
  padding-top: 25px; }

.table-inscricoes tr.last td {
  padding-bottom: 25px; }

.table-inscricoes td {
  padding: 10px 4px;
  vertical-align: middle; }

.table-inscricoes td:last-child {
  text-align: right; }

.table-inscricoes tr.total .msg-error {
  display: none; }

.table-inscricoes tr.total.error .msg-error {
  display: block;
  color: #d93447; }

.table-inscricoes tr.total.error .all-ok {
  display: none; }

/* =submit */
.inscricoes-form .end {
  position: relative;
  width: 100%;
  margin: 70px 0 30px 0;
  text-align: center;
  font: normal 15px/22px "pR", Helvetica, Arial, sans-serif;
  color: #5f5e5e;
  display: none;
  /*Hide until have information to show*/ }

.inscricoes-form .end .btn {
  margin: 0 auto 35px auto; }

.ie .inscricoes-form .end .btn {
  display: block; }

/*-------------------------------------------------------------------------
    =HOME SLIDESHOW
    NOTE: responsive given by a transparent gif = same size of pics;
-------------------------------------------------------------------------*/
.slideshow img {
  height: auto;
  width: 100%;
  min-height: 300px; }

.slideshow-pic {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 300px;
  overflow: hidden; }

.slideshow-pic li {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden; }

.slideshow-pics li img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto; }

.slideshow-pic li.on {
  z-index: 0; }

/* =arrow */
.slideshow-arrows {
  position: absolute;
  z-index: 10;
  left: 37px;
  bottom: 24px; }

.slideshow-arrows.rgt {
  right: 37px;
  left: auto; }

.slideshow-arrows > span {
  display: inline-block;
  width: 37px;
  height: 35px;
  border: 2px solid #fff;
  background: transparent url(/public/imgs/icon-slideshow-arrow.svg) no-repeat 10px 50%;
  background-size: 15px 19px;
  text-indent: -5555px;
  cursor: pointer;
  margin-left: -5px;
  -webkit-transition: background-position 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: background-position 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: background-position 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.slideshow-arrows > span:hover {
  background-position: 15px 50%; }

.slideshow-arrows-prev {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1); }

/*-------------------------------------------------------------------------
    =HORARIOS
-------------------------------------------------------------------------*/
.horarios .header {
  position: relative;
  z-index: 100;
  height: 50%;
  height: 50vh;
  text-align: center;
  background-color: #252322; }

.horarios .header p {
  max-width: 767px;
  padding: 0 40px;
  font: normal 26px/36px "p-Light", Helvetica, Arial, sans-serif; }

.horarios .header::after {
  content: "";
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 50%;
  width: 2px;
  height: 155px;
  background-color: #ffffff;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }

.horarios .content-horarios p.btn-show {
  display: none; }

.horarios .content-horarios.open .gym-name::before,
.horarios .content-horarios.open .gym-name::after,
.modalidades .content-horarios.open .gym-name::before,
.modalidades .content-horarios.open .gym-name::after {
  display: none; }

@media screen and (max-width: 1322px) {
  /*.content-horarios.open .gym-column.open {padding: 75px 40px;}*/
  .table-horarios td,
  .table-horarios th {
    min-width: 100px; }
  .table-horarios-scroll .table-horarios td:first-child,
  .table-horarios-scroll .table-horarios th:first-child {
    width: 90px;
    min-width: 90px;
    max-width: 90px; }
  .table-horarios td .txt {
    word-break: break-all; } }

@media screen and (max-width: 1260px) {
  .content-horarios .gym-name {
    font-size: 62px; } }

@media screen and (max-width: 1200px) {
  .content-horarios .gym-name {
    padding: 0 20px; }
  .content-horarios .icon-horarios {
    display: none; }
  .content-horarios .open h3 {
    text-align: center; }
  .content-horarios .open h3 .city {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  .table-horarios-wrapper .nav-table {
    width: 100%;
    right: auto;
    top: 30px;
    text-align: center; }
  .table-horarios {
    margin-top: 80px; }
  .table-horarios-scroll {
    overflow-x: scroll;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    width: 100%; }
  .content-horarios .btn-close {
    top: -126px; }
  .table-horarios td,
  .table-horarios th {
    min-width: 112px; }
  .content-horarios.open .gym-column.open {
    padding: 80px; }
  .content-horarios .btn-close {
    right: -57px; } }

@media screen and (max-width: 767px) {
  .table-horarios-wrapper .nav-table {
    top: 40px; }
  .content-horarios .btn-close {
    right: 20px; } }

@media screen and (max-width: 566px) {
  .horarios .header p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px;
    line-height: 32px; }
  .horarios .header::after {
    height: 135px; } }

@media screen and (max-width: 414px) {
  .content-horarios .gym-name {
    font-size: 51px; }
  .gym-name.yellow::before,
  .gym-name.orange::before,
  .gym-name.white::before {
    top: -70px; }
  .gym-name.yellow::after,
  .gym-name.orange::after,
  .gym-name.white::after {
    top: 70px; }
  .horarios .header::after {
    height: 105px; }
  .nav-table li {
    margin-top: 10px; }
  .nav-table li:last-child {
    margin-top: 10px; }
  .horarios .table-horarios-wrapper .nav-table {
    top: 20px;
    padding: 0 20px; } }

@media screen and (max-width: 560px) {
  .content-horarios .gym-name {
    font-size: 12vw; } }

@media screen and (max-width: 320px) {
  .nav-table li:nth-child(2) {
    margin-right: 0; } }

@media screen and (max-height: 775px) {
  .horarios .header {
    height: 388px; }
  .horarios .header::after {
    height: 105px; } }

/*-------------------------------------------------------------------------
    =RGPD
-------------------------------------------------------------------------*/
.corporate.rgpd .header h3 {
  display: block;
  margin-left: -4px;
  margin-top: 16px;
  font: normal 400 58px/68px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin: 0 auto; }

.corporate.rgpd h2 {
  font-size: 28px;
  margin: 20px 0 10px 0;
  font-family: "p-Medium", Helvetica, Arial, sans-serif; }

.corporate.rgpd p {
  font: 400 16px/34px p-Light, Helvetica, Arial, sans-serif !important; }

.corporate.rgpd li {
  font: 400 16px/34px p-Light, Helvetica, Arial, sans-serif !important;
  /* margin-left: 10px; */
  margin: 20px 20px; }

/*-------------------------------------------------------------------------
    =CORPORATE
-------------------------------------------------------------------------*/
.corporate {
  background-color: #1f202b; }

.corporate .header {
  position: relative;
  z-index: 100;
  padding: 220px 0 180px 0;
  background-color: #1f202b; }

.corporate .header .block-content {
  padding-left: 40px;
  padding-right: 40px; }

.corporate .header .grid-cell:first-of-type {
  width: 20%;
  margin-top: -20px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.corporate .header .grid-cell:nth-of-type(2) {
  width: 80%; }

.corporate .header svg {
  display: block; }

.corporate .header p {
  font: normal 22px/38px "p-Light", Helvetica, Arial, sans-serif;
  margin-bottom: 20px; }

.corporate .header h3 {
  display: inline-block;
  margin-left: -4px;
  margin-top: 16px;
  font: normal 400 38px/48px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0; }

.corporate-ginasios {
  min-height: 100%;
  min-height: 100vh; }

.corporate-ginasios > .grid-cell {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.corporate .content-2c-fullH-gym .visual a {
  position: relative;
  max-width: 100%;
  margin: 0 auto; }

.corporate .content-2c-fullH-gym .visual {
  overflow: hidden; }

.corporate .content-2c-fullH-gym .visual .block-bg-cover {
  will-change: transform;
  -webkit-transform: scale(1) translate3d(0, 0, 0);
  transform: scale(1) translate3d(0, 0, 0);
  /*-webkit-transition: -webkit-transform .7s cubic-bezier(.17,.86,.28,.98); original*/
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.86, 0.28, 0.98);
  transition: transform 0.6s cubic-bezier(0.17, 0.86, 0.28, 0.98); }

.corporate .content-2c-fullH-gym .visual.over .block-bg-cover {
  /*-webkit-transform: scale(1.05) translate3d(0,0,0); original*/
  -webkit-transform: scale(1.2) translate3d(0, 0, 0);
  transform: scale(1.2) translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 8s cubic-bezier(0.04, 0.73, 0, 1.01);
  transition: transform 8s cubic-bezier(0.04, 0.73, 0, 1.01); }

.mobile .corporate .content-2c-fullH-gym .visual .block-bg-cover,
.mobile .corporate .content-2c-fullH-gym .visual.over .block-bg-cover {
  -webkit-transform: none;
  transform: none; }

/* exceptions for gym name links */
.corporate .gym-name {
  line-height: 122px; }

.corporate .gym-name::before {
  top: -78px; }

.corporate .gym-name::after {
  top: 122px; }

.corporate-ginasios-wrapper {
  position: relative;
  height: calc(100vh - 250px); }

.corporate-ginasios .block-bg-cover {
  height: calc(100vh - 250px);
  opacity: 0.7; }

.corporate-funcionamento {
  background-color: #1f202b; }

.corporate-funcionamento .block-content {
  padding: 180px 40px 0 40px; }

.corporate-funcionamento .grid-cell:first-of-type {
  width: 20%;
  margin-top: 6px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.corporate-funcionamento .grid-cell:nth-of-type(2) {
  width: 80%; }

.corporate-funcionamento svg {
  display: block;
  margin-left: 38px; }

html[lang="en"] .corporate-funcionamento svg {
  display: block;
  margin-left: 55px; }

.corporate-funcionamento p {
  font: normal 22px/38px "p-Light", Helvetica, Arial, sans-serif;
  margin-bottom: 20px; }

.corporate-funcionamento h3 {
  display: inline-block;
  margin-top: 16px;
  font: normal 400 38px/48px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0; }

.corporate-parcerias {
  background-color: #1f202b; }

.corporate-parcerias .block-content {
  padding: 110px 40px; }

.corporate-parcerias > .grid-cell:first-of-type {
  width: 188px;
  margin-top: 4px;
  text-align: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

/*.corporate-parcerias .grid-cell:nth-of-type(2){ padding-left: 40px; }*/
.corporate #form-parceiro {
  height: auto; }

.corporate-parcerias p {
  font: normal 22px/38px "p-Light", Helvetica, Arial, sans-serif;
  margin-bottom: 20px; }

.corporate-parcerias > .grid-cell:first-of-type h3 {
  margin-top: 16px; }

.corporate-parcerias h3 {
  font: normal 400 38px/48px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0; }

.corporate-parcerias .tabs nav {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.corporate-parcerias .tabs:after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 90px;
  width: 400px;
  z-index: 1;
  pointer-events: none;
  background: -webkit-linear-gradient(right, #1f202b, rgba(31, 32, 43, 0));
  background: linear-gradient(to left, #1f202b, rgba(31, 32, 43, 0));
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s; }

/*.corporate-parcerias .tabs-list {
  width: 652px;
}*/
.corporate-parcerias .tabs-list.fixed {
  position: fixed;
  top: 79px;
  width: 20%; }

.corporate-parcerias .tabs > div {
  width: 20%; }

.corporate-parcerias .tabs-list li {
  display: block;
  font: normal 400 40px/40px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  padding: 25px 0 25px 25px;
  cursor: pointer; }

.corporate-parcerias .tabs-list li.on {
  color: #ffd100; }

.corporate-parcerias .tabs-list li.on,
.corporate-parcerias .tabs-content {
  background-color: #1a1b22; }

.corporate-parcerias .tabs-content {
  padding: 17px 50px;
  display: none; }

.corporate-parcerias .tabs-content.on {
  display: block; }

.corporate-parcerias .tabs-content li {
  height: auto;
  padding: 35px 0;
  border-bottom: 1px solid rgba(72, 78, 85, 0.2); }

.corporate-parcerias .tabs-content li:last-of-type {
  border-bottom-color: transparent; }

.corporate-parcerias .tabs-content li > .grid-cell:nth-child(2) {
  max-width: 80%; }

.corporate-parcerias .tabs-content li > .grid-cell:first-of-type {
  width: 20%;
  max-width: 20%;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.corporate-parcerias .tabs-content li > .grid-cell:last-of-type {
  padding-left: 40px; }

.corporate-parcerias .tabs-content h3 {
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0;
  width: 100%;
  max-width: 100%;
  text-align: left; }

.corporate-parcerias .tabs-content p {
  font-size: 14px;
  line-height: 24px;
  color: #9d948e;
  margin: 10px 0; }

.corporate-parcerias .tabs-content p.parceria-com {
  font: normal 400 14px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin: 20px 0 0 0;
  text-transform: uppercase;
  color: #ffffff; }

.corporate-parcerias .tabs-content p.parceria-com span {
  color: #9d948e; }

.corporate-parcerias .tabs-content .tabs-content-text-wrapper {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: center; }

.corporate-parceiro {
  position: relative;
  padding: 120px 40px; }

@media screen and (max-width: 1412px) {
  .corporate .corporate-ginasios-wrapper {
    height: 50vh; }
  .corporate .corporate-ginasios .block-bg-cover {
    height: 50vh; }
  .corporate .grid-cell.corporate-ginasios-info-horario,
  .corporate .grid-cell.corporate-ginasios-info-contactos {
    min-height: auto;
    margin-bottom: 20px; } }

@media screen and (max-width: 1024px) {
  .corporate .grid {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .corporate-parcerias .tabs-content li.grid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .corporate .header {
    padding: 160px 0 100px 0; }
  .corporate-funcionamento .block-content {
    padding: 100px 40px; }
  .corporate .corporate-ginasios-wrapper {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .corporate .block-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .corporate-parcerias .block-content {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .corporate .header h3,
  .corporate-funcionamento h3,
  .corporate-parcerias > .grid-cell:first-of-type h3 {
    margin-bottom: 40px;
    margin-left: 0; }
  .corporate-parcerias .block-content {
    padding-top: 0; }
  .corporate .corporate-ginasios-wrapper {
    height: 70vh; }
  .corporate .corporate-ginasios .block-bg-cover {
    height: 70vh; }
  .corporate-parcerias .tabs-content li {
    height: auto; }
  .corporate-funcionamento svg {
    display: block;
    margin-left: 40px; } }

@media screen and (max-width: 1023px) {
  .corporate .header svg,
  .corporate-funcionamento svg {
    display: block;
    margin: 0 auto; }
  .corporate-parcerias .grid-cell h3 {
    display: block;
    margin-bottom: 40px;
    margin-left: 0; }
  .corporate .header .grid-cell:nth-of-type(2),
  .corporate-funcionamento .grid-cell:nth-of-type(2) {
    width: 100%; }
  .corporate .header .grid-cell:first-of-type,
  .corporate-funcionamento .grid-cell:first-of-type {
    margin-top: 0;
    text-align: center; }
  .corporate-parcerias .tabs:after {
    display: block; }
  .corporate-parcerias .block-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .corporate-parcerias .tabs .grid-cell-1of5 {
    max-width: 100%;
    width: 100%; }
  .corporate-parcerias .tabs-list li {
    display: inline-block;
    padding: 25px; }
  .corporate-parcerias .tabs-list {
    min-width: 125%; } }

@media screen and (max-width: 840px) {
  .corporate-parcerias .tabs-list {
    min-width: 150%; } }

@media screen and (max-width: 712px) {
  .corporate-parcerias .tabs-list {
    min-width: 175%; } }

@media screen and (max-width: 640px) {
  .corporate-parcerias .tabs-content li.grid {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .corporate-parcerias .tabs-content li > .grid-cell:last-of-type {
    padding-left: 0;
    max-width: 100%;
    margin-top: 20px; }
  .corporate-parcerias .tabs-list {
    min-width: 200%; }
  .corporate-parcerias .tabs:after {
    width: 250px; } }

@media screen and (max-width: 480px) {
  .corporate-parcerias .tabs-list {
    min-width: 225%; }
  .corporate-parcerias .tabs:after {
    width: 150px; } }

@media screen and (max-width: 566px) {
  .corporate .header {
    padding-top: 100px; }
  .corporate .corporate-ginasios h3 {
    padding-left: 20px;
    padding-right: 20px; }
  .corporate .header p,
  .corporate-funcionamento p {
    font-size: 18px;
    line-height: 32px; }
  .corporate .header,
  .corporate-parcerias .block-content {
    padding-bottom: 40px; }
  .corporate .header .block-content {
    padding-left: 20px;
    padding-right: 20px; }
  .corporate-funcionamento .block-content {
    padding: 70px 20px 40px 20px; }
  .corporate-parcerias .tabs-content {
    padding-left: 34px;
    padding-right: 34px; }
  .corporate-parceiro {
    padding: 0; }
  .corporate-parceiro .block-bg-cover {
    display: none; }
  .corporate-parcerias .block-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0; }
  .corporate-parcerias .grid-cell-1of6 {
    padding-left: 20px;
    padding-right: 20px; }
  .corporate-parcerias .tabs-content li.grid > .grid-cell:first-of-type {
    padding-left: 0;
    padding-right: 0; }
  .corporate .header svg {
    height: 64px; }
  .corporate-funcionamento svg {
    height: 54px; }
  .corporate .header h3,
  .corporate-funcionamento h3,
  .corporate-parcerias .grid-cell-1of6 h3 {
    margin: 20px 0;
    font-size: 32px; }
  .corporate-parcerias .tabs-content h3 {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 20px; }
  .corporate-parcerias .tabs-list li {
    font-size: 32px;
    padding: 15px 25px; }
  .corporate-parcerias .tabs:after {
    height: 70px; } }

@media screen and (max-width: 414px) {
  .corporate-parcerias .tabs-content li.grid > .grid-cell:first-of-type {
    display: none; }
  .corporate-parcerias .tabs-content li > .grid-cell:last-of-type {
    margin-top: 0; }
  .corporate-parcerias .tabs-list li {
    font-size: 32px; }
  .corporate-parcerias .tabs:after {
    width: 137px; }
  .corporate-parcerias .tabs-list {
    min-width: 260%; } }

@media screen and (max-width: 320px) {
  .corporate-parcerias .tabs-list li {
    padding: 15px 20px;
    font-size: 30px; }
  .corporate-parcerias .tabs:after {
    height: 70px; } }

/* FORM */
.rgpd-wrapper input {
  opacity: 0;
  visibility: hidden;
  position: absolute !important; }

.rgpd-wrapper .check-wrapper {
  position: relative; }

.rgpd-wrapper .check-wrapper:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 2px solid #ffffff;
  top: 0;
  left: 0;
  pointer-events: none; }

.rgpd-wrapper {
  position: relative;
  margin: 20px auto 0 auto;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  color: #ffffff;
  font-size: 15px;
  text-align: left; }

#form-visita .rgpd-wrapper {
  margin-top: 40px; }

.rgpd-wrapper .rgpd-text,
.rgpd-wrapper .rgpd-text a {
  color: #ffffff;
  font-size: 15px; }

.rgpd-wrapper svg {
  position: relative;
  width: 15px;
  height: 15px;
  left: 4px;
  top: -3px;
  opacity: 0;
  cursor: pointer; }

.rgpd-wrapper.active svg {
  opacity: 1; }

.rgpd-wrapper .rgpd-text {
  margin-left: 15px; }

#form-parceiro {
  position: relative;
  margin: 0 auto;
  max-width: 750px;
  width: 100%;
  height: 750px;
  padding: 55px 55px;
  background: rgba(245, 181, 14, 0.97);
  z-index: 10; }

#form-promo {
  background: #ffb100;
  height: 550px; }

#form-promo {
  width: 100%; }

#form-promo .form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0); }

#form-parceiro h3,
#form-promo h3,
.promo-lightbox h3 {
  width: 100%;
  max-width: 100%;
  margin: 15px 0 25px 0;
  font: normal 56px/56px "s-Medium", Helvetica, Arial, sans-serif;
  letter-spacing: -1px; }

#form-parceiro p,
#form-promo p {
  font: 15px/24px "p-Regular", Helvetica, Arial, sans-serif;
  color: #906f25;
  text-align: center; }

#form-parceiro .input-border,
#form-promo .input-border {
  display: block;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 53px;
  margin: 30px auto 0 auto;
  border-bottom: 3px solid #ffffff; }

#form-parceiro .input-border:before,
#form-parceiro .input-border:after,
#form-promo .input-border:before,
#form-promo .input-border:after {
  display: block;
  position: absolute;
  width: 3px;
  height: 18px;
  bottom: 0;
  content: "";
  background: #ffffff; }

#form-parceiro .input-border:before,
#form-promo .input-border:before {
  left: 0; }

#form-parceiro .input-border:after,
#form-promo .input-border:after {
  right: 0; }

#form-parceiro input,
#form-promo input {
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  font: 18px/50px "p-Medium", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ffffff;
  background: transparent; }

::-webkit-input-placeholder {
  color: white; }

:-moz-placeholder {
  color: white; }

::-moz-placeholder {
  color: white; }

:-ms-input-placeholder {
  color: white; }

input:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

input:focus:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5); }

input:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5); }

input:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#form-parceiro .space-button,
#form-promo .space-button {
  margin-top: 55px; }

#form-parceiro button,
#form-promo button {
  color: #ffffff;
  display: block;
  margin: 0 auto; }

#form-parceiro.sent,
#form-promo.sent {
  height: auto; }

#form-parceiro.sent .input-border,
#form-parceiro.sent .radio-buttons,
#form-promo.sent .input-border,
#form-promo.sent .radio-buttons {
  display: none; }

#form-parceiro.sent button,
#form-promo.sent button {
  pointer-events: none;
  background: #ffffff;
  color: #f5b50e; }

/*#form-parceiro .error{color: #B82020; margin-top: 15px; margin-bottom: -20px;}*/
#form-parceiro .input-border.error::before,
#form-parceiro .input-border.error::after,
#form-promo .input-border.error::before,
#form-promo .input-border.error::after {
  background: #d51d37; }

#form-parceiro .input-border.error,
#form-promo .input-border.error {
  border-bottom: 3px solid #d51d37; }

#form-parceiro .radio-buttons,
#form-promo .radio-buttons {
  list-style: none;
  margin: 0;
  text-align: center;
  width: 100%;
  margin-top: 30px; }

#form-parceiro .input-border.last {
  margin-bottom: 30px; }

#form-parceiro .radio-buttons.hide-labels {
  display: none; }

#form-parceiro .radio-buttons li,
#form-promo .radio-buttons li {
  display: inline-block;
  font: 18px/50px "p-Medium", Helvetica, Arial, sans-serif;
  padding: 0 10px;
  margin: 0 20px;
  cursor: pointer;
  text-transform: uppercase; }

#form-parceiro .radio-buttons li .radio-selection,
#form-promo .radio-buttons li .radio-selection {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  border: 3px solid #fff;
  top: 5px;
  left: -10px; }

#form-parceiro .radio-buttons li .radio-selection svg,
#form-promo .radio-buttons li .radio-selection svg {
  position: absolute;
  width: 20px;
  top: -4px;
  left: 4px;
  z-index: 2;
  stroke-dasharray: 28;
  stroke-dashoffset: 28; }

#form-parceiro .radio-buttons li .radio-selection.selected svg,
#form-promo .radio-buttons li .radio-selection.selected svg {
  animation: dash 0.15s linear forwards; }

#form-parceiro .radio-buttons li .radio-selection.unselected svg,
#form-promo .radio-buttons li .radio-selection.unselected svg {
  animation: undash 0.15s linear forwards; }

@keyframes dash {
  from {
    stroke-dashoffset: 28; }
  to {
    stroke-dashoffset: 0; } }

@keyframes undash {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: 28; } }

#form-parceiro .radio-buttons li .radio-selection:after,
#form-promo .radio-buttons li .radio-selection:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 0px;
  height: 7px;
  background-color: rgba(245, 181, 14, 0.97);
  z-index: 1;
  -webkit-transform: rotateZ(-49deg);
  transform: rotateZ(-49deg);
  -webkit-transition: width 0.1s linear;
  transition: width 0.1s linear; }

#form-parceiro .radio-buttons li .radio-selection.selected:after,
#form-promo .radio-buttons li .radio-selection.selected:after {
  width: 20px; }

#form-parceiro .radio-buttons li.active .radio-selection:after,
#form-promo .radio-buttons li.active .radio-selection:after {
  opacity: 1; }

#form-parceiro .input-hidden,
#form-promo .input-hidden {
  display: none; }

@media screen and (max-width: 830px) {
  #form-parceiro #form-promo {
    width: auto;
    height: auto; } }

@media screen and (max-width: 566px) {
  #form-parceiro,
  #form-promo {
    padding: 60px 40px;
    background: #f5b50e;
    width: auto;
    height: auto; }
  #form-promo {
    height: 750px; }
  #form-parceiro h3,
  #form-promo h3,
  .promo-lightbox h3 {
    font-size: 42px;
    line-height: 1;
    margin-top: 0; }
  #form-parceiro .radio-buttons li,
  #form-promo .radio-buttons li {
    padding: 0; } }

@media screen and (max-width: 414px) {
  #form-promo,
  #form-parceiro {
    width: auto;
    height: auto; }
  #form-promo {
    height: 600px; }
  #form-parceiro .radio-buttons li .radio-selection svg,
  #form-promo .radio-buttons li .radio-selection svg {
    top: -5px; } }

.promo-lightbox .promo-thanks {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font: 26px/35px "p-Regular", Helvetica, Arial, sans-serif;
  text-align: center; }

/*-------------------------------------------------------------------------
    =RECRUTA
-------------------------------------------------------------------------*/
.page-toload.recruta header {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 100;
  height: 370px;
  padding: 0 40px;
  background-color: #feba24;
  text-align: center; }

.page-toload.recruta header h3 {
  max-width: 100%;
  font: normal 28px/48px "p-Light", Helvetica, Arial, sans-serif;
  letter-spacing: 0; }

.page-toload.recruta header h4 {
  font: normal 400 64px/64px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.page-toload.recruta .recruta-form-block h3 {
  padding-bottom: 45px;
  font: normal 28px/36px "p-Regular", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  color: #252322;
  text-transform: none;
  text-align: left; }

.page-toload.recruta .recruta-form-block {
  background-color: #c4bbb7; }

.page-toload.recruta .btn-arrow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-brown.svg) no-repeat center center;
  background-size: 26px auto; }

.page-toload.recruta .recruta-form .rgpd-wrapper .rgpd-text,
.page-toload.recruta .recruta-form .rgpd-wrapper .rgpd-text a {
  color: #858180; }

.page-toload.recruta .recruta-form .rgpd-wrapper .check-wrapper:before {
  border: 2px solid #858180; }

.page-toload.recruta .recruta-form .rgpd-wrapper {
  justify-content: flex-start;
  -webkit-justify-content: flex-start; }

.page-toload.recruta .btn-square-center {
  border: 3px solid #252322;
  color: #252322; }

.recruta-form .end .btn {
  opacity: 0.3;
  pointer-events: none; }

.recruta-form .end .enable {
  opacity: 1;
  pointer-events: all; }

.page-toload.recruta .btn-submit-anim.go {
  border-color: rgba(255, 255, 255, 0.1); }

.page-toload.recruta .btn-submit-anim.go-reset {
  border-color: #252322; }

.page-toload.recruta .btn-x::before,
.page-toload.recruta .btn-x::after {
  background-color: #252322; }

/*.page-toload.recruta .btn-square-center.loading.go {
  border: none;
  color: transparent;
}*/
.page-toload.recruta .btn-arrow .bg {
  background: #252322; }

/** =FORMS **/
.recruta-form {
  position: relative;
  max-width: 1013px;
  margin: 0 auto;
  padding: 120px 40px; }

.recruta-form > div {
  overflow: hidden;
  padding-bottom: 18px; }

.recruta-form p {
  width: 50%; }

.recruta-form p {
  position: relative;
  float: left; }

.recruta-form p:first-child {
  padding-right: 18px; }

.recruta-form input.text,
.recruta-form textarea {
  width: 100%;
  height: 64px;
  padding: 0 22px;
  font: normal 20px/64px "p-Light", Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
  color: #242a30;
  background-color: #ada6a2;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border 0.16s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.recruta-form textarea {
  height: auto;
  min-height: 130px;
  line-height: 34px;
  padding-top: 10px;
  resize: vertical; }

.recruta-form input.text:focus,
.recruta-form textarea:focus {
  border-left: 8px solid #242a30; }

.recruta-form .error input.text,
.recruta-form .error textarea,
.recruta-form .error .recruta-file-name {
  border-left: 8px solid #d51d37; }

.recruta-form ::-webkit-input-placeholder {
  color: #858180; }

.recruta-form ::-moz-placeholder {
  color: #858180; }

.recruta-form :-ms-input-placeholder {
  color: #858180; }

.recruta-form input:-moz-placeholder {
  color: #858180; }

.recruta-form input.text:focus::-webkit-input-placeholder {
  color: #242a30; }

.recruta-form input.text:focus::-moz-placeholder {
  color: #242a30; }

.recruta-form input.text:focus:-ms-input-placeholder {
  color: #242a30; }

.recruta-form input.text:focus input:-moz-placeholder {
  color: #242a30; }

.recruta-form textarea:focus::-webkit-input-placeholder {
  color: #242a30; }

.recruta-form textarea:focus::-moz-placeholder {
  color: #242a30; }

.recruta-form textarea:focus:-ms-input-placeholder {
  color: #242a30; }

.recruta-form textarea:focus input:-moz-placeholder {
  color: #242a30; }

.recruta-form .add-file {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
  width: 64px;
  height: 64px;
  background-color: #a19a97;
  cursor: pointer;
  -webkit-transition: background-color 0.26s ease-in-out;
  -ms-transition: background-color 0.26s ease-in-out;
  transition: background-color 0.26s ease-in-out; }

.recruta-form .recruta-file-name {
  float: left;
  width: calc(100% - 64px);
  padding: 0 22px;
  font: normal 20px/64px "p-Light", Helvetica, Arial, sans-serif;
  line-height: 64px;
  color: #858180;
  background-color: #ada6a2; }

/* =submit */
.recruta-form .end {
  position: relative;
  width: 100%;
  margin: 70px 0 0px 0;
  text-align: center;
  font: normal 15px/22px "p-Light", Helvetica, Arial, sans-serif;
  color: #5f5e5e;
  padding: 0; }

.recruta-form .end .btn {
  margin: 0 auto; }

.ie .recruta-form .end .btn {
  display: block; }

@media screen and (max-width: 566px) {
  .recruta-form p {
    width: 100%; }
  .recruta-form p:first-child {
    padding-right: 0; }
  .recruta-form p:nth-child(2) {
    padding-top: 18px; }
  .recruta-form {
    padding: 60px 20px; }
  .page-toload.recruta header {
    padding-top: 60px;
    padding-right: 20px;
    padding-left: 20px; }
  .page-toload.recruta header h4 {
    font-size: 54px;
    line-height: 1; }
  .recruta-form .end {
    margin-top: 40px; } }

@media screen and (max-width: 414px) {
  .page-toload.recruta header h3 {
    font-size: 22px; }
  .page-toload.recruta header h4 {
    font-size: 46px;
    line-height: 1; }
  .recruta-form input.text,
  .recruta-form textarea,
  .recruta-form .recruta-file-name {
    font-size: 18px; }
  .page-toload.recruta .recruta-form-block h3 {
    font-size: 24px; } }

/*-------------------------------------------------------------------------
    =GINASIOS ALL
-------------------------------------------------------------------------*/
.header-ginasios {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
  padding: 0;
  background-color: #252322;
  z-index: 100; }
  @media screen and (max-width: 768px) {
    .header-ginasios {
      height: 80vh; } }
  .header-ginasios .scroll-wrapper-horizontal {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    will-change: transform; }
    .header-ginasios .scroll-wrapper-horizontal .gym-column {
      position: relative;
      width: 45vw;
      height: 100%;
      overflow: hidden;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      flex-direction: column;
      -webkit-flex-direction: column;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition: width 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125);
      transition: width 0.3s cubic-bezier(0.175, 0.885, 0.22, 1.125); }
      @media screen and (max-width: 1080px) {
        .header-ginasios .scroll-wrapper-horizontal .gym-column {
          width: 85vw; } }
      .header-ginasios .scroll-wrapper-horizontal .gym-column h3 {
        pointer-events: none;
        transition: opacity 0.15s linear, transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
        -webkit-transition: opacity 0.15s linear, -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
        .header-ginasios .scroll-wrapper-horizontal .gym-column h3:before, .header-ginasios .scroll-wrapper-horizontal .gym-column h3:after,
        .header-ginasios .scroll-wrapper-horizontal .gym-column h3 .city {
          transition: transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05);
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
      .header-ginasios .scroll-wrapper-horizontal .gym-column .block-bg-cover {
        width: 100vw;
        overflow: visible;
        pointer-events: none;
        will-change: transform;
        -webkit-transform: translate3d(-40px, 0, 0);
        transform: translate3d(-40px, 0, 0);
        -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.17, 0.86, 0.3, 1);
        transition: transform 0.7s cubic-bezier(0.17, 0.86, 0.3, 1); }
        .header-ginasios .scroll-wrapper-horizontal .gym-column .block-bg-cover .element-cover {
          object-position: 0% 50%; }
        .header-ginasios .scroll-wrapper-horizontal .gym-column .block-bg-cover:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: #000;
          opacity: 0.2;
          -webkit-transition: opacity 0.15s linear;
          transition: opacity 0.15s linear; }
      .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit {
        position: absolute;
        top: calc(50% + 22vh);
        transform: translate3d(0, -50%, 0);
        -webkit-transform: translate3d(0, -50%, 0);
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        text-align: center; }
        @media screen and (max-height: 650px) {
          .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit {
            top: calc(50% + 25vh); } }
        .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit svg {
          width: 60px;
          height: 60px;
          display: block;
          margin: 0 auto; }
          @media screen and (max-height: 768px) {
            .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit svg {
              width: 55px;
              height: 55px; } }
        .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit .text-wrapper {
          display: block;
          font: normal 400 18px/18px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
          text-transform: uppercase;
          overflow: hidden; }
          .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit .text-wrapper .text {
            display: block;
            transform: translate3d(0, 100%, 0);
            -webkit-transform: translate3d(0, 100%, 0);
            transition: transform 0.5s cubic-bezier(0.17, 0.86, 0.31, 1.05);
            -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.17, 0.86, 0.31, 1.05); }
        @media (hover: hover) {
          .header-ginasios .scroll-wrapper-horizontal .gym-column .virtual-visit:hover .text-wrapper .text {
            transform: translate3d(0, 0%, 0);
            -webkit-transform: translate3d(0, 0%, 0); } }
      @media (hover: hover) {
        .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3 {
          transform: scale(1.05);
          -webkit-transform: scale(1.05); }
          .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3:before {
            -webkit-transform: translateX(-50%) translateY(8px);
            transform: translateX(-50%) translateY(8px); } }
    @media screen and (hover: hover) and (max-height: 768px) {
      .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3:before {
        -webkit-transform: translateX(-50%) translateY(8px) scale(0.8);
        transform: translateX(-50%) translateY(8px) scale(0.8); } }
      @media (hover: hover) {
          .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3:after {
            -webkit-transform: translateX(-50%) translateY(-8px);
            transform: translateX(-50%) translateY(-8px); } }
    @media screen and (hover: hover) and (max-height: 768px) {
      .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3:after {
        -webkit-transform: translateX(-50%) translateY(-8px) scale(0.8);
        transform: translateX(-50%) translateY(-8px) scale(0.8); } }
      @media (hover: hover) {
          .header-ginasios .scroll-wrapper-horizontal .gym-column .gym-link:hover h3 .city {
            -webkit-transform: translate3d(-50%, 8px, 0);
            transform: translate3d(-50%, 8px, 0); } }
      .header-ginasios .scroll-wrapper-horizontal .gym-column.js-gym-anime .block-bg-cover {
        -webkit-transform: translate3d(-30px, 0, 0);
        transform: translate3d(-30px, 0, 0); }
        .header-ginasios .scroll-wrapper-horizontal .gym-column.js-gym-anime .block-bg-cover:after {
          opacity: 0; }
      .header-ginasios .scroll-wrapper-horizontal .gym-column.lagrimas-health-club {
        left: 1px; }
      .header-ginasios .scroll-wrapper-horizontal .gym-column.leiria-health-club {
        right: 1px; }
  .header-ginasios .gym-btn {
    display: none; }
    .header-ginasios .gym-btn .bg-hover .btn-svg path {
      stroke: #feba24; }

.mobile .header-ginasios .gym-btn {
  display: block; }
  @media screen and (max-width: 767px) {
    .mobile .header-ginasios .gym-btn.prev {
      left: 20px; }
    .mobile .header-ginasios .gym-btn.next {
      right: 20px; } }

.mobile .header-ginasios .scroll-wrapper-horizontal .gym-column.lagrimas-health-club {
  left: auto; }

.mobile .header-ginasios .scroll-wrapper-horizontal .gym-column.leiria-health-club {
  right: auto; }

.header-ginasios .center-stage {
  position: absolute;
  z-index: 50;
  top: auto;
  bottom: 77px;
  left: 50%;
  margin: 0 0 0 -47px; }

@media screen and (max-height: 768px) {
  .header-ginasios h3::before,
  .header-ginasios h3::after {
    -webkit-transform: translateX(-50%) scale(0.8);
    -ms-transform: translateX(-50%) scale(0.8);
    transform: translateX(-50%) scale(0.8); }
  .header-ginasios h3 {
    font: normal 400 60px/74px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
    letter-spacing: 0.5px; }
  .header-ginasios h3.yellow::before {
    top: -80px; }
  .header-ginasios h3.yellow::after {
    top: 80px; }
  .header-ginasios h3 .city {
    font-size: 22px;
    top: -26px; }
  .header-ginasios .arrow-gyms {
    width: 104px;
    height: 50px;
    margin-top: 0; }
  .header-ginasios .btn-square-center {
    width: 84px;
    height: 84px; }
    .header-ginasios .btn-square-center .container {
      width: 82px;
      height: 82px; }
  .header-ginasios .center-stage {
    bottom: 50px; } }

@media screen and (max-height: 650px) {
  .header-ginasios .center-stage {
    bottom: 40px; }
  .header-ginasios .btn-square-center {
    width: 74px;
    height: 74px;
    font-size: 18px;
    line-height: 18px; }
    .header-ginasios .btn-square-center .container {
      width: 72px;
      height: 72px; } }

@media screen and (max-width: 767px) {
  .header-ginasios h3::before,
  .header-ginasios h3::after {
    -webkit-transform: translateX(-50%) scale(0.65);
    -ms-transform: translateX(-50%) scale(0.65);
    transform: translateX(-50%) scale(0.65); }
  .header-ginasios h3 {
    font-size: 51px; }
  .header-ginasios h3.yellow::before {
    top: -70px; }
  .header-ginasios h3.yellow::after {
    top: 70px; }
  .header-ginasios h3 .city {
    font-size: 20px;
    top: -22px; } }

@media screen and (max-width: 413px) {
  .header-ginasios h3 {
    font-size: 12vw; }
  .header-ginasios h3.yellow::before {
    top: -60px; }
  .header-ginasios h3.yellow::after {
    top: 60px; }
  .header-ginasios h3 .city {
    font-size: 20px;
    top: -11px; } }

.ginasios-recruta {
  padding: 120px 40px;
  background-color: #feba24;
  text-align: center; }

.ginasios-recruta > h3 {
  position: relative;
  display: inline;
  width: 100%;
  font: normal 400 42px/42px "s-Light", Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0; }

.ginasios-recruta h3 svg {
  position: relative;
  left: 5px;
  height: 33px;
  width: 150px; }

.ginasios-recruta ul {
  width: 1060px;
  margin: 80px auto 0 auto;
  text-align: left; }

.ginasios-recruta ul li {
  height: auto;
  padding: 45px;
  margin: 20px 0;
  background-color: #f5b317; }

.ginasios-recruta ul li:first-child {
  margin-top: 0; }

.ginasios-recruta ul li .grid-cell:first-child {
  padding-right: 45px; }

.ginasios-recruta ul li:hover {
  background-color: #ffffff;
  color: #f5b317; }

.ginasios-recruta ul li .grid-cell:nth-child(2) {
  width: 94px; }

.ginasios-recruta ul li:hover .btn {
  border-color: #f5b317;
  color: #f5b317; }

.ginasios-recruta ul li:hover .btn .bg {
  background-color: #f5b317; }

.ginasios-recruta ul li .btn-arrow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-bigwhite.svg) no-repeat center center; }

.ginasios-recruta ul li h3 {
  width: 100%;
  margin-bottom: 10px;
  display: inline;
  font: normal 400 36px/36px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0; }

.ginasios-recruta ul li p {
  margin-top: 20px;
  font: normal 400 16px/26px "p-Regular", Helvetica, Arial, sans-serif; }

@media screen and (max-width: 1140px) {
  .ginasios-recruta ul {
    width: 100%; } }

@media screen and (max-width: 1023px) {
  .ginasions-recruta-list .grid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .header-ginasios .center-stage {
    display: none; } }

@media screen and (max-width: 566px) {
  .ginasions-recruta-list .grid {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ginasios-recruta ul {
    margin-top: 60px; }
  .ginasios-recruta ul li .grid-cell:first-child {
    padding-right: 0; }
  .ginasios-recruta {
    padding: 60px 20px; }
  .ginasions-recruta-list a.btn {
    margin: 40px auto 0 auto; }
  .ginasios-recruta ul li h3 {
    font-size: 32px;
    line-height: 34px; } }

@media screen and (max-width: 414px) {
  .ginasios-recruta ul li {
    padding: 30px; }
  .ginasios-recruta h3 svg {
    left: 0; } }

@media screen and (max-width: 320px) {
  .ginasios-recruta h3 svg {
    left: 0; } }

/* =GINASIOS ALL HEADER DESKTOP */
/* note: cant use translate for bg cover because of object position */
.clipme {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%; }

.clipme.first {
  z-index: 10; }

.clipme.second {
  z-index: 5; }

.header-ginasios .gym-name-desk {
  /*-webkit-transition: -webkit-transform .35s cubic-bezier(0.175, 0.885, 0.220, 1.125);        */
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.15s linear;
  transition: transform 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.15s linear; }

.header-ginasios .second .gym-name-desk {
  left: 75%; }

.clipme .block-bg-cover {
  will-change: transform;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.17, 0.86, 0.3, 1);
  transition: transform 0.7s cubic-bezier(0.17, 0.86, 0.3, 1); }

.clipme.second .block-bg-cover {
  -webkit-transform: translate3d(40px, 0, 0);
  transform: translate3d(40px, 0, 0); }

.toclip {
  clip: rect(0, 50vw, 100vh, 0);
  -webkit-transition: clip 0.4s cubic-bezier(0.17, 0.86, 0.3, 1);
  transition: clip 0.4s cubic-bezier(0.17, 0.86, 0.3, 1); }

.clickall .gym-name-desk,
.clickall .virtual-visit,
.clickall .center-stage {
  opacity: 0; }

.clickall .scroll-wrapper-horizontal .gym-column {
  pointer-events: none;
  cursor: default; }
  .clickall .scroll-wrapper-horizontal .gym-column .block-bg-cover:after {
    opacity: 0; }

.hoverRgt .first .gym-name-desk {
  -webkit-transform: translate3d(-40%, -50%, 0);
  transform: translate3d(-40%, -50%, 0); }

.hoverRgt .second .gym-name-desk {
  -webkit-transform: translate3d(-40%, -50%, 0);
  transform: translate3d(-40%, -50%, 0); }

.hoverRgt .first .block-bg-cover {
  -webkit-transform: translate3d(-30px, 0, 0);
  transform: translate3d(-30px, 0, 0); }

.clickall.hoverRgt .first .block-bg-cover {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.hoverLft .first .gym-name-desk {
  -webkit-transform: translate3d(-60%, -50%, 0);
  transform: translate3d(-60%, -50%, 0); }

.hoverLft .second .gym-name-desk {
  -webkit-transform: translate3d(-60%, -50%, 0);
  transform: translate3d(-60%, -50%, 0); }

.hoverLft .second .block-bg-cover {
  -webkit-transform: translate3d(30px, 0, 0);
  transform: translate3d(30px, 0, 0); }

.clickall.hoverLft .second .block-bg-cover {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.clipme .block-bg-cover.overlay-darken::after {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

.hoverRgt .first .block-bg-cover.overlay-darken::after,
.hoverLft .second .block-bg-cover.overlay-darken::after {
  opacity: 0; }

/*-------------------------------------------------------------------------
    =GINASIOS EACH =GYM EACH
-------------------------------------------------------------------------*/
.ginasios:not(.ginasios-all) .virtual-visit {
  position: fixed;
  top: 0;
  right: 34px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  z-index: 1001;
  height: 72px; }
  @media screen and (max-width: 566px) {
    .ginasios:not(.ginasios-all) .virtual-visit {
      right: 20px; } }
  .ginasios:not(.ginasios-all) .virtual-visit svg {
    width: 45px;
    height: 45px;
    display: block;
    margin: 0 auto; }
  .ginasios:not(.ginasios-all) .virtual-visit .text-wrapper {
    display: block;
    font: normal 400 14px/14px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    overflow: hidden;
    margin-left: 10px; }
    @media screen and (max-width: 566px) {
      .ginasios:not(.ginasios-all) .virtual-visit .text-wrapper {
        display: none; } }
  @media (hover: hover) {
    .ginasios:not(.ginasios-all) .virtual-visit:hover .text-wrapper .text {
      transform: translate3d(0, 0%, 0);
      -webkit-transform: translate3d(0, 0%, 0); } }

.open-nav .ginasios .virtual-visit {
  opacity: 0;
  visibility: hidden; }

.ginasios .content-main-wrapper,
.ginasios .content-header {
  background-color: #252322; }

.ginasios .content-header {
  position: relative;
  z-index: 100;
  width: 100%; }

.ginasios .content-header .center-stage {
  /* tranform bug over on before */
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 95px;
  top: auto;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: bottom 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.2s linear;
  transition: bottom 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.2s linear; }

.ginasios .content-header .center-stage.other {
  bottom: auto;
  top: 155px;
  -webkit-transition: top 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.2s linear;
  transition: top 0.45s cubic-bezier(0.17, 0.86, 0.3, 1), opacity 0.2s linear; }

.ginasios .content-header .center-stage.go {
  opacity: 1;
  bottom: 75px; }

.ginasios .content-header .center-stage.other.go {
  bottom: auto;
  top: 135px; }

.ginasios .content-header .center-stage.other .gym-name.size38::after {
  display: none; }

.ginasios .content-header .center-stage.other .arrow-half-small-svg {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1); }

.ginasios .content-header .center-stage.other .btn:hover .btn-arrow-half.down {
  -webkit-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

.mobile .ginasios .content-header .center-stage.other {
  display: none; }

/* =FORM =VISITA */
.celas #form-visita {
  background: rgba(255, 209, 0, 0.97); }

.lagrimas #form-visita {
  background: rgba(255, 162, 0, 0.97); }

.leiria #form-visita {
  background: rgba(240, 219, 23, 0.97); }

.leiria #form-visita p {
  color: #606065; }

#form-visita {
  position: relative;
  margin: 0 auto;
  width: 700px;
  height: auto;
  padding: 80px 55px;
  z-index: 10; }

#form-visita h3 {
  width: 100%;
  max-width: 100%;
  margin: 15px 0 25px 0;
  font: normal 56px/56px "s-Medium", Helvetica, Arial, sans-serif;
  letter-spacing: -1px; }

#form-visita p {
  font: 15px/24px "p-Regular", Helvetica, Arial, sans-serif;
  color: #906f25;
  text-align: center; }

#form-visita .input-border {
  display: block;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 53px;
  margin: 30px auto 0 auto;
  border-bottom: 3px solid #ffffff; }

#form-visita .input-border::before,
#form-visita .input-border::after {
  display: block;
  position: absolute;
  width: 3px;
  height: 18px;
  bottom: 0;
  content: "";
  background: #ffffff; }

#form-visita .input-border::before {
  left: 0; }

#form-visita .input-border::after {
  right: 0; }

#form-visita input {
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  font: 18px/50px "p-Medium", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ffffff;
  background: transparent; }

#form-visita .space-button {
  margin-top: 55px; }

#form-visita button,
#form-parceiro button {
  color: #ffffff;
  display: block;
  margin: 0 auto;
  opacity: 0.4;
  pointer-events: none; }

#form-visita button.enable,
#form-parceiro button.enable {
  opacity: 1;
  pointer-events: all; }

#form-visita .sent {
  height: auto; }

#form-visita .sent .input-border,
#form-visita .sent .radio-buttons {
  display: none; }

#form-visita .sent button {
  pointer-events: none;
  background: #ffffff;
  color: #f5b50e; }

#form-visita .error {
  /*color: #d51d37;*/
  /*margin-top: 15px; margin-bottom: -20px;*/ }

#form-visita .input-border.error::before,
#form-visita .input-border.error::after {
  background: #d51d37; }

#form-visita .input-border.error {
  border-bottom: 3px solid #d51d37; }

@media screen and (max-width: 768px) {
  #form-visita {
    width: auto;
    height: auto; } }

@media screen and (max-width: 566px) {
  .ginasios .content-form-centerVH {
    padding: 0; }
  .ginasios .content-form-centerVH .block-bg-cover {
    display: none; }
  #form-visita {
    padding: 60px 20px;
    background: #f5b50e; }
  #form-visita h3 {
    font-size: 42px;
    line-height: 1;
    margin-top: 0; }
  .leiria .marcar-visita-leiria form#form-visita {
    background-color: transparent;
    padding: 60px 40px; } }

.leiria .leiria-content {
  background: transparent url(/public/files/home/map-leiria.jpg) no-repeat center center;
  background-size: cover; }

.leiria .leiria-content .contacts-title {
  width: 100%;
  max-width: 100%;
  margin: 15px 0 25px 0;
  font: normal 56px/56px "s-Medium", Helvetica, Arial, sans-serif;
  letter-spacing: -1px; }

.leiria .leiria-content .marcar-visita-leiria:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #52433a;
  opacity: 0.7; }

.leiria .marcar-visita-leiria #form-visita {
  padding: 0; }

.leiria .leiria-content #form-visita p.response-text {
  margin-bottom: 100px;
  color: #ffffff; }

.leiria .leiria-content .response-text,
.leiria .content-map-home .link-gym + p {
  padding-top: 0; }

.leiria .center-stage.other {
  display: none; }

.leiria .leiria-content .btn-arrow.arrow-yellow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-big-lbrown.svg) no-repeat center center; }

@media screen and (max-width: 566px) {
  .leiria .leiria-content .contacts-title {
    font-size: 42px;
    line-height: 1;
    margin-top: 0; }
  .leiria .marcar-visita-leiria h3 {
    background-color: transparent;
    padding: 60px 40px; }
  .leiria .leiria-content #form-visita p.response-text {
    margin-bottom: 40px; } }

/*-------------------------------------------------------------------------
    =NUTRICAO ALL
------------------------------------------------------------------------*/
.nutricao .header {
  position: relative;
  height: 50%;
  height: 50vh;
  padding: 0 40px;
  text-align: center;
  background-color: #c8dc83;
  overflow: hidden; }

.health-all .header {
  background-color: #fe714d; }

.health-single .header {
  background-color: #fe714d; }

.nutricao-all .header > div h3 {
  position: relative;
  z-index: 2;
  display: inline;
  width: 100%;
  font: normal 400 42px/42px "s-Light", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 0.9); }

.nutricao-all .header h3 svg {
  position: relative;
  width: 150px;
  height: 33px; }

.nutricao-all .header p {
  position: relative;
  z-index: 2;
  max-width: 839px;
  margin-top: 25px;
  font: normal 26px/36px "p-Light", Helvetica, Arial, sans-serif; }

.nutricao-all .header a {
  margin: 15px 0 0 0;
  font: normal 400 24px/24px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  opacity: 0.8;
  border-bottom: 2px solid transparent;
  z-index: 2; }

.nutricao-all .header .underline-anim {
  background-position: -128px 24px; }

.nutricao-all .header .underline-anim:not(.hover-out):hover {
  background-position: 0 24px; }

.nutricao-all .header .underline-anim.hover-out {
  background-position: 128px 24px; }

.nutricao-all .header a:hover {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8); }

.nutricao-all .header img {
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: inherit;
  z-index: 1;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }

.health-all .header img {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.nutricao-all .nutricao-list {
  position: relative;
  padding: 105px 40px 160px 40px;
  color: #000;
  background-color: #f3f3f3; }

.nutricao-all .nutricao-list .block-content.grid {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.nutricao-all .nutricao-list .box-container {
  padding: 45px;
  background-color: #ffffff; }

.nutricao-all .nutricao-list hr {
  display: block;
  height: 1px;
  margin: 70px 0 25px 0;
  background-color: #e8e8e8;
  border: none; }

.nutricao-all .nutricao-list article {
  min-height: 280px;
  height: auto;
  margin-top: 70px; }

.nutricao-all .nutricao-list article:first-child {
  margin-top: 0; }

.nutricao-all .nutricao-list article:last-child:before {
  display: none; }

.nutricao-all .nutricao-list article:hover:before {
  opacity: 0; }

.nutricao-all .nutricao-list .thumbnail-image {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden; }

.nutricao-all .nutricao-list h3 {
  display: inline-block;
  width: 75%;
  max-width: 75%;
  font: normal 400 36px/36px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  color: #1f1f1f;
  text-align: left; }

.nutricao-all .nutricao-list p {
  padding-bottom: 20px;
  font: normal 16px/25px "p-Light", Helvetica, Arial, sans-serif;
  color: #888888; }

.nutricao-all .nutricao-list time {
  display: block;
  margin-top: 10px;
  padding-bottom: 20px;
  font: normal 400 14px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #d6d6d6;
  text-transform: uppercase; }

.nutricao-all .nutricao-list span.tags {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  font: normal 400 14px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #c8dc83;
  text-transform: uppercase; }

.nutricao-all .nutricao-list span.tags a {
  font: normal 400 14px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #c8dc83;
  text-transform: uppercase; }

.health-all .nutricao-list span.tags {
  color: #fe714d; }

.health-all .nutricao-list span.tags a {
  color: #fe714d; }

.nutricao-all .nutricao-list .underline-anim {
  background: linear-gradient(rgba(200, 220, 131, 0), rgba(200, 220, 131, 0)) no-repeat;
  background-position: -51px 15px; }

.nutricao-all .nutricao-list .underline-anim:not(.hover-out):hover {
  background: linear-gradient(#c8dc83, #c8dc83) no-repeat;
  background-position: 0 15px; }

.nutricao-all .nutricao-list .underline-anim.hover-out {
  background: linear-gradient(#c8dc83, #c8dc83) no-repeat;
  background-position: 51px 15px; }

.health-all .nutricao-list .underline-anim {
  background: linear-gradient(rgba(254, 113, 77, 0), rgba(254, 113, 77, 0)) no-repeat;
  background-position: -51px 15px; }

.health-all .nutricao-list .underline-anim:not(.hover-out):hover {
  background: linear-gradient(#fe714d, #fe714d) no-repeat;
  background-position: 0 15px; }

.health-all .nutricao-list .underline-anim.hover-out {
  background: linear-gradient(#fe714d, #fe714d) no-repeat;
  background-position: 51px 15px; }

.nutricao-all .nutricao-list .social-buttons {
  position: absolute;
  bottom: 0;
  z-index: 50; }

.nutricao-all .nutricao-list .btn {
  margin: 140px auto 0 auto;
  color: #c8dc83;
  border-color: #c8dc83; }

.nutricao-all .nutricao-list .btn .bg {
  background-color: #c8dc83; }

.health-all .nutricao-list .btn {
  color: #fe714d;
  border-color: #fe714d; }

.health-all .nutricao-list .btn .bg {
  background-color: #fe714d; }

.nutricao-all .nutricao-list .btn-arrow .arrow {
  background: transparent url(/public/imgs/icon-arrow-down-bigwhite.svg) no-repeat center center; }

@media screen and (max-width: 1024px) {
  .nutricao-all .nutricao-list article.grid-cell {
    max-width: 50%;
    margin-top: 0;
    padding: 0 15px;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nutricao-all .nutricao-list .grid-cell.box-container,
  .nutricao-all .nutricao-list a.grid {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nutricao-all .nutricao-list article {
    margin-bottom: 30px; }
  .nutricao-all .nutricao-list article:after {
    display: none; }
  .nutricao-all .nutricao-list .box-container .grid-cell-1of2 {
    width: 100%; }
  .nutricao-all .nutricao-list h3 {
    width: 100%;
    max-width: 100%; }
  .nutricao-all .nutricao-list span.tags,
  .nutricao-all .nutricao-list .social-buttons {
    position: relative; }
  .nutricao-all .nutricao-list p {
    padding: 20px 0; }
  .nutricao-all .nutricao-list .btn {
    margin-top: 60px; }
  .nutricao-all .nutricao-list {
    padding-bottom: 90px; } }

@media screen and (max-width: 768px) {
  .nutricao-all .nutricao-list article.grid-cell {
    max-width: 100%;
    padding: 0;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%; }
  .nutricao-all .nutricao-list .thumbnail-image {
    width: 100%; }
  .nutricao-all .nutricao-list .box-container {
    margin-top: -200px; }
  .nutricao-all .header p {
    margin-top: 25px;
    font-size: 22px;
    line-height: 28px; }
  .nutricao-all .nutricao-list .btn {
    margin-top: 40px; }
  .nutricao-all .nutricao-list {
    padding-top: 70px;
    padding-bottom: 70px; }
  .nutricao-list .grid-cell {
    min-height: 40%; }
  .nutricao-list .grid-cell {
    min-height: 40%; }
  .nutricao-list .grid-cell-1of2 {
    min-height: 0%; } }

@media screen and (max-width: 700px) {
  .nutricao-all .nutricao-list .box-container {
    margin-top: -150px; } }

@media screen and (max-width: 566px) {
  .nutricao .header {
    height: 350px;
    padding: 60px 20px 0 20px; }
  .nutricao-all .header img {
    display: none; }
  .nutricao-all .nutricao-list .btn {
    margin-top: 30px; }
  .nutricao-all .nutricao-list {
    padding: 60px 20px; }
  .nutricao-all .nutricao-list .box-container {
    margin-top: -100px; }
  .nutricao-all .nutricao-list h3 {
    font-size: 32px;
    line-height: 34px; } }

@media screen and (max-width: 414px) {
  .nutricao-all .nutricao-list {
    padding: 60px 20px; }
  .nutricao-all .nutricao-list .box-container {
    margin-top: 0; }
  .nutricao-all .nutricao-list .box-container {
    padding: 30px; }
  .nutricao-all .nutricao-list .btn {
    margin: 30px auto 0 auto; }
  .nutricao-all .header p {
    font-size: 20px;
    line-height: 25px; }
  .nutricao-all .header img {
    display: none; }
  .nutricao .header {
    height: 400px;
    padding-top: 80px; } }

@media screen and (max-height: 775px) and (min-width: 568px) {
  .nutricao .header {
    height: 400px; }
  .nutricao-all .header img {
    bottom: -20px; }
  .health-all .header img {
    bottom: auto; } }

@media screen and (max-height: 635px) and (min-width: 1024px) {
  .nutricao-all .header p {
    font-size: 22px;
    line-height: 38px; }
  .horarios .header p {
    font-size: 22px;
    line-height: 38px; }
  .corporate-parcerias .tabs-list li {
    font-size: 38px;
    line-height: 38px; } }

/*-------------------------------------------------------------------------
    =NUTRICAO SINGLE
-------------------------------------------------------------------------*/
.nutricao-single .header h3 {
  position: relative;
  width: 600px;
  max-width: 600px;
  font: normal 400 74px/74px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-align: center; }

.nutricao-single .header time {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font: normal 400 18px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #ffffff;
  text-transform: uppercase; }

.nutricao-single .header span.tags {
  position: relative;
  display: inline-block;
  font: normal 400 18px/16px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #ffffff;
  text-transform: uppercase; }

.nutricao-single .nutricao-single-content {
  position: relative;
  padding: 105px 40px 0 40px;
  color: #000;
  background-color: #ffffff; }

.nutricao-single .nutricao-single-content p {
  margin-bottom: 20px;
  font: normal 20px/38px "p-Light", Helvetica, Arial, sans-serif;
  color: #888888; }

.nutricao-single .nutricao-single-content ul {
  margin-bottom: 20px;
  font: normal 20px/38px "p-Light", Helvetica, Arial, sans-serif;
  color: #888888; }

.nutricao-single .nutricao-single-content li {
  list-style: disc;
  margin-left: 20px; }

.nutricao-single .nutricao-single-content h4 {
  margin-bottom: 20px;
  margin-top: 100px;
  font: normal 400 38px/48px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: uppercase; }

.nutricao-single .nutricao-single-content .social-column {
  max-width: 227px;
  height: 265px;
  border-right: 1px solid #e8e8e8; }

.nutricao-single .nutricao-single-content .text-column {
  position: relative;
  top: -7px;
  padding-left: 45px; }

.nutricao-single .nutricao-single-content .social-comments {
  margin: 105px 0; }

.nutricao-single .nutricao-single-related {
  position: relative;
  padding: 0 40px 160px 40px;
  color: #000;
  background-color: #ffffff; }

.nutricao-single .nutricao-single-related h3 {
  position: relative;
  font: normal 400 32px/32px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  color: #ffffff;
  text-align: left; }

.nutricao-single .nutricao-single-related time {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font: normal 400 15px/15px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0.5; }

.nutricao-single .nutricao-single-related .block-container {
  position: relative;
  width: 100%;
  height: 270px; }

.nutricao-single .nutricao-single-related a {
  display: block; }

.nutricao-single .nutricao-single-related .grid-cell-1of3 {
  margin-right: 20px; }

.nutricao-single .nutricao-single-related .grid-cell-1of3:last-child {
  margin-right: 0; }

.nutricao-single .nutricao-single-related .text-wrapper {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px; }

.nutricao-single .header .underline-anim {
  background-position: -66px 19px; }

.nutricao-single .header .underline-anim:not(.hover-out):hover {
  background-position: 0 19px; }

.nutricao-single .header .underline-anim.hover-out {
  background-position: 66px 19px; }

@media screen and (max-width: 1024px) {
  .nutricao-single .nutricao-single-content .grid {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .nutricao-single .nutricao-single-content .text-column {
    width: 100%;
    top: inherit;
    padding-left: 0; }
  .nutricao-single .nutricao-single-content .social-column {
    width: 400px;
    max-width: 400px;
    height: auto;
    margin: 0 auto 50px auto;
    padding-bottom: 20px;
    text-align: center;
    border-right: none;
    border-bottom: 1px solid #e8e8e8; } }

@media screen and (max-width: 768px) {
  .nutricao-single .nutricao-single-related .grid-cell-1of3 {
    max-width: 50%; }
  .nutricao-single .nutricao-single-related .grid-cell-1of3:last-child {
    display: none; }
  .nutricao-single .nutricao-single-related .grid-cell-1of3:nth-child(2) {
    margin-right: 0; }
  .nutricao-single .header h3 {
    width: 100%;
    max-width: 100%; }
  .nutricao-single .nutricao-single-content {
    padding-top: 70px; }
  .nutricao-single .nutricao-single-content .social-comments {
    margin-top: 70px;
    margin-bottom: 70px; }
  .nutricao-single .nutricao-single-related {
    padding: 0 40px 70px 40px; }
  .nutricao-single .header h3 {
    font-size: 66px;
    line-height: 1; } }

@media screen and (max-width: 566px) {
  .nutricao-single .nutricao-single-content {
    padding: 60px 20px; }
  .nutricao-single .nutricao-single-related {
    padding: 0 20px 60px 20px; }
  .nutricao-single .nutricao-single-content .social-comments {
    margin-top: 60px;
    margin-bottom: 60px; }
  .nutricao-single .nutricao-single-related .text-wrapper {
    bottom: 20px;
    left: 20px;
    right: 20px; }
  .nutricao-single .header h3 {
    width: 400px;
    max-width: 400px;
    font-size: 56px;
    line-height: 1; }
  .nutricao-single .nutricao-single-content .social-column {
    width: 100%;
    max-width: auto; }
  .nutricao-single .nutricao-single-content p,
  .nutricao-single .nutricao-single-content li {
    font-size: 18px;
    line-height: 32px; }
  .nutricao-single .nutricao-single-content h4 {
    font-size: 32px;
    margin-top: 60px; } }

@media screen and (max-width: 480px) {
  .nutricao-single .nutricao-single-related .grid {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .nutricao-single .nutricao-single-related .grid-cell-1of3 {
    max-width: 100%;
    margin-top: 30px;
    margin-right: 0; }
  .nutricao-single .nutricao-single-related .grid-cell-1of3:first-child {
    margin-top: 0; }
  .nutricao-single .nutricao-single-related .grid-cell-1of3:last-child {
    display: block; } }

@media screen and (max-width: 414px) {
  .nutricao .header {
    padding: 60px 20px 0 20px; }
  .nutricao-single .header span.tags {
    display: block;
    margin-top: 20px; }
  .nutricao-single .header time {
    display: block;
    margin-top: 5px; }
  .nutricao-single .header h3 {
    width: auto;
    max-width: 100%;
    font-size: 46px;
    line-height: 1; } }

/* IPhone 4/4S and 5/5S Specific Styles (Bottom bar issue) */
@media screen and (max-width: 320px) and (max-height: 480px) {
  .nutricao .header {
    height: 85vh; } }

/*-------------------------------------------------------------------------
    =PAGE 404
-------------------------------------------------------------------------*/
.page-404 .header {
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 10; }

.page-404 h2 {
  font: normal 74px/100px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center; }

.page-404 h3 {
  font: normal 26px/38px "p-Light", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: none;
  letter-spacing: 0.5px; }

.page-404 .content-404 {
  height: 100vh;
  background-color: #00a2ff;
  overflow: hidden; }

.page-404 .center-stage {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 125px;
  width: 100%;
  text-align: center;
  text-transform: uppercase; }

.page-404 .btn-square-center {
  margin: 0 auto; }

@media screen and (max-width: 1024px) {
  .page-404 .header {
    height: 300px; }
  .page-404 .center-stage {
    bottom: 80px; } }

@media screen and (max-height: 850px) and (min-width: 1024px) {
  .page-404 .content-404 {
    height: 130vh; } }

@media only screen and (max-width: 736px) and (max-height: 479px) {
  .page-404 .content-404 {
    height: 180vh; }
  .page-404 h2 {
    font-size: 54px;
    line-height: 60px; }
  .page-404 h3 {
    font-size: 22px;
    line-height: 32px; } }

@media screen and (max-width: 414px) {
  .page-404 h2 {
    font-size: 64px;
    line-height: 80px; } }

@media screen and (max-width: 413px) {
  .page-404 .content-404 {
    height: 110vh; } }

@media screen and (max-width: 320px) {
  .page-404 .content-404 {
    height: 130vh; }
  .page-404 .header {
    height: 270px; }
  .page-404 h2 {
    font-size: 54px;
    line-height: 60px; }
  .page-404 h3 {
    font-size: 22px;
    line-height: 32px; } }

/*-------------------------------------------------------------------------
    =BRAND GUIDELINES
-------------------------------------------------------------------------*/
#modal-bg,
#modal-wrapper {
  display: none;
  width: 0;
  height: 0;
  overflow-y: hidden; }

#modal-content {
  position: relative;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  z-index: 1050;
  opacity: 1;
  will-change: transform; }

.modal-open #modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background-color: #281a13;
  opacity: 0.8;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.modal-open #modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: block; }

.modal-open #modal-wrapper.js-on {
  overflow-y: scroll; }

#lightbox-wrapper {
  position: relative;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  z-index: 1050;
  background-color: #252322;
  opacity: 1; }

#lightbox {
  position: relative;
  max-width: 810px;
  width: 100%;
  height: auto;
  background-color: #fff;
  z-index: 1050;
  -webkit-box-shadow: 0 3px 10px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px 8px rgba(0, 0, 0, 0.1); }

#lightbox .btn-close-modal {
  position: absolute;
  top: 0;
  right: -55px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background: #ffb100;
  overflow: hidden;
  z-index: 30;
  text-indent: -5555px; }

#lightbox .btn-close-modal .btn {
  position: relative; }

#lightbox.brand-modal header {
  position: relative;
  overflow: hidden;
  height: 250px;
  background-color: #ffb100; }

#lightbox .title {
  position: absolute;
  top: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center; }

#lightbox .id-sec {
  margin: 35px auto;
  text-indent: -9999px; }

#lightbox h3:not(.marcar-visita-lightbox h3) {
  font: normal 74px/100px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0; }

#lightbox .content {
  position: relative;
  width: 100%;
  height: auto;
  padding: 60px 90px; }
  @media screen and (max-width: 767px) {
    #lightbox .content {
      padding: 60px 20px; } }

#lightbox .cell-center-h {
  position: relative;
  text-align: center; }

#lightbox .block-yellow {
  color: #ffffff;
  background-color: #ffb100; }

#lightbox .list-centered {
  margin-top: 50px;
  margin-bottom: 0; }

#lightbox .list-centered {
  width: 100%;
  margin: 80px 0 20px 0;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

#lightbox .btn-rect {
  border: 3px solid #ffb100;
  color: #ffb100; }

#lightbox .btn-rect:hover {
  background-color: #ffb100;
  color: #ffffff; }

#lightbox .brand-content {
  padding: 40px 0; }

#lightbox.brand-modal img {
  display: inline;
  height: 54px; }

#lightbox.brand-modal .grid-cell p {
  margin: 20px 0 0 0; }

#lightbox.brand-modal h4 {
  font-size: 12px;
  color: #000000;
  opacity: 0.5; }

#lightbox .brand-content a {
  font: normal 400 24px/24px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  margin: 0 3px;
  color: #ffb100; }

#lightbox .brand-content.block-yellow a {
  color: #ffffff; }

#lightbox .underline-anim {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) no-repeat;
  background-position: -21px 27px; }

#lightbox .underline-anim:not(.hover-out):hover {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) no-repeat;
  background-position: 0 27px; }

#lightbox .underline-anim.hover-out {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) no-repeat;
  background-position: 21px 27px; }

#lightbox .block-yellow .underline-anim {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) no-repeat;
  background-position: -21px 27px; }

#lightbox .block-yellow .underline-anim:not(.hover-out):hover {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) no-repeat;
  background-position: 0 27px; }

#lightbox .block-yellow .underline-anim.hover-out {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) no-repeat;
  background-position: 21px 27px; }

#lightbox.promo-lightbox .response-text {
  font: 15px/24px "p-Regular", Helvetica, Arial, sans-serif;
  color: #906f25;
  text-align: center; }

@media screen and (max-width: 915px) {
  #lightbox .btn-close-modal {
    right: 0;
    background: transparent; } }

#lightbox.marcar-visita-lightbox,
#lightbox.promo-lightbox {
  background-color: #ffb100; }
  #lightbox.marcar-visita-lightbox #form-parceiro,
  #lightbox.promo-lightbox #form-parceiro {
    padding: 0;
    width: auto;
    height: auto;
    background: transparent; }
  #lightbox.marcar-visita-lightbox .content,
  #lightbox.promo-lightbox .content {
    padding-top: 0; }
  #lightbox.marcar-visita-lightbox .title,
  #lightbox.promo-lightbox .title {
    position: relative; }

#lightbox.promo-lightbox.has-image header {
  max-height: 600px;
  height: auto; }

#lightbox.promo-lightbox.has-image .title {
  position: absolute;
  height: auto; }

#lightbox.promo-lightbox.has-image h3 {
  margin-top: 0;
  display: none; }

#lightbox.promo-lightbox.has-image p.response-text {
  display: none; }

#lightbox.promo-lightbox.has-image .content {
  padding-top: 60px; }

#lightbox.promo-lightbox.has-image #form-parceiro .input-border:first-child {
  margin-top: 0; }

/*-------------------------------------------------------------------------
    =COVID
------------------------------------------------------------------------*/
.covid-19-page .header {
  position: relative;
  height: 60%;
  height: 60vh;
  padding: 0 40px;
  text-align: center;
  background-color: #c8dc83; }
  @media screen and (max-height: 6000px) {
    .covid-19-page .header {
      height: 70%;
      height: 70vh; } }

.covid-19-page .header h3 {
  position: relative;
  max-width: 100%;
  font: normal 400 102px/102px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px; }
  @media screen and (max-width: 1200px) {
    .covid-19-page .header h3 {
      font-size: 76px;
      line-height: 76px; } }
  @media screen and (max-width: 767px) {
    .covid-19-page .header h3 {
      font-size: 46px;
      line-height: 46px; } }

.covid-19-page .header p {
  position: relative;
  font: normal 400 42px/42px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .covid-19-page .header p {
      font-size: 26px;
      line-height: 26px; } }

.covid-19-page .section-wrapper {
  position: relative;
  padding-top: 300px;
  background-color: #ffe000; }
  @media screen and (max-width: 1200px) {
    .covid-19-page .section-wrapper {
      padding-top: 250px; } }
  @media screen and (max-width: 767px) {
    .covid-19-page .section-wrapper {
      padding-top: 150px; } }
  .covid-19-page .section-wrapper.second-section {
    padding-top: 100px;
    background-color: #292929; }
    .covid-19-page .section-wrapper.second-section .content-title,
    .covid-19-page .section-wrapper.second-section .content-block-grid .item p {
      color: #ffffff; }
  .covid-19-page .section-wrapper .content-title {
    font: normal 400 72px/72px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    padding: 0 10px; }
    @media screen and (max-width: 1200px) {
      .covid-19-page .section-wrapper .content-title {
        font-size: 66px;
        line-height: 66px; } }
    @media screen and (max-width: 767px) {
      .covid-19-page .section-wrapper .content-title {
        font-size: 52px;
        line-height: 52px; } }

.covid-19-page .page-content {
  position: relative; }
  .covid-19-page .page-content .block-content {
    max-width: 1700px; }
  .covid-19-page .page-content .covid-mask {
    position: absolute;
    top: 0px;
    width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%); }
    @media screen and (max-width: 1200px) {
      .covid-19-page .page-content .covid-mask {
        width: 400px;
        -webkit-transform: translateY(-30%);
        transform: translateY(-30%); } }
    @media screen and (max-width: 767px) {
      .covid-19-page .page-content .covid-mask {
        width: 60%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }

.covid-19-page .content-block-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  padding-bottom: 40px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .covid-19-page .content-block-grid {
      margin-top: 50px; } }
  @media screen and (max-width: 767px) {
    .covid-19-page .content-block-grid {
      margin-top: 20px; } }
  .covid-19-page .content-block-grid .item {
    position: relative;
    padding: 60px;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center; }
    @media screen and (max-width: 1500px) {
      .covid-19-page .content-block-grid .item {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; } }
    @media screen and (max-width: 1200px) {
      .covid-19-page .content-block-grid .item {
        padding: 40px; } }
    @media screen and (max-width: 1023px) {
      .covid-19-page .content-block-grid .item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        width: 33%;
        max-width: 33%;
        padding: 60px; } }
    @media screen and (max-width: 767px) {
      .covid-19-page .content-block-grid .item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        padding: 10vw; } }
    @media screen and (max-width: 440px) {
      .covid-19-page .content-block-grid .item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        padding: 5vw; } }
    .covid-19-page .content-block-grid .item .image-wrapper {
      position: relative;
      max-width: 100%; }
      @media screen and (max-width: 440px) {
        .covid-19-page .content-block-grid .item .image-wrapper {
          padding: 10px; } }
      .covid-19-page .content-block-grid .item .image-wrapper img {
        max-width: 180px;
        margin: 0 auto; }
    .covid-19-page .content-block-grid .item p {
      position: relative;
      max-width: 100%;
      padding: 0 10px;
      font: normal 400 18px/22px "Knockout 49 A", "Knockout 49 B", Helvetica, Arial, sans-serif;
      letter-spacing: 0;
      text-align: center;
      text-transform: uppercase;
      color: #000;
      margin-top: 45px;
      text-align: left; }
      @media screen and (max-width: 1200px) {
        .covid-19-page .content-block-grid .item p {
          font-size: 16px;
          padding: 0; } }
      @media screen and (max-width: 767px) {
        .covid-19-page .content-block-grid .item p {
          margin-top: 30px; } }

/*-----------------------------------------------------------------------
PAGES

* @Version:    1.0 - 2014
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
.aquacircuit,
.aquacircuit .darker,
.modality-home.aquacircuit.over .circle-bg,
.aquacircuit.transition-modalities .transition-modalities-bg {
  background-color: #1cb8dc; }

.ballet,
.ballet .darker,
.modality-home.ballet.over .circle-bg,
.ballet.transition-modalities .transition-modalities-bg {
  background-color: #f7dce5; }

.ballet2,
.ballet2 .darker,
.modality-home.ballet2.over .circle-bg,
.ballet2.transition-modalities .transition-modalities-bg {
  background-color: #edc2d0; }

.bodyattack,
.bodyattack .darker,
.modality-home.bodyattack.over .circle-bg,
.bodyattack.transition-modalities .transition-modalities-bg {
  background-color: #ffb865; }

.bodybalance,
.bodybalance .darker,
.modality-home.bodybalance.over .circle-bg,
.bodybalance.transition-modalities .transition-modalities-bg {
  background-color: #c2cfa6; }

.bodycombat,
.bodycombat .darker,
.modality-home.bodycombat.over .circle-bg,
.bodycombat.transition-modalities .transition-modalities-bg {
  background-color: #d7ec81; }

.bodyjam,
.bodyjam .darker,
.modality-home.bodyjam.over .circle-bg,
.bodyjam.transition-modalities .transition-modalities-bg {
  background-color: #e2f2a1; }

.bodypump,
.bodypump .darker,
.modality-home.bodypump.over .circle-bg,
.bodypump.transition-modalities .transition-modalities-bg {
  background-color: #ff3341; }

.bodystep,
.bodystep .darker,
.modality-home.bodystep.over .circle-bg,
.bodystep.transition-modalities .transition-modalities-bg {
  background-color: #4a4a56; }

.bodyvive,
.bodyvive .darker,
.modality-home.bodyvive.over .circle-bg,
.bodyvive.transition-modalities .transition-modalities-bg {
  background-color: #3068fc; }

.bootcamp,
.bootcamp .darker,
.modality-home.bootcamp.over .circle-bg,
.bootcamp.transition-modalities .transition-modalities-bg {
  background-color: #2c2925; }

.borntomove,
.borntomove .darker,
.modality-home.borntomove.over .circle-bg,
.borntomove.transition-modalities .transition-modalities-bg {
  background-color: #ff88af; }

.btm,
.btm .darker,
.modality-home.btm.over .circle-bg,
.btm.transition-modalities .transition-modalities-bg {
  background-color: #f5bc4d; }

.crossphive,
.crossphive .darker,
.modality-home.crossphive.over .circle-bg,
.crossphive.transition-modalities .transition-modalities-bg {
  background-color: #25221f; }

.cxworx,
.cxworx .darker,
.modality-home.cxworx.over .circle-bg,
.cxworx.transition-modalities .transition-modalities-bg {
  background-color: #473944; }

.estrelasmar,
.estrelasmar .darker,
.modality-home.estrelasmar.over .circle-bg,
.estrelasmar.transition-modalities .transition-modalities-bg {
  background-color: #00c0ff; }

.flyoga,
.flyoga .darker,
.modality-home.flyoga.over .circle-bg,
.flyoga.transition-modalities .transition-modalities-bg {
  background-color: #bfb3ac; }

.gap,
.gap .darker,
.modality-home.gap.over .circle-bg,
.gap.transition-modalities .transition-modalities-bg {
  background-color: #ffad90; }

.hidrobike,
.hidrobike .darker,
.modality-home.hidrobike.over .circle-bg,
.hidrobike.transition-modalities .transition-modalities-bg {
  background-color: #0dabd0; }

.hidroginastica,
.hidroginastica .darker,
.modality-home.hidroginastica.over .circle-bg,
.hidroginastica.transition-modalities .transition-modalities-bg {
  background-color: #32c4e6; }

.hidrohiit,
.hidrohiit .darker,
.modality-home.hidrohiit.over .circle-bg,
.hidrohiit.transition-modalities .transition-modalities-bg {
  background-color: #58d0ec; }

.musculacao,
.musculacao .darker,
.modality-home.musculacao.over .circle-bg,
.musculacao.transition-modalities .transition-modalities-bg {
  background-color: #393530; }

.natacao,
.natacao .darker,
.modality-home.natacao.over .circle-bg,
.natacao.transition-modalities .transition-modalities-bg {
  background-color: #284592; }

.phitkids,
.phitkids .darker,
.modality-home.phitkids.over .circle-bg,
.phitkids.transition-modalities .transition-modalities-bg {
  background-color: #5f4f6e; }

.phitt,
.phitt .darker,
.modality-home.phitt.over .circle-bg,
.phitt.transition-modalities .transition-modalities-bg {
  background-color: #312d29; }

.phivekangoo,
.phivekangoo .darker,
.modality-home.phivekangoo.over .circle-bg,
.phivekangoo.transition-modalities .transition-modalities-bg {
  background-color: #ff8665; }

.pilates,
.pilates .darker,
.modality-home.pilates.over .circle-bg,
.pilates.transition-modalities .transition-modalities-bg {
  background-color: #e0baa3; }

.powerjump,
.powerjump .darker,
.modality-home.powerjump.over .circle-bg,
.powerjump.transition-modalities .transition-modalities-bg {
  background-color: #ffa177; }

.rpm,
.rpm .darker,
.modality-home.rpm.over .circle-bg,
.rpm.transition-modalities .transition-modalities-bg {
  background-color: #5600d9; }

.spinning,
.spinning .darker,
.modality-home.spinning.over .circle-bg,
.spinning.transition-modalities .transition-modalities-bg {
  background-color: #4c11a6; }

.step,
.step .darker,
.modality-home.step.over .circle-bg,
.step.transition-modalities .transition-modalities-bg {
  background-color: #ff7781; }

.swimcoach,
.swimcoach .darker,
.modality-home.swimcoach.over .circle-bg,
.swimcoach.transition-modalities .transition-modalities-bg {
  background-color: #183d9f; }

.trx,
.trx .darker,
.modality-home.trx.over .circle-bg,
.trx.transition-modalities .transition-modalities-bg {
  background-color: #5b5b6c; }

.yoga,
.yoga .darker,
.modality-home.yoga.over .circle-bg,
.yoga.transition-modalities .transition-modalities-bg {
  background-color: #c0a595; }

.yoguitos,
.yoguitos .darker,
.modality-home.yoguitos.over .circle-bg,
.yoguitos.transition-modalities .transition-modalities-bg {
  background-color: #ff974d; }

.zumba,
.zumba .darker,
.modality-home.zumba.over .circle-bg,
.zumba.transition-modalities .transition-modalities-bg {
  background-color: #308bfc; }

.no-picture.page-toload,
.no-picture .push-footer {
  background-color: #252322; }

.bg-diag .darker:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.08; }

.musculacao .content-horarios {
  display: none; }

/******* =MODALIDADE BTN SVG *******/
.aquacircuit .btn-arrow-svg path {
  fill: #1cb8dc; }

.ballet .btn-arrow-svg path {
  fill: #f7dce5; }

.ballet2 .btn-arrow-svg path {
  fill: #edc2d0; }

.bodyattack .btn-arrow-svg path {
  fill: #ffb865; }

.bodybalance .btn-arrow-svg path {
  fill: #c2cfa6; }

.bodycombat .btn-arrow-svg path {
  fill: #d7ec81; }

.bodyjam .btn-arrow-svg path {
  fill: #e2f2a1; }

.bodypump .btn-arrow-svg path {
  fill: #ff3341; }

.bodystep .btn-arrow-svg path {
  fill: #4a4a56; }

.bodyvive .btn-arrow-svg path {
  fill: #3068fc; }

.bootcamp .btn-arrow-svg path {
  fill: #2c2925; }

.borntomove .btn-arrow-svg path {
  fill: #ff88af; }

.btm .btn-arrow-svg path {
  fill: #f5bc4d; }

.crossphive .btn-arrow-svg path {
  fill: #25221f; }

.cxworx .btn-arrow-svg path {
  fill: #473944; }

.estrelasmar .btn-arrow-svg path {
  fill: #00c0ff; }

.flyoga .btn-arrow-svg path {
  fill: #bfb3ac; }

.gap .btn-arrow-svg path {
  fill: #ffad90; }

.hidrobike .btn-arrow-svg path {
  fill: #0dabd0; }

.hidroginastica .btn-arrow-svg path {
  fill: #32c4e6; }

.hidrohiit .btn-arrow-svg path {
  fill: #58d0ec; }

.musculacao .btn-arrow-svg path {
  fill: #393530; }

.natacao .btn-arrow-svg path {
  fill: #284592; }

.phitkids .btn-arrow-svg path {
  fill: #5f4f6e; }

.phitt .btn-arrow-svg path {
  fill: #312d29; }

.phivekangoo .btn-arrow-svg path {
  fill: #ff8665; }

.pilates .btn-arrow-svg path {
  fill: #e0baa3; }

.powerjump .btn-arrow-svg path {
  fill: #ffa177; }

.rpm .btn-arrow-svg path {
  fill: #5600d9; }

.spinning .btn-arrow-svg path {
  fill: #4c11a6; }

.step .btn-arrow-svg path {
  fill: #ff7781; }

.swimcoach .btn-arrow-svg path {
  fill: #183d9f; }

.trx .btn-arrow-svg path {
  fill: #5b5b6c; }

.yoga .btn-arrow-svg path {
  fill: #c0a595; }

.yoguitos .btn-arrow-svg path {
  fill: #ff974d; }

.zumba .btn-arrow-svg path {
  fill: #308bfc; }

/******* =HORARIOS TABELAS *******/
.modalidades .content-horarios .btn-rect {
  color: #fff;
  border-color: #fff; }

.modalidades.aquacircuit .btn-rect:hover,
.modalidades.aquacircuit .btn-rect.on {
  background: #fff;
  color: #1cb8dc; }

.modalidades.ballet .btn-rect:hover,
.modalidades.ballet .btn-rect.on {
  background: #fff;
  color: #f7dce5; }

.modalidades.ballet2 .btn-rect:hover,
.modalidades.ballet2 .btn-rect.on {
  background: #fff;
  color: #edc2d0; }

.modalidades.bodyattack .btn-rect:hover,
.modalidades.bodyattack .btn-rect.on {
  background: #fff;
  color: #ffb865; }

.modalidades.bodybalance .btn-rect:hover,
.modalidades.bodybalance .btn-rect.on {
  background: #fff;
  color: #c2cfa6; }

.modalidades.bodycombat .btn-rect:hover,
.modalidades.bodycombat .btn-rect.on {
  background: #fff;
  color: #d7ec81; }

.modalidades.bodyjam .btn-rect:hover,
.modalidades.bodyjam .btn-rect.on {
  background: #fff;
  color: #e2f2a1; }

.modalidades.bodypump .btn-rect:hover,
.modalidades.bodypump .btn-rect.on {
  background: #fff;
  color: #ff3341; }

.modalidades.bodystep .btn-rect:hover,
.modalidades.bodystep .btn-rect.on {
  background: #fff;
  color: #4a4a56; }

.modalidades.bodyvive .btn-rect:hover,
.modalidades.bodyvive .btn-rect.on {
  background: #fff;
  color: #3068fc; }

.modalidades.bootcamp .btn-rect:hover,
.modalidades.bootcamp .btn-rect.on {
  background: #fff;
  color: #2c2925; }

.modalidades.borntomove .btn-rect:hover,
.modalidades.borntomove .btn-rect.on {
  background: #fff;
  color: #ff88af; }

.modalidades.btm .btn-rect:hover,
.modalidades.btm .btn-rect.on {
  background: #fff;
  color: #f5bc4d; }

.modalidades.crossphive .btn-rect:hover,
.modalidades.crossphive .btn-rect.on {
  background: #fff;
  color: #25221f; }

.modalidades.cxworx .btn-rect:hover,
.modalidades.cxworx .btn-rect.on {
  background: #fff;
  color: #473944; }

.modalidades.estrelasmar .btn-rect:hover,
.modalidades.estrelasmar .btn-rect.on {
  background: #fff;
  color: #00c0ff; }

.modalidades.flyoga .btn-rect:hover,
.modalidades.flyoga .btn-rect.on {
  background: #fff;
  color: #bfb3ac; }

.modalidades.gap .btn-rect:hover,
.modalidades.gap .btn-rect.on {
  background: #fff;
  color: #ffad90; }

.modalidades.hidrobike .btn-rect:hover,
.modalidades.hidrobike .btn-rect.on {
  background: #fff;
  color: #0dabd0; }

.modalidades.hidroginastica .btn-rect:hover,
.modalidades.hidroginastica .btn-rect.on {
  background: #fff;
  color: #32c4e6; }

.modalidades.hidrohiit .btn-rect:hover,
.modalidades.hidrohiit .btn-rect.on {
  background: #fff;
  color: #58d0ec; }

.modalidades.musculacao .btn-rect:hover,
.modalidades.musculacao .btn-rect.on {
  background: #fff;
  color: #393530; }

.modalidades.natacao .btn-rect:hover,
.modalidades.natacao .btn-rect.on {
  background: #fff;
  color: #284592; }

.modalidades.phitkids .btn-rect:hover,
.modalidades.phitkids .btn-rect.on {
  background: #fff;
  color: #5f4f6e; }

.modalidades.phitt .btn-rect:hover,
.modalidades.phitt .btn-rect.on {
  background: #fff;
  color: #312d29; }

.modalidades.phivekangoo .btn-rect:hover,
.modalidades.phivekangoo .btn-rect.on {
  background: #fff;
  color: #ff8665; }

.modalidades.pilates .btn-rect:hover,
.modalidades.pilates .btn-rect.on {
  background: #fff;
  color: #e0baa3; }

.modalidades.powerjump .btn-rect:hover,
.modalidades.powerjump .btn-rect.on {
  background: #fff;
  color: #ffa177; }

.modalidades.rpm .btn-rect:hover,
.modalidades.rpm .btn-rect.on {
  background: #fff;
  color: #5600d9; }

.modalidades.spinning .btn-rect:hover,
.modalidades.spinning .btn-rect.on {
  background: #fff;
  color: #4c11a6; }

.modalidades.step .btn-rect:hover,
.modalidades.step .btn-rect.on {
  background: #fff;
  color: #ff7781; }

.modalidades.swimcoach .btn-rect:hover,
.modalidades.swimcoach .btn-rect.on {
  background: #fff;
  color: #183d9f; }

.modalidades.trx .btn-rect:hover,
.modalidades.trx .btn-rect.on {
  background: #fff;
  color: #5b5b6c; }

.modalidades.yoga .btn-rect:hover,
.modalidades.yoga .btn-rect.on {
  background: #fff;
  color: #c0a595; }

.modalidades.yoguitos .btn-rect:hover,
.modalidades.yoguitos .btn-rect.on {
  background: #fff;
  color: #ff974d; }

.modalidades.zumba .btn-rect:hover,
.modalidades.zumba .btn-rect.on {
  background: #fff;
  color: #308bfc; }

.ico-s {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50% 50%;
  /* background-color: rgba(0,0,0,.3)!important;*/
  background-color: rgba(56, 64, 74, 0.3) !important;
  background-size: 37px 37px;
  background-repeat: no-repeat;
  background-position: center center; }

.home .ico-s {
  background-color: #38404a !important; }

.bg-lbrown .ico-s {
  background-color: #52433a !important; }

.bg-dbrown .ico-s {
  background-color: #9D8679 !important; }

.ico-b {
  display: inline-block;
  width: 142px;
  height: 142px;
  border-radius: 50% 50%;
  background-color: #38404a !important;
  background-size: 137px 137px;
  background-repeat: no-repeat;
  background-position: center center; }

.ico-m {
  display: inline-block;
  width: 90px;
  height: 90px;
  background-color: transparent;
  background-size: 99px 99px;
  background-repeat: no-repeat;
  background-position: center center; }

.ico-border {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  background-color: transparent !important;
  background-size: 42px 42px;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.ico-border:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  /*border: 2px solid #fff;*/
  box-shadow: 0 0 0 2px #fff;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.ico-border:hover {
  -webkit-transform: scale(1.071);
  -ms-transform: scale(1.071);
  -o-transform: scale(1.071);
  transform: scale(1.071); }

.ico-border:hover:after {
  opacity: .2;
  -webkit-transform: scale(1.111);
  -ms-transform: scale(1.111);
  -o-transform: scale(1.111);
  transform: scale(1.111); }

/*.ico-border:hover:before{
    opacity: 0;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
}*/
/* =white */
.ico-s.aquacircuit, .ico-m.aquacircuit {
  background-image: url(/public/imgs/modalidades/picto-aquacircuit.svg); }

.ico-s.ballet, .ico-m.ballet {
  background-image: url(/public/imgs/modalidades/picto-ballet.svg); }

.ico-s.ballet2, .ico-m.ballet2 {
  background-image: url(/public/imgs/modalidades/picto-ballet.svg); }

.ico-s.bodyattack, .ico-m.bodyattack {
  background-image: url(/public/imgs/modalidades/picto-bodyattack.svg); }

.ico-s.bodybalance, .ico-m.bodybalance {
  background-image: url(/public/imgs/modalidades/picto-bodybalance.svg); }

.ico-s.bodycombat, .ico-m.bodycombat {
  background-image: url(/public/imgs/modalidades/picto-bodycombat.svg); }

.ico-s.bodyjam, .ico-m.bodyjam {
  background-image: url(/public/imgs/modalidades/picto-bodyjam.svg); }

.ico-s.bodypump, .ico-m.bodypump {
  background-image: url(/public/imgs/modalidades/picto-bodypump.svg); }

.ico-s.bodystep, .ico-m.bodystep {
  background-image: url(/public/imgs/modalidades/picto-bodystep.svg); }

.ico-s.bodyvive, .ico-m.bodyvive {
  background-image: url(/public/imgs/modalidades/picto-bodyvive.svg); }

.ico-s.bootcamp, .ico-m.bootcamp {
  background-image: url(/public/imgs/modalidades/picto-bootcamp.svg); }

.ico-s.borntomove, .ico-m.borntomove {
  background-image: url(/public/imgs/modalidades/picto-borntomove.svg); }

.ico-s.btm, .ico-m.btm {
  background-image: url(/public/imgs/modalidades/picto-btm.svg); }

.ico-s.crossphive, .ico-m.crossphive {
  background-image: url(/public/imgs/modalidades/picto-crossfit.svg); }

.ico-s.cxworx, .ico-m.cxworx {
  background-image: url(/public/imgs/modalidades/picto-cxworx.svg); }

.ico-s.estrelasmar, .ico-m.estrelasmar {
  background-image: url(/public/imgs/modalidades/picto-estrelasmar.svg); }

.ico-s.flyoga, .ico-m.flyoga {
  background-image: url(/public/imgs/modalidades/picto-flyoga.svg); }

.ico-s.gap, .ico-m.gap {
  background-image: url(/public/imgs/modalidades/picto-gap.svg); }

.ico-s.gritseries, .ico-m.gritseries {
  background-image: url(/public/imgs/modalidades/picto-gritseries.svg); }

.ico-s.phitkids, .ico-m.phitkids {
  background-image: url(/public/imgs/modalidades/picto-phitkids.svg); }

.ico-s.phitt, .ico-m.phitt {
  background-image: url(/public/imgs/modalidades/picto-phitt.svg); }

.ico-s.gritcardio, .ico-m.gritcardio {
  background-image: url(/public/imgs/modalidades/picto-gritcardio.svg); }

.ico-s.hidrobike, .ico-m.hidrobike {
  background-image: url(/public/imgs/modalidades/picto-hidrobike.svg); }

.ico-s.hidroginastica, .ico-m.hidroginastica {
  background-image: url(/public/imgs/modalidades/picto-hidroginastica.svg); }

.ico-s.hidrohiit, .ico-m.hidrohiit {
  background-image: url(/public/imgs/modalidades/picto-hidrohiit.svg); }

.ico-s.phivekangoo, .ico-b.phivekangoo, .ico-m.phivekangoo {
  background-image: url(/public/imgs/modalidades/picto-kangoo.svg); }

.ico-s.phivestretch, .ico-m.phivestretch {
  background-image: url(/public/imgs/modalidades/picto-stretch.svg); }

.ico-s.musculacao, .ico-m.musculacao {
  background-image: url(/public/imgs/modalidades/picto-musculacao.svg); }

.ico-s.natacao, .ico-m.natacao {
  background-image: url(/public/imgs/modalidades/picto-natacao.svg); }

.ico-s.natacaokids, .ico-m.natacaokids {
  background-image: url(/public/imgs/modalidades/picto-natacao.svg); }

.ico-s.pilates, .ico-m.pilates {
  background-image: url(/public/imgs/modalidades/picto-pilates.svg); }

.ico-s.phuntional, .ico-m.phuntional {
  background-image: url(/public/imgs/modalidades/picto-phunctional.svg); }

.ico-s.powerjump, .ico-m.powerjump {
  background-image: url(/public/imgs/modalidades/picto-powerjump.svg); }

.ico-s.rpm, .ico-m.rpm {
  background-image: url(/public/imgs/modalidades/picto-rpm.svg); }

.ico-s.spinning, .ico-m.spinning,
.ico-s.race, .ico-m.race,
.ico-s.long-ride, .ico-m.long-ride {
  background-image: url(/public/imgs/modalidades/picto-spinning.svg); }

.ico-s.step, .ico-m.step {
  background-image: url(/public/imgs/modalidades/picto-step.svg); }

.ico-s.swimcoach, .ico-m.swimcoach {
  background-image: url(/public/imgs/modalidades/picto-swimcoach.svg); }

.ico-s.trx, .ico-m.trx {
  background-image: url(/public/imgs/modalidades/picto-trx.svg); }

.ico-s.yoga, .ico-m.yoga {
  background-image: url(/public/imgs/modalidades/picto-yoga.svg); }

.ico-s.yoguitos, .ico-m.yoguitos {
  background-image: url(/public/imgs/modalidades/picto-yoguitos.svg); }

.ico-s.zumba, .ico-m.zumba {
  background-image: url(/public/imgs/modalidades/picto-zumba.svg); }

/* =dark #242a30*/
.ico-s.ballet.dark, .ico-m.ballet.dark {
  background-image: url(/public/imgs/modalidades/picto-ballet-dark.svg); }

.ico-s.ballet2.dark, .ico-m.ballet2.dark {
  background-image: url(/public/imgs/modalidades/picto-ballet-dark.svg); }

.ico-s.bodybalance.dark, .ico-m.bodybalance.dark {
  background-image: url(/public/imgs/modalidades/picto-bodybalance-dark.svg); }

.ico-s.bodycombat.dark, .ico-m.bodycombat.dark {
  background-image: url(/public/imgs/modalidades/picto-bodycombat-dark.svg); }

.ico-s.bodyjam.dark, .ico-m.bodyjam.dark {
  background-image: url(/public/imgs/modalidades/picto-bodyjam-dark.svg); }

.ico-s.bodypump.dark, .ico-m.bodypump.dark {
  background-image: url(/public/imgs/modalidades/picto-bodypump-dark.svg); }

.ico-s.bodyvive.dark, .ico-m.bodyvive.dark {
  background-image: url(/public/imgs/modalidades/picto-bodyvive-dark.svg); }

.ico-s.borntomove.dark, .ico-m.borntomove.dark {
  background-image: url(/public/imgs/modalidades/picto-borntomove-dark.svg); }

.ico-s.btm.dark, .ico-m.btm.dark {
  background-image: url(/public/imgs/modalidades/picto-btm-dark.svg); }

.ico-s.cxworx.dark, .ico-m.cxworx.dark {
  background-image: url(/public/imgs/modalidades/picto-cxworx-dark.svg); }

.ico-s.estrelasmar.dark, .ico-m.estrelasmar.dark {
  background-image: url(/public/imgs/modalidades/picto-estrelasmar-dark.svg); }

.ico-s.gap.dark, .ico-m.gap.dark {
  background-image: url(/public/imgs/modalidades/picto-gap-dark.svg); }

.ico-s.phitkids.dark, .ico-m.phitkids.dark {
  background-image: url(/public/imgs/modalidades/picto-phitkids-dark.svg); }

.ico-s.phitt.dark, .ico-m.phitt.dark {
  background-image: url(/public/imgs/modalidades/picto-phitt-dark.svg); }

.ico-s.gritcardio.dark, .ico-m.gritcardio.dark {
  background-image: url(/public/imgs/modalidades/picto-gritcardio-dark.svg); }

.ico-s.hidrobike.dark, .ico-m.hidrobike.dark {
  background-image: url(/public/imgs/modalidades/picto-hidrobike-dark.svg); }

.ico-s.hidroginastica.dark, .ico-m.hidroginastica.dark {
  background-image: url(/public/imgs/modalidades/picto-hidroginastica-dark.svg); }

.ico-s.phivekangoo.dark, .ico-b.phivekangoo.dark, .ico-m.phivekangoo.dark {
  background-image: url(/public/imgs/modalidades/picto-kangoo-dark.svg); }

.ico-s.musculacao.dark, .ico-m.musculacao.dark {
  background-image: url(/public/imgs/modalidades/picto-musculacao-dark.svg); }

.ico-s.natacao.dark, .ico-m.natacao.dark {
  background-image: url(/public/imgs/modalidades/picto-natacao-dark.svg); }

.ico-s.pilates.dark, .ico-m.pilates.dark {
  background-image: url(/public/imgs/modalidades/picto-pilates-dark.svg); }

.ico-s.powerjump.dark, .ico-m.powerjump.dark {
  background-image: url(/public/imgs/modalidades/picto-powerjump-dark.svg); }

.ico-s.spinning.dark, .ico-m.spinning.dark {
  background-image: url(/public/imgs/modalidades/picto-spinning-dark.svg); }

.ico-s.step.dark, .ico-m.step {
  background-image: url(/public/imgs/modalidades/picto-step-dark.svg); }

.ico-s.swimcoach.dark, .ico-m.swimcoach.dark {
  background-image: url(/public/imgs/modalidades/picto-swimcoach-dark.svg); }

.ico-s.yoga.dark, .ico-m.yoga.dark {
  background-image: url(/public/imgs/modalidades/picto-yoga-dark.svg); }

.ico-s.yoguitos.dark, .ico-m.yoguitos.dark {
  background-image: url(/public/imgs/modalidades/picto-yoguitos-dark.svg); }

/******* =HOME modalidades *******/
.mobile .modality-home.aquacircuit .circle-bg {
  background-color: #1cb8dc; }

.mobile .modality-home.ballet .circle-bg {
  background-color: #f7dce5; }

.mobile .modality-home.ballet2 .circle-bg {
  background-color: #edc2d0; }

.mobile .modality-home.bodyattack .circle-bg {
  background-color: #ffb865; }

.mobile .modality-home.bodybalance .circle-bg {
  background-color: #c2cfa6; }

.mobile .modality-home.bodycombat .circle-bg {
  background-color: #d7ec81; }

.mobile .modality-home.bodyjam .circle-bg {
  background-color: #e2f2a1; }

.mobile .modality-home.bodypump .circle-bg {
  background-color: #ff3341; }

.mobile .modality-home.bodystep .circle-bg {
  background-color: #4a4a56; }

.mobile .modality-home.bodyvive .circle-bg {
  background-color: #3068fc; }

.mobile .modality-home.bootcamp .circle-bg {
  background-color: #2c2925; }

.mobile .modality-home.borntomove .circle-bg {
  background-color: #ff88af; }

.mobile .modality-home.btm .circle-bg {
  background-color: #f5bc4d; }

.mobile .modality-home.crossphive .circle-bg {
  background-color: #25221f; }

.mobile .modality-home.cxworx .circle-bg {
  background-color: #473944; }

.mobile .modality-home.estrelasmar .circle-bg {
  background-color: #00c0ff; }

.mobile .modality-home.flyoga .circle-bg {
  background-color: #bfb3ac; }

.mobile .modality-home.gap .circle-bg {
  background-color: #ffad90; }

.mobile .modality-home.hidrobike .circle-bg {
  background-color: #0dabd0; }

.mobile .modality-home.hidroginastica .circle-bg {
  background-color: #32c4e6; }

.mobile .modality-home.hidrohiit .circle-bg {
  background-color: #58d0ec; }

.mobile .modality-home.musculacao .circle-bg {
  background-color: #393530; }

.mobile .modality-home.natacao .circle-bg {
  background-color: #284592; }

.mobile .modality-home.phitkids .circle-bg {
  background-color: #5f4f6e; }

.mobile .modality-home.phitt .circle-bg {
  background-color: #312d29; }

.mobile .modality-home.phivekangoo .circle-bg {
  background-color: #ff8665; }

.mobile .modality-home.pilates .circle-bg {
  background-color: #e0baa3; }

.mobile .modality-home.powerjump .circle-bg {
  background-color: #ffa177; }

.mobile .modality-home.rpm .circle-bg {
  background-color: #5600d9; }

.mobile .modality-home.spinning .circle-bg {
  background-color: #4c11a6; }

.mobile .modality-home.step .circle-bg {
  background-color: #ff7781; }

.mobile .modality-home.swimcoach .circle-bg {
  background-color: #183d9f; }

.mobile .modality-home.trx .circle-bg {
  background-color: #5b5b6c; }

.mobile .modality-home.yoga .circle-bg {
  background-color: #c0a595; }

.mobile .modality-home.yoguitos .circle-bg {
  background-color: #ff974d; }

.mobile .modality-home.zumba .circle-bg {
  background-color: #308bfc; }

/******* =MAIN NAV MODALIDADES *******/
.aquacircuit-nav #nav-main-control .bg::after,
.aquacircuit-nav #nav-main-control .nav-modality li::after {
  background: rgba(0, 174, 212, 0.9); }

.ballet-nav #nav-main-control .bg::after,
.ballet-nav #nav-main-control .nav-modality li::after {
  background: rgba(236, 210, 218, 0.9); }

.ballet2-nav #nav-main-control .bg::after,
.ballet2-nav #nav-main-control .nav-modality li::after {
  background: rgba(227, 185, 198, 0.9); }

.bodyattack-nav #nav-main-control .bg::after,
.bodyattack-nav #nav-main-control .nav-modality li::after {
  background: rgba(245, 176, 90, 0.9); }

.bodybalance-nav #nav-main-control .bg::after,
.bodybalance-nav #nav-main-control .nav-modality li::after {
  background: rgba(185, 198, 157, 0.9); }

.bodycombat-nav #nav-main-control .bg::after,
.bodycombat-nav #nav-main-control .nav-modality li::after {
  background: rgba(205, 227, 119, 0.9); }

.bodyjam-nav #nav-main-control .bg::after,
.bodyjam-nav #nav-main-control .nav-modality li::after {
  background: rgba(214, 232, 150, 0.9); }

.bodypump-nav #nav-main-control .bg::after,
.bodypump-nav #nav-main-control .nav-modality li::after {
  background: rgba(247, 54, 57, 0.95); }

.bodystep-nav #nav-main-control .bg::after,
.bodystep-nav #nav-main-control .nav-modality li::after {
  background: rgba(71, 71, 82, 0.95); }

.bodyvive-nav #nav-main-control .bg::after,
.bodyvive-nav #nav-main-control .nav-modality li::after {
  background: rgba(39, 92, 243, 0.95); }

.bootcamp-nav #nav-main-control .bg::after,
.bootcamp-nav #nav-main-control .nav-modality li::after {
  background: rgba(42, 39, 35, 0.95); }

.borntomove-nav #nav-main-control .bg::after,
.borntomove-nav #nav-main-control .nav-modality li::after {
  background: rgba(246, 130, 166, 0.95); }

.btm-nav #nav-main-control .bg::after,
.btm-nav #nav-main-control .nav-modality li::after {
  background: rgba(235, 181, 64, 0.95); }

.crossphive-nav #nav-main-control .bg::after,
.crossphive-nav #nav-main-control .nav-modality li::after {
  background: rgba(35, 32, 30, 0.95); }

.cxworx-nav #nav-main-control .bg::after,
.cxworx-nav #nav-main-control .nav-modality li::after {
  background: rgba(68, 54, 65, 0.95); }

.estrelasmar-nav #nav-main-control .bg::after,
.estrelasmar-nav #nav-main-control .nav-modality li::after {
  background: rgba(0, 181, 245, 0.95); }

.flyoga-nav #nav-main-control .bg::after,
.flyoga-nav #nav-main-control .nav-modality li::after {
  background: rgba(182, 171, 163, 0.95); }

.gap-nav #nav-main-control .bg::after,
.gap-nav #nav-main-control .nav-modality li::after {
  background: rgba(246, 166, 135, 0.95); }

.hidrobike-nav #nav-main-control .bg::after,
.hidrobike-nav #nav-main-control .nav-modality li::after {
  background: rgba(0, 161, 199, 0.95); }

.hidroginastica-nav #nav-main-control .bg::after,
.hidroginastica-nav #nav-main-control .nav-modality li::after {
  background: rgba(25, 186, 221, 0.95); }

.hidrohiit-nav #nav-main-control .bg::after,
.hidrohiit-nav #nav-main-control .nav-modality li::after {
  background: rgba(74, 197, 226, 0.95); }

.musculacao-nav #nav-main-control .bg::after,
.musculacao-nav #nav-main-control .nav-modality li::after {
  background: rgba(51, 47, 43, 0.95); }

.natacao-nav #nav-main-control .bg::after,
.natacao-nav #nav-main-control .nav-modality li::after {
  background: rgba(36, 63, 141, 0.95); }

.phitkids-nav #nav-main-control .bg::after,
.phitkids-nav #nav-main-control .nav-modality li::after {
  background: rgba(92, 74, 106, 0.95); }

.phitt-nav #nav-main-control .bg::after,
.phitt-nav #nav-main-control .nav-modality li::after {
  background: rgba(47, 43, 38, 0.95); }

.phivekangoo-nav #nav-main-control .bg::after,
.phivekangoo-nav #nav-main-control .nav-modality li::after {
  background: rgba(247, 130, 93, 0.95); }

.pilates-nav #nav-main-control .bg::after,
.pilates-nav #nav-main-control .nav-modality li::after {
  background: rgba(214, 177, 154, 0.95); }

.powerjump-nav #nav-main-control .bg::after,
.powerjump-nav #nav-main-control .nav-modality li::after {
  background: rgba(246, 155, 110, 0.95); }

.rpm-nav #nav-main-control .bg::after,
.rpm-nav #nav-main-control .nav-modality li::after {
  background: rgba(82, 0, 210, 0.95); }

.spinning-nav #nav-main-control .bg::after,
.spinning-nav #nav-main-control .nav-modality li::after {
  background: rgba(72, 0, 160, 0.95); }

.step-nav #nav-main-control .bg::after,
.step-nav #nav-main-control .nav-modality li::after {
  background: rgba(247, 115, 122, 0.95); }

.swimcoach-nav #nav-main-control .bg::after,
.swimcoach-nav #nav-main-control .nav-modality li::after {
  background: rgba(18, 53, 154, 0.95); }

.trx-nav #nav-main-control .bg::after,
.trx-nav #nav-main-control .nav-modality li::after {
  background: rgba(87, 87, 104, 0.95); }

.yoga-nav #nav-main-control .bg::after,
.yoga-nav #nav-main-control .nav-modality li::after {
  background: rgba(184, 158, 141, 0.95); }

.yoguitos-nav #nav-main-control .bg::after,
.yoguitos-nav #nav-main-control .nav-modality li::after {
  background: rgba(246, 146, 66, 0.95); }

.zumba-nav #nav-main-control .bg::after,
.zumba-nav #nav-main-control .nav-modality li::after {
  background: rgba(34, 128, 242, 0.95); }

/*-----------------------------------------------------------------------
MOBILE and RESPONSIVE

* @Version:    1.0 - 2014
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com

-------------------------------------------------------------------------*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */ }

body {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */ }

/*Fix home header in ipad paint*/
.mobile .home-start-fixed {
  position: relative; }

@media screen and (max-width: 1466px) {
  /*------------ ==INSCRICOES ---------------*/
  .inscricoes .content .grid-1 {
    width: 25%; }
  .inscricoes .content .grid-2 {
    width: 75%; } }

@media screen and (max-width: 1318px) {
  /*------------ ==INSCRICOES ---------------*/
  .inscricoes .content .grid-1 {
    display: none; }
  .inscricoes .content .grid-2 {
    width: 100%; }
  .inscricoes-form {
    max-width: none; } }

@media screen and (max-width: 1269px) {
  /*------------ =HOME ---------------*/
  /** =Planos */
  .home #content-planos .plano-normal h5 {
    padding-bottom: 40px; }
  .text-price {
    height: 62px; }
  .text-price,
  .text-price .odometer span {
    font-size: 55px;
    line-height: 55px; }
  .text-price .odometer span {
    line-height: 67px; } }

@media screen and (max-width: 1079px) {
  /*------------ =HORARIOS ---------------*/
  .content-horarios .dowl-pdf {
    position: relative;
    top: 0;
    right: auto;
    width: 100%;
    margin: 80px 0 0 0;
    text-align: center; }
  /*------------ =TEAM ---------------*/
  .team .instruct-info h2 {
    font: normal 72px/80px "s-Medium", Helvetica, Arial, sans-serif; }
  .team .instruct-info .rgt {
    font: normal 400 55px/55px "Knockout 27 A", "Knockout 27 B", Helvetica, Arial, sans-serif; }
  /*------------ =HOME ---------------*/
  /** =social */
  #content-social .list-imgs {
    padding: 0 250px; }
  /** =Planos */
  .home #content-planos .plano-row {
    font-size: 14px;
    line-height: 17px;
    height: auto;
    padding-bottom: 25px; }
  .home #content-planos .plano-row > div,
  .home #content-planos .plano-row > ul,
  .home #content-planos .plano-row > p {
    width: 100%;
    height: auto; }
  .home #content-planos .plano-row .grid {
    padding-top: 35px; }
  .home #content-planos .plano-row .text-price + p {
    position: relative;
    left: auto;
    bottom: auto; }
  .home #content-planos .plano-row .text-price {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 30px; }
  .home #content-planos .plano-row .btn-square-center {
    margin: 35px auto;
    top: auto; }
  .leiria #form-visita {
    width: auto; } }

@media screen and (max-width: 1023px) {
  #awwwards {
    display: none; }
  .responsive-info {
    display: block; }
  /* GINASIOS SINGLE */
  .ginasios.celas
.content-2c-fullH-gym:first-of-type
div.grid-cell:nth-child(1) {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .ginasios.celas
.content-2c-fullH-gym:first-of-type
div.grid-cell:nth-child(2) {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .content-2c-gym {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .content-2c-gym .visual {
    width: 100%; }
  .content-2c-gym .info {
    width: 100%; }
  .content-2c-gym .info p {
    position: relative; }
  /******* GRID *******/
  .grid {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .no-flexbox .grid-cell {
    float: left;
    width: 100%; }
  .grid.grid-icons {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  /*------------ =TEAM ---------------*/
  .team.member .instruct-info .rgt {
    display: none; }
  /*------------ ==INSCRICOES ---------------*/
  .inscricoes-form .inputType-radio {
    float: none;
    width: 100%;
    margin-bottom: 20px; }
  .inscricoes-form fieldset {
    margin-bottom: 60px; }
  .inscricoes-form fieldset.dados > div {
    padding: 0; }
  .inscricoes-form fieldset.dados p,
  .inscricoes-form .column {
    width: 100%;
    float: none;
    margin-bottom: 20px; } }

@media screen and (max-width: 822px) {
  /******* =MENU MAIN =MAIN NAV *******/
  #nav-main-list li {
    margin: 0 12px; }
  /*------------ =HOME ---------------*/
  /** =Planos */
  .text-price {
    height: 72px; }
  .text-price,
  .text-price .odometer span {
    font-size: 72px;
    line-height: 72px; }
  .home .plano-premium .grid {
    -webkit-justify-content: space-around;
    justify-content: space-around; }
  .home #content-planos .plano-premium li {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none; }
  .home .grid-icons li {
    flex: none;
    width: 50%;
    margin: 0 0 35px 0;
    padding: 0; }
  .leiria-horario-btn {
    top: 0px; }
  /*------------ =BLOG MOBILE FIX HEIGHTS ---------------*/
  .nutricao-list .grid-cell {
    min-height: 40%; }
  .nutricao-list .grid-cell {
    min-height: 40%; }
  .nutricao-list .grid-cell-1of2 {
    min-height: 0%; } }

@media screen and (max-width: 767px) {
  .leiria .content-map-home .marcar-visita-leiria {
    padding: 0; }
  /*------------ =HORARIOS ---------------*/
  .content-horarios .gym-column {
    width: 100%; }
  .has-arrows #nav-main-control .bg {
    width: calc(100% - 240px); }
  #nav-main-control .nav-modality {
    width: 240px; }
  /* =note: display none tenho bug no over do da seta dta */
  #nav-main-control .nav-modality .text {
    display: none; }
  .home .content-horarios .icon-horarios-bw,
  .content-horarios .icon-horarios {
    display: none; }
  .content-horarios.open .gym-column.close {
    padding: 70px 0;
    width: 100%; }
  .content-horarios.open .gym-column.open {
    padding: 70px 0; }
  .content-horarios .close h3 {
    position: relative;
    left: auto;
    bottom: auto;
    top: auto;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 80px;
    width: 100%;
    white-space: normal;
    -webkit-transform: none;
    transform: none; }
  .content-horarios .close h3 {
    padding-bottom: 40px; }
  .content-horarios .close p {
    position: relative;
    width: auto;
    bottom: auto;
    top: auto; }
  .gym-name.yellow::before,
  .gym-name.orange::before,
  .gym-name.white::before,
  .content-horarios .off .gym-name.white::before,
  .gym-name.yellow::after,
  .gym-name.orange::after,
  .gym-name.white::after,
  .content-horarios .off .gym-name.white::after {
    -webkit-transform: translateX(-50%) scale(0.65);
    -ms-transform: translateX(-50%) scale(0.65);
    transform: translateX(-50%) scale(0.65); }
  .gym-name.yellow::before,
  .gym-name.orange::before,
  .gym-name.white::before,
  .content-horarios .off .gym-name.white::before {
    top: -70px; }
  .gym-name.yellow::after,
  .gym-name.orange::after,
  .gym-name.white::after,
  .content-horarios .off .gym-name.white::after {
    top: 70px; }
  /*.corporate .gym-name { line-height: 122px; }*/
  .corporate .gym-name::before {
    top: -70px; }
  .corporate .gym-name::after {
    top: 114px; } }

@media screen and (max-width: 413px) {
  .gym-name.yellow::before,
  .gym-name.orange::before,
  .gym-name.white::before {
    top: -60px; }
  .gym-name.yellow::after,
  .gym-name.orange::after,
  .gym-name.white::after {
    top: 60px; }
  .content-horarios .off .gym-name.white::before {
    top: -60px; }
  .content-horarios .off .gym-name.white::after {
    top: 60px; } }

@media screen and (max-width: 699px) {
  /******* =MENU MAIN =MAIN NAV *******/
  #header-main {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    height: 72px;
    overflow: hidden; }
  /*  .home #header-main{
    top: 258px;
  }*/
  .open-nav #header-main {
    height: 100vh;
    overflow: auto; }
  .open-nav #header-bg {
    display: block;
    opacity: 0.25;
    pointer-events: none; }
  .nav-sec-mobile {
    position: relative;
    z-index: 20;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transform: translate3d(0, -110%, 0);
    -moz-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0); }
  .open-nav .nav-sec-mobile {
    position: relative;
    z-index: 20;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  #nav-main {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  #nav-main-list {
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    margin-top: 72px;
    border-top: 1px solid #2a292a; }
  #nav-main-list li,
  #nav-main-list li:first-child {
    width: 49%;
    /*border-right: 1px solid #2a292a;*/
    border-bottom: 1px solid #2a292a;
    margin: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  #nav-main-list a {
    position: relative; }
  #nav-main-list svg,
  #nav-main-list img {
    margin-bottom: 10px; }
  #nav-main-list span {
    padding-bottom: 25px; }
  #nav-main-control {
    position: fixed;
    z-index: 25; }
  #nav-main-control .bg {
    width: 100%; }
  /*    .has-arrows #nav-main-control .bg{
      width: 100%;
  }*/
  .open-nav #nav-main-control {
    background: #252322; }
  #nav-main-control .nav-modality {
    z-index: 100; }
  #nav-main-sec {
    height: auto; }
  #nav-main-sec ul {
    float: none;
    width: 100%;
    text-align: center; }
  #nav-main-sec .btn-close {
    display: none; }
  /*------------ =HORARIOS ---------------*/
  /*.content-horarios .btn-close{
      top: -250px;
      right: 20px;
  }
  .content-horarios .btn-close .btn-x {
      display: block;
      width: 45px;
      height: 45px;
  }*/
  /*------------ ==INSCRICOES ---------------*/
  .inscricoes .content {
    padding: 0 20px 50px 20px; }
  /*------------ =HOME ---------------*/
  /** =Planos */
  .home #content-planos .plano-normal h5 {
    padding: 0 16px 40px 16px; }
  .text-price {
    height: 62px; }
  .text-price,
  .text-price .odometer span {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: -2px; }
  .text-price .odometer + span {
    display: block;
    font-size: 16px;
    line-height: 16px; }
  .text-price sup {
    font: normal 16px/16px "p-Light", Helvetica, Arial, sans-serif;
    left: -4px; }
  .lagrimas .planos-slide {
    margin: 60px 0 0 0; } }

@media screen and (max-width: 568px) {
  /*------------ =GYMS TITLES ---------------*/
  .gym-name {
    font-size: 58px;
    padding-left: 20px;
    padding-right: 20px; }
  #nav-main-control .nav-modality li {
    margin: 0; }
  #nav-main-control .nav-modality .go-main {
    display: none; }
  /******* =HEADER *******/
  .has-arrows #nav-main-control .bg {
    width: calc(100% - 160px); }
  #nav-main-control .nav-modality {
    width: 160px; }
  .corporate .gym-name {
    line-height: 78px; }
  .corporate .gym-name::before {
    top: -80px; }
  .corporate .gym-name::after {
    top: 80px; }
  .leiria #form-visita {
    background-color: initial; } }

@media screen and (max-width: 566px) {
  /******* =HEADER *******/
  .btn-burger {
    left: 20px; }
  /*------------ =TEAM ---------------*/
  .team .instruct-info h2 {
    font: normal 54px/57px "s-Medium", Helvetica, Arial, sans-serif; }
  .team .instruct-info .rgt {
    display: none; }
  .team .instruct-info .list-modality {
    margin-top: 17px; }
  /*------------ =FORM ---------------*/
  #form-home {
    height: auto;
    background: #f5b50e; }
  #content-registo {
    height: auto; }
  #content-registo .content-registo-bg {
    display: none; } }

@media screen and (max-width: 414px) {
  /*------------ =GYMS TITLES ---------------*/ }

@media screen and (max-width: 413px) {
  /******* =HEADER *******/
  .has-arrows #nav-main-control .bg {
    width: calc(100% - 120px); }
  #nav-main-control .nav-modality {
    width: 120px; }
  /******* =content-fotos *******/
  .content-fotos li,
  .content-fotos li.picH {
    width: 100%; }
  .content-fotos li.picV {
    width: 50%; }
  .content-fotos li.picUp {
    margin-top: 0; }
  /*------------ ==INSCRICOES ---------------*/
  .inscricoes .id-sec-full {
    padding-bottom: 72px; }
  .inscricoes .header-section {
    font: normal 20px/26px "p-Light", Helvetica, Arial, sans-serif;
    padding: 80px 0 57px 0; }
  #nav-inscricoes h3 {
    font: normal 20px/26px "p-Regular", Helvetica, Arial, sans-serif; }
  .btn-arrow-down:before,
  .btn-arrow-down:after {
    top: 9px;
    right: -26px; }
  .btn-arrow-down:after {
    right: -18px;
    top: 17px; }
  #nav-inscricoes ul {
    top: 65px;
    padding: 28px 22px;
    font: normal 17px/34px "p-Regular", Helvetica, Arial, sans-serif;
    min-width: 274px; }
  .inscricoes-form {
    margin-top: 50px; }
  .inscricoes-form h5 {
    font: normal 19px/25px "p-Regular", Helvetica, Arial, sans-serif;
    margin-bottom: 15px; }
  .inscricoes-form .inputType-radio,
  .inscricoes-form input.text {
    height: 50px;
    padding: 0 19px;
    font: normal 16px/50px "p-Regular", Helvetica, Arial, sans-serif; }
  .inscricoes-form .info-price {
    font: normal 13px/50px "p-Regular", Helvetica, Arial, sans-serif; }
  .inscricoes-form .icon-svg-auto {
    top: 8px; }
  .inscricoes-form fieldset {
    margin-bottom: 50px; }
  .inscricoes-form fieldset .info-tooltip {
    left: -132px;
    top: -148px;
    min-height: 125px;
    width: 190px;
    padding: 10px 10px;
    font: normal 13px/18px "p-Regular", Helvetica, Arial, sans-serif; }
  .info-tooltip:after {
    border-width: 22px;
    margin-left: 32px; }
  .table-inscricoes {
    font: normal 20px/22px "p-Regular", Helvetica, Arial, sans-serif; }
  .table-inscricoes td {
    padding: 8px 4px; }
  .table-inscricoes tr.total {
    font: normal 23px/20px p-Medium, Helvetica, Arial, sans-serif; }
  .inscricoes-form .end {
    margin: 50px 0 14px 0;
    font: normal 13px/17px "p-Regular", Helvetica, Arial, sans-serif; }
  .inscricoes-form .end .btn {
    margin: 0 auto 29px auto; }
  /*------------ =HORARIOS ---------------*/
  /*.content-horarios .btn-close{
        top: -270px;
    }*/
  /*------------- =FORM ------------------*/
  #form-home .radio-buttons {
    text-align: left; }
  #form-home .radio-buttons li {
    margin-left: 0; } }

@media screen and (max-width: 320px) {
  /*------------ =GYMS TITLES ---------------*/
  .gym-name {
    font-size: 46px; }
  .team .instruct-info h2 {
    font-size: 46px;
    line-height: 48px; } }

@media screen and (max-height: 600px) {
  .gym-name {
    font-size: 51px; }
  .gym-name.yellow::before,
  .gym-name.orange::before,
  .gym-name.white::before,
  .content-horarios .off .gym-name.white::before {
    top: -60px; }
  .gym-name.yellow::after,
  .gym-name.orange::after,
  .gym-name.white::after,
  .content-horarios .off .gym-name.white::after {
    top: 60px; }
  .corporate .gym-name {
    line-height: 78px; }
  .corporate .gym-name::before {
    top: -62px; }
  .corporate .gym-name::after {
    top: 62px; } }

/*-------------------------------------------------------------------------
    =HEIGHTS
-------------------------------------------------------------------------*/
/*@media screen and (min-width:1024px) and (max-height: 775px) {
    .team #home-start .bg-blue p{
        min-width: 80%;
    }

}*/
/*------------ =TEAM ---------------*/
/*@media screen and (max-height: 775px) {
  .team .instruct-pic {
    top: 0;
    -webkit-transform: translate3d(-50%,0,0);
    -ms-transform: translate3d(-50%, 0,0);
    transform: translate3d(-50%,0,0);
  }
  .team.member {
    margin-bottom: 0;
  }
  .team .center-stage {
    position: relative;
    bottom: 100px;
  }
  .footer-team {
    position: relative;
  }
}*/
/*-------------------------------------------------------------------------
    >= GERAL IE's
-------------------------------------------------------------------------*/
.ie select::-ms-expand {
  display: none; }

.ie10 .content-campg-home-grid,
.ie11 .content-campg-home-grid {
  display: block; }

.ie10 .anim-txt-ud,
.ie11 .anim-txt-ud {
  -webkit-transform: scaleY(1) translateY(0) translateZ(0);
  transform: scaleY(1) translateY(0) translateZ(0); }

.ie10 .up .anim-txt-ud,
.ie11 .up .anim-txt-ud,
.ie10 .anim-txt-ud.up,
.ie11 .anim-txt-ud.up {
  transform-origin: none;
  -webkit-animation: none;
  animation: none; }

.ie10 .down .anim-txt-ud,
.ie11 .down .anim-txt-ud,
.ie10 .anim-txt-ud.down,
.ie11 .anim-txt-ud.down {
  transform-origin: none;
  -webkit-animation: none;
  animation: none; }

.ie10 .content-horarios,
.ie11 .content-horarios {
  height: 700px; }

.ie10 .content-horarios.open,
.ie11 .content-horarios.open {
  height: auto; }

.ie10 .header-ginasios,
.ie11 .header-ginasios {
  display: block;
  height: 100%;
  height: 100vh; }

.ie10 .header-ginasios .grid-cell,
.ie11 .header-ginasios .grid-cell {
  display: block;
  width: 50%;
  float: left; }

/*-------------------------------------------------------------------------
    >= IE11
-------------------------------------------------------------------------*/
.ie11 .content-campg-home .element-cover,
.ie11 .footer .element-cover,
.ie11 .content-2c-fullH-gym .visual .element-cover,
.ie11 .nutricao-all .nutricao-list .thumbnail-image {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie11 .content-gym-home .info-content {
  margin-top: 100px; }

.ie11 .content-campg-home-grid li {
  width: 25%;
  height: 520px;
  float: left;
  overflow: hidden; }

.ie11 .nav-modality-list li .bg-diag {
  display: none; }

.ie11 .header-ginasios .align-flex-vcenter {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 44%;
  margin: 0;
  padding: 0;
  -webkit-transform: translate3d(-44%, -50%, 0);
  -ms-transform: translate3d(-44%, -50%, 0);
  transform: translate3d(-44%, -50%, 0); }

/*-------------------------------------------------------------------------
    <= IE10
-------------------------------------------------------------------------*/
.ie10 .element-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 #home-start {
  height: 100vh; }

.ie10 #home-start .bg-p {
  display: none; }

.ie10 #home-start .header-cover {
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .list-modal-home .modality-home {
  display: inline-block; }

.ie10 .content-gym-home {
  position: relative;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.ie10 .content-gym-home .visual {
  position: relative;
  width: 100%;
  height: 509px;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.ie10 .content-gym-home .info {
  position: relative;
  width: 100%;
  min-height: 400px;
  padding: 80px 0;
  margin-left: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.ie10 .content-gym-home .info-content {
  width: 100%;
  padding: 0 80px;
  margin-top: 0; }

.ie10 .content-gym-home .info-content .info-btn {
  position: relative;
  bottom: auto;
  left: auto; }

.ie10 .content-campg-home-grid li {
  width: 50%;
  height: 450px;
  float: left;
  overflow: hidden; }

.ie10 .parallax-b-layer > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10.no-borderimage .nav-modality-list li .bg-diag {
  display: block; }

.ie10.no-borderimage .team.member .content-main-wrapper {
  height: 300vh; }

.ie10 .team .instruct-info .grid-cell {
  display: block;
  width: 50%;
  height: 100vh; }

.ie10 .team.member .instruct-info {
  top: 25vh; }

.ie10 .team .form-team-member {
  display: block;
  width: 700px;
  height: auto;
  margin: 0 auto; }

.ie10 .ginasios-recruta ul li .grid-cell:nth-child(2) {
  padding-right: 100px; }

.ie10 .recruta header h3 {
  position: relative;
  top: 50%;
  margin-top: -24px; }

.ie10 .recruta header h4 {
  position: relative;
  top: 50%;
  margin-top: -12px; }

.ie10 .content-2c-fullH-gym .visual {
  height: calc(100vh - 250px); }

.ie10 .content-2c-fullH-gym {
  height: auto;
  min-height: 0;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ie10 .content-2c-fullH-gym .info-contacts-flex {
  position: relative;
  width: 100%;
  height: 250px;
  padding: 75px;
  -webkit-flex-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit; }

.ie10 .info-contacts-flex .grid-cell {
  min-height: 100%; }

.ie10 .info-contacts-flex .grid-cell:nth-child(2) {
  margin-top: 0; }

.ie10 .info-contacts-flex .grid-btn {
  position: relative;
  top: auto;
  right: 100px;
  height: auto;
  min-height: 0; }

.ie10 .info-contacts-flex .grid-btn .btn-show {
  position: relative;
  top: auto;
  margin-top: 0; }

.ie10 .info-contacts-flex li span.title.short {
  min-width: 100px;
  width: 100px; }

.ie10 .content-2c-fullH-gym div.grid-cell:nth-child(2) {
  position: relative;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ie10 .content-2c-fullH-gym div.grid-cell:nth-child(2) .info-contacts-flex {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.ie10 .content-2c-fullH-gym div.grid-cell:nth-child(2) .visual {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.ie10 .content-2c-gym .visual {
  padding-top: 64.59%; }

.ie10 .content-2c-gym .info p {
  padding: 75px; }

.ie10 .grid-icons-full .icon-wrap {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  text-align: center; }

.ie10 .grid-icons .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .footer-2c {
  position: relative;
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0; }

.ie10 .footer-2c .cell-lft {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  padding: 60px 40px;
  height: auto; }

.ie10 .footer-2c .cell-rgt {
  position: relative;
  width: 100%;
  min-height: 100%;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  height: auto; }

.ie10 .footer-2c .cell-rgt .text {
  position: relative;
  left: 0;
  top: -240px;
  width: 100%;
  height: auto;
  padding: 60px 40px; }

.ie10 .footer-2c .cell-rgt .text p {
  font-size: 36px;
  line-height: 40px; }

.ie10 .footer.footer-2c .btn-arrow-svg {
  position: absolute;
  top: 50%;
  left: auto;
  margin-top: -47px;
  right: 40px;
  bottom: 60px;
  pointer-events: none; }

.ie10 .footer.footer-2c .text::after {
  display: none; }

.ie10 .horarios .header p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .nutricao-all .header > div h3 {
  position: absolute;
  width: 100%;
  top: 40%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .nutricao-all .header p {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .nutricao-all .nutricao-list .thumbnail-image {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  width: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .nutricao-single .header h3 {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .nutricao .header div:last-child {
  position: absolute;
  width: 100%;
  top: 65%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.ie10 .block-hover-shadow::after {
  width: 0;
  height: 0;
  box-shadow: none;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: none;
  transition: none; }

.ie10 .block-hover-shadow:hover {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.ie10 .block-hover-shadow:hover::after {
  opacity: 0; }

@media screen and (max-width: 2000px) {
  .ie10 .list-modal-home .modality-home:nth-child(5) {
    display: none; } }

@media screen and (max-width: 1620px) {
  .ie10 .list-modal-home .modality-home:nth-child(4) {
    display: none; } }

@media screen and (max-width: 1230px) {
  .ie10 .list-modal-home .modality-home:nth-child(3) {
    display: none; } }

@media screen and (max-width: 870px) {
  .ie10 .list-modal-home .modality-home:nth-child(2) {
    display: none; } }

/*# sourceMappingURL=styles.css.map */
